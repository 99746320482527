export const arrowimage = require("../assets/arrow.svg");
export const invoiceimage = require("../assets/invoice.svg");
export const addTag = require("../assets/addtag.svg");
export const searchicon = require("../assets/search.svg");
export const sortingicon = require("../assets/sorting.svg");
export const filtericon = require("../assets/filter.svg");
export const Invoicesimage = require("../assets/invoices.svg");
export const InvoicesIco = require("../assets/invoicesIco.svg");
export const logo = require("../assets/kx_logo.jpg")




