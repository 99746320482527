import React from "react";
// Customizable Area Start
import {
  Typography,
  Box,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  InputAdornment,
  Chip,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Navbar from "../../../components/src/Navbar.web";
import SideBar from "../../../components/src/SideBar.web";
import { filterIcon, invoiceIcon, RupeeIcon, seacrhIcon, sortingIcon } from "./assets";
import DebitCardController, {
  Props
} from "./DebitCardController.web";
// Customizable Area End



export default class DebitCard extends DebitCardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start



    // Customizable Area End
  }

  // Customizable Area Start
  debitdetails = () => {
    const tabData = [
      { label: 'All Invoices', value: 0 },
      { label: 'Pending', value: 1 },
      { label: 'Accepted', value: 2 },
      { label: 'Rejected', value: 3 },
    ];

    const headers = [
      { id: 'debitreqno', label: 'Debit  Request No' },
      { id: 'amount', label: 'Amount' },
      { id: 'againstinvoice', label: 'Against Invoice' },
      { id: 'status', label: 'Status' },
      { id: 'vendor', label: 'Vendor' },
      { id: 'date', label: 'Date' },
      { id: 'action', label: 'Actions' }
    ];

    const Debitnotedetails = [
      {
        amount: '28,541,745',
        againstinvoice: 'INV-240218-0010-1',
        debitno: 'DR-240218-0010-1',
        vender: 'Jindal Steel & Power',
        date: '09 Feb 2024',
        status: 'Pending'
      },
      {
        debitno: 'DR-240218-0010-2',
        againstinvoice: 'INV-240218-0010-2',
        amount: '28,541,645',
        date: '08 Feb 2024',
        status: 'Accepted',
        vender: 'Hitachi'
      },
      {
        debitno: 'DR-240218-0010-3',
        amount: '28,551,745',
        againstinvoice: 'INV-240218-0010-3',
        status: 'Rejected',
        vender: 'Bosch',
        date: '09 Feb 2024'
      },
      {
        amount: '28,541,745',
        againstinvoice: 'INV-240218-0010-4',
        debitno: 'DR-240218-0010-4',
        vender: 'Jindal Power',
        date: '25 Feb 2024',
        status: 'Pending'
      },
      {
        debitno: 'DR-240218-0010-5',
        amount: '28,541,645',
        date: '08 Feb 2024',
        againstinvoice: 'INV-240218-0010-5',
        vender: 'Hitachi',
        status: 'Accepted'
      },
      {
        debitno: 'DR-240218-0010-6',
        amount: '28,751,745',
        againstinvoice: 'INV-240218-0010-6',
        status: 'Rejected',
        vender: 'Jindal Steel & Power',
        date: '20 Feb 2024'
      }
    ];

    return (
      <Box style={{ width: "94%", ...style.mainDiv }}>
        <Box>
          <Typography variant="h6" style={{ ...style.head, fontFamily: "Inter, sans-serif" }}>
            Debit Notes
          </Typography>
        </Box>
        <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
          <Box style={{ ...style.navTabdiv }}>
            <Box
              style={{
                flexGrow: 1,
                overflowX: "auto",
                display: "flex"
              }}
            >
                <Tabs
  TabIndicatorProps={{
    style: { background: "#EE6900", height: "4px", borderRadius: "8px" }
  }}
  onChange={(event, newValue) => this.handleTabChange(newValue)}
  value={this.state.value}
>
  {tabData.map(({ label, value }) => (
    <Tab
      style={{
        fontFamily: "Inter, sans-serif",
        textTransform: "none",
        height: "58px",
        minWidth: "80px", 
        padding: "6px 35px" 
      }}
      label={
        <Typography
          style={this.state.value !== value ? style.inactiveTabs : style.activeTabs}
        >
          {label}
        </Typography>
      }
      key={value}
    />
  ))}
</Tabs>
            </Box>
            <Box style={style.searchContainers}>
              <InputBase
                style={style.searchInputs}
                inputProps={{
                  style: {
                    fontFamily: 'inter, sans-serif',
                    fontSize: 12,
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 0.87)"
                  }
                }}
                 placeholder="Search"
                startAdornment={
                  <InputAdornment position="start">
                    <img  style={{ width: 20, height: 20 }} src={seacrhIcon} alt="search icon"  />
                  </InputAdornment>
                }
              />
              <img src={sortingIcon}  data-testId="filterIcon" alt="sorting icon"  style={{ width: 20, height: 20, marginLeft: "8px" }} />
              <IconButton>
                <img src={filterIcon}  alt="MoreVertIcon" style={{ width: 20, height: 20 }} data-testId="MoreVertIcon" />
              </IconButton>
            </Box>
          </Box>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead style={{ ...style.tableHeads }}>
                <TableRow style={{ borderBottom: "none" }}>
                  <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                  {headers.map(({ id, label }) => (
                    <TableCell key={id} align="left" style={{ ...style.TableCells, borderTop: "none", borderBottom: "none", fontFamily: "Inter, sans-serif" }}>
                      {label}
                    </TableCell>
                  ))}
                  <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow style={{ height: "45px" }}></TableRow>
                {Debitnotedetails.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                    <TableCell align="left" style={{ ...style.debitno, borderTop: "1px solid #E7E2Ef", borderLeft: "1px solid #E7E2EF", fontFamily: "Inter, sans-serif" }}>
                      {row.debitno}
                    </TableCell>
                    <TableCell align="left" style={{ ...style.amount, borderTop: "1px solid #E7E2Ef", fontFamily: "Inter, sans-serif" }}>
                      <img src={RupeeIcon} alt="rupee" style={{ marginRight: "5px" }} />
                      {row.amount}
                    </TableCell>
                    <TableCell align="left" style={{ ...style.againstinvoice, borderTop: "1px solid #E7E2Ef", fontFamily: "Inter, sans-serif" }}>
                      {row.againstinvoice}
                    </TableCell>
                    <TableCell style={{ borderTop: "1px solid #E7E2Ef" }}>
                      <Chip
                        label={row.status}
                        style={{
                          ...this.getStyleBasedOnStatus(row.status),
                          textTransform: "none"
                        }}
                      />
                    </TableCell>
                    <TableCell style={{ ...style.vendor, borderTop: "1px solid #E7E2Ef", fontFamily: "Inter,sans-serif" }}>
                      {row.vender}
                    </TableCell>
                    <TableCell style={{ ...style.Date, borderTop: "1px solid #E7E2Ef", fontFamily: "Inter, sans-serif" }}>
                      {row.date}
                    </TableCell>
                    <TableCell align="left" style={{ borderTop: "1px solid #E7E2Ef", borderRight: "1px solid #E7E2EF" }}>
                      <IconButton aria-label="more" onClick={this.handleMenuClick}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        keepMounted
                        anchorEl={this.state.anchorEl}
                        onClose={this.handleMenuClose}
                        PaperProps={{
                          style: { boxShadow: 'none', border: "1px solid #E7E2Ef" }
                        }}
                        open={Boolean(this.state.anchorEl)}
                        style={{ margin: "5px", borderRadius: "10px" }}
                      >
                        <MenuItem
                          style={{ width: "170px", fontFamily: "Inter, sans-serif", fontSize: "14px", fontWeight: 400, color: "#323842" }}
                            data-testId="menuIcon"
                            onClick={() => {
                              this.navigateToDebitnoteDetail();
                              this.handleMenuClose(); 
                            }}                            
                        >
                          <img src={invoiceIcon} alt="Invoice Icon" style={{ marginRight: "10px" }} />
                          View Debit Notes
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow style={{ height: "45px" }}></TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (<Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
      <Navbar {...this.props} history={this.props.history} />
      <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
        {this.debitdetails()}
      </SideBar>
    </Box>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const style = {
  mainDiv: {
    marginLeft: "47px",
    fontFamily: "Inter, sans-serif",
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "24px 0px",
    fontFamily: "Inter, sans-serif",
    fontSize: "24px",
    fontWeight: 700
  },

  heading: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "26px",
    textAlign: "left",
  } as React.CSSProperties,
  tableHeads: {
    backgroundColor: "#f3f4f6",
  },
  TableCells: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    textAlign: "left",
    color: "#565E6C",
    backgroundColor: "#F3F4F6",
  } as React.CSSProperties,
  debitno: {
    fontSize: "14px",
    fontWeight: 700
  },
  againstinvoice: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#323842"
  },
  vendor: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#323842"
  },
  amount: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#323842"
  },
  Date: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#323842"
  },

  Tabfont: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  navTabdiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "58px"
  },

  searchContainers: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 0px 0 0",
    width: "265px"
  },
  actionmenu: {
    width: "150px",
    boxShadow: "none",
  },
  searchInputs: {
    width: "173px",
    height: "35px",
    opacity: 1,
    borderRadius: "9px",
    padding: "0px 10px",
    backgroundColor: "#F3F4F6",
    fontFamily: "Inter, sans-serif",

  },
  inactiveTabs: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "left",
    color: "#000000",
  } as React.CSSProperties,
  activeTabs: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "20px",
    textAlign: "left",
    color: "#EE6900",
  } as React.CSSProperties,
};
// Customizable Area End
