import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history:any;
  // Customizable Area End
}


interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorEL:any;
  value:number;
  headers:any[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PaymentTimelineController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorEL: null,
      value: 0,
      headers:[
        { id: 'invoice', label: 'Invoice' },
        { id: 'invAmount', label: 'Invoice Amount' },
        { id: 'penAmound', label: 'Pending Amount' },
        { id: 'due', label: 'Due In' },
        { id: 'actions', label: 'Actions' },
      ]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {

  }
  handleTabChange = (event:React.ChangeEvent<{}>, newValue:number) => {
    this.setState({ value: newValue }, this.updateHeader);
  };

  handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    this.setState({ anchorEL: event.currentTarget || null });
  };
  handleMenuClose = (): void => {
    this.setState({ anchorEL: null });
  };
  updateHeader = () => {
    let newHeaders = [
      { id: 'invoice', label: 'Invoice' },
      { id: 'invAmount', label: 'Invoice Amount' },
      { id: 'penAmound', label: 'Pending Amount' },
      { id: 'due', label: 'Due In' },
      { id: 'actions', label: 'Actions' },
    ];
    if(this.state.value == 1){
      newHeaders = [
        { id: 'invoice', label: 'Invoice' },
        { id: 'invAmount', label: 'Invoice Amount' },
        { id: 'penAmound', label: 'Pending Amount' },
        { id: 'due', label: 'Due Since' },
        { id: 'actions', label: 'Actions' },
      ];
    };
    if(this.state.value == 2){
      newHeaders = [
        { id: 'invoice', label: 'Invoice' },
        { id: 'invAmount', label: 'Paid Amount' },
        { id: 'penAmound', label: 'Overdue Amount' },
        { id: 'due', label: 'Overdue' },
        { id: 'actions', label: 'Actions' },
      ];
    }
    this.setState({headers:newHeaders})
  }
  // Customizable Area End
}
