//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  // Customizable Area Start
  isloading: boolean;
  hoverIndex:number| null;
  focusedImageIndex: number | null;
  pageDescription: number;
  productId:string| number|null
  productData: {
    data: {
        id: string,
        type: string,
        attributes: {
            id: number,
            category_id: number,
            brand_id: number,
            upid_id: number,
            upid_version_id: number,
            name: string,
            brand_sku: string,
            unit_of_measurement: string,
            volume_bucket: string,
            item_group: number,
            alias: string,
            upc: string,
            product_description: string,
            minimum_order_quantity: number,
            unit_conversion: number,
            tax_percent: number,
            tax_slab: string,
            sample_availability: boolean,
            instant_quote_availability: string,
            product_type: string,
            hsn_san: string,
            description: string,
            ways_of_appliation: string,
            use: string,
            standards: string,
            prices: null,
            specification: object,
            is_recommended: boolean,
            status: string,
            product_id: null | number,
            category: string,
            upid_name: string,
            brand_name: string,
            documents: [
                {
                    title: string,
                    document_type: string,
                    file: string
                }
            ],
            certificates: [
                {
                    name: string,
                    file: string
                }
            ],
            quality_tests: [
                {
                    name: string,
                    file: string
                }
            ],
            primary_image: {
                url: string
            },
            images: [
                {
                    id: number,
                    url: string
                },
                {
                    id: number,
                    url: string
                }
            ],
            specifications: {
                id: number,
                brand: string,
                brand_collection_name: string,
                brand_sku: string,
                brand_sku_number: string,
                isin: string,
                is_specifications: string,
                standard_packaging: string,
                master_packaging: string,
                body_material: string,
                insulated_material: string,
                conductor_material: string,
                no_of_cores: string,
                colour: string,
                conductor_type: string,
                finish: string,
                no_of_module: string,
                no_of_steps: string,
                armoured_type: string,
                length_mm: string,
                width_mm: string,
                thickness_mm: string,
                diameter_mm: string,
                weight_kg_per_m2: string,
                size_sq_mm: string,
                voltage_v: string,
                sensitivity_ma: string,
                rated_current_a: string,
                power_w: string,
                copper_composition: string,
                aluminium_composition: string,
                pvc_composition: string,
                rubber_composition: string,
                steel_composition: string,
                metal_composition: string,
                thermal_conductivity_w_per_mk: string,
                temperature_rating_c: string,
                thermal_stability: string,
                fire_rating: string,
                category_id: null | number
            }
        }
    }
}
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductDescriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productApiItemCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      isloading: false,

      // Customizable Area Start
      isloading: false,
      productData: {},
      productId : null,
      focusedImageIndex:null,
      pageDescription: 1,
      hoverIndex:null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
   
      if (apiRequestCallId && apiRequestCallId === this.productApiItemCallId) {
        if (responseJson) {
          this.setState({ productData: responseJson.product });
        } else {
          this.parseApiErrorResponse(errorReponse);
        }
      }
       
     
    }

    // Customizable Area End
  }

  // Customizable Area Start
  
 

  async componentDidMount() {
    await super.componentDidMount(); 
    let params = this.props.navigation.getParam("navigationBarTitleText");   
    this.getPostData(params);
  }
  onGoBack = () => {
    this.props.navigation.navigate("BuildingBlocks");
  };

  getPostData = async (productId:string|number|null) => {
    try {
      const header = {
        "Content-Type": configJSON.productApiContentType,
        token: await getStorageData('authToken'),
      };
  
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.productApiItemCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productAPiEndPoint}/${productId}`
    );
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.productAPiMethod);
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return true;
    } catch (error) {
      console.error('Error fetching product data:', error);
      this.setState({ isloading: false });
    }
  };
  // Customizable Area End
}
