import React, { ChangeEvent } from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { State, City } from 'country-state-city';

export interface Location {
  countryCode: string;
  latitude: string;
  longitude: string;
  name: string;
  stateCode: string;
}

export interface Statelocation {
  countryCode: string;
  latitude: string;
  longitude: string;
  name: string;
  stateCode: string;
  isoCode: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: {
    location: {
      state:{
        user_id: string;
        phone: string
      }
    }
  }
  handleNext: () => void
  // Customizable Area Start
  passState:Function,
  addressStateData: {street: string, zipcode: string, selectedOption: string, selectedStateOption: string}
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  otpText: string;
  otpTextError: string;
  openDialog: boolean;
  currentStep: number;
  showDropDownCity: boolean;
  selectedOption: string;
  showDropDownState: boolean;
  selectedStateOption: string;
  showDropDownCountry: boolean;
  selectedCountryOption: string;
  addressData: {street: string, zipcode: string};
  fieldRequiredData: string;
  searchTerm: string;
  searchStateTerm: string;
  filteredCitiesData:  Location[];
  filteredStatesData:  Statelocation[]

  // Customizable Area Start
  fieldBilling:string;
  fieldCity:string;
  fieldStreet:string;
  fieldZip:string;
  valueBilling:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddressInfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      otpText: "",
      otpTextError: "",
      openDialog: false,
      currentStep: 1,
      showDropDownCity: false,
      selectedOption: "",
      showDropDownState: false,
      selectedStateOption: "",
      showDropDownCountry: false,
      selectedCountryOption: "India",
      addressData: {street: "", zipcode: ""},
      fieldRequiredData:"",
      searchTerm:"",
      searchStateTerm: "",
      filteredCitiesData: [],
      filteredStatesData: []

      // Customizable Area Start
      ,fieldBilling:'',
      fieldCity:'',
      fieldStreet:'',
      fieldZip:'',
      valueBilling:'',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount(); 
    this.setState({addressData: {...this.props.addressStateData}, selectedOption: this.props.addressStateData.selectedOption, selectedStateOption: this.props.addressStateData.selectedStateOption})

    const cities = City.getCitiesOfCountry('IN');
    const filteredCitie: Location[] | undefined = cities && cities.filter((city: { name: string }) => city.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())) as Location[];
    this.setState({ filteredCitiesData: filteredCitie ? filteredCitie : [] });
    
    const states = State.getStatesOfCountry('IN');
    const filteredState: Statelocation[] | undefined = states && states.filter((state:{name:string}) => state.name.toLowerCase().includes(this.state.searchStateTerm.toLowerCase())) as Statelocation[];
    this.setState({ filteredStatesData:filteredState})
  }

  handleDropdownCity = () => {
    this.setState({ showDropDownCity: !this.state.showDropDownCity })
  }

  handleDropdownState = () => {
    this.setState({ showDropDownState: !this.state.showDropDownState })
  }

  handleDropdownChange = (event: string) => {
    this.setState({ selectedOption: event, showDropDownCity: false});
    this.props.passState({...this.state.addressData,selectedOption:event,selectedStateOption:this.state.selectedStateOption,selectedCountryOption:this.state.selectedCountryOption})
    this.setState({fieldCity:''})
  };

  handleDropdownCountry = () => {
    this.setState({ showDropDownCountry: !this.state.showDropDownCountry })
  }

  handleStateChange = (event: string) => {
    this.setState({ selectedStateOption: event, showDropDownState: false, fieldRequiredData: ""});
    this.props.passState({...this.state.addressData,selectedStateOption:event,selectedOption:this.state.selectedOption,selectedCountryOption:this.state.selectedCountryOption})
  };

  handleValidation = () => {
   
    let message = "Required field";
      if(this.state.selectedStateOption && this.state.selectedCountryOption && this.state.valueBilling && this.state.addressData && this.state.selectedOption){
        this.props.handleNext()      
      }
      else{
        if(!this.state.fieldRequiredData){
          this.setState({fieldRequiredData: message})
        }
        if(!this.state.fieldBilling){
          this.setState({fieldBilling: message})
        }
        if(!this.state.addressData.street){
          this.setState({fieldStreet: message})
        }
        if(!this.state.selectedStateOption){
          this.setState({fieldCity: message})
        }
        if(!this.state.addressData.zipcode){
          this.setState({fieldZip: message})
        }
      }
  }   

  handleAddressInfoChange = (event:ChangeEvent<HTMLInputElement>) => {
    this.setState({addressData:{...this.state.addressData, [event.target.name]:event.target.value}})
    this.props.passState({...this.state.addressData, [event.target.name]:event.target.value, selectedOption: this.state.selectedOption, selectedStateOption: this.state.selectedStateOption, selectedCountryOption: this.state.selectedCountryOption})
    this.setState({fieldStreet:''})
  }
  handleAddressInfoChangeZip = (event:ChangeEvent<HTMLInputElement>) => {
    this.setState({addressData:{...this.state.addressData, [event.target.name]:event.target.value}})
    this.props.passState({...this.state.addressData, [event.target.name]:event.target.value, selectedOption: this.state.selectedOption, selectedStateOption: this.state.selectedStateOption, selectedCountryOption: this.state.selectedCountryOption})
    this.setState({fieldZip:''})
  }

  handleSearchChange = (event:ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchTerm: event.target.value });
  };

  handleStateSearch = (event:ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchStateTerm: event.target.value });
  };
  // Customizable Area End
}