export const seacrhIcon = require("../assets/search.svg");
export const filterIcon = require("../assets/filter.svg");
export const sortingIcon = require("../assets/sort.svg");
export const RupeeIcon = require("../assets/rupee.svg");
export const invoiceIcon = require("../assets/invoice.svg");
export const Logo = require("../assets/kx_logo.jpg");
export const Scanner = require("../assets/scanner.svg");



