import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  Hidden
} from '@material-ui/core';

import Navbar from "../../../components/src/Navbar.web";
import SideBar from "../../../components/src/SideBar.web";
import { styled } from "@material-ui/styles";
export const OrderBlock = styled("div")(({ theme }) => ({
  "& .orderDetailMain":{
    padding:"30px 50px",
    "@media (max-width:900px)": {
        padding:"30px 5px",
    },
  },
  "& .orderIdText":{
    font:"Inter, sans-serif",
    fontWeight:700,
    fontSize:"24px",
    lineHeight:"26px",
    color:"#171A1F"
  },
  "& .cancelBtn":{
    color:"#FFF",
    border:"1px solid transparent",
    backgroundColor:"#BDC1CA",
    borderRadius:"12px",
    padding:"8px 16px",
    font:"Inter, sans-serif",
    fontWeight:500,
    fontSize:"16px",
    lineHeight:"24px",
    marginRight:"7px",
    cursor:"pointer"
  },
  "& .SubmitBtnTop":{
    color:"#FFF",
    border:"1px solid transparent",
    backgroundColor:"#FF8A3C",
    borderRadius:"12px",
    padding:"8px 16px",
    font:"Inter, sans-serif",
    fontWeight:500,
    fontSize:"16px",
    lineHeight:"24px",
    marginLeft:"7px",
    cursor:"pointer"
  },
  "& .SubmitBtn":{
    color:"#FFF",
    border:"1px solid transparent",
    backgroundColor:"#FFA76C",
    borderRadius:"12px",
    padding:"8px 16px",
    font:"Inter, sans-serif",
    fontWeight:500,
    fontSize:"16px",
    lineHeight:"24px",
    marginLeft:"7px",
    cursor:"pointer"
  },
  "& .main":{
    border:"1px solid #E2E8F0",
    borderRadius:"8px",
    padding:"20px",
    marginTop:"25px",
    marginBottom:"18px",
  },
  "& .factoryAddress":{
    border:"1px solid #E1E7EF",
    padding:"20px",
    marginTop:"14px"
  },
  "& .factoryName":{
    fontWeight:700,
    fontSize:"14px",
    lineHeight:"22px",
    color:"#323842"
  },
  "& .factoryGstin":{
    fontWeight:500,
    fontSize:"14px",
    lineHeight:"22px",
    color:"#323842"
  },
  "& .factoryPan":{
    fontWeight:500,
    fontSize:"14px",
    lineHeight:"22px",
    color:"#323842"
  },
  "& .headerInfo":{
    fontWeight:700,
    fontSize:"14px",
    lineHeight:"22px",
    color:"#64748B"
  },
  "& .factoryAddress__factoryInfo":{
    fontWeight:700,
    fontSize:"14px",
    lineHeight:"22px",
    color:"#323842"
  },
  "& .mainFactoryInfo":{
    borderBottom:"1px solid #E1E7EF",
    paddingBottom:"16px",
    marginBottom:"16px"
  },
  "& .factoryAddress.MuiGrid-container": {
    flexWrap: "nowrap",
  },
  "& .MuiTypography-root":{
    fontFamily:"Inter, sans-serif"
  },
  "& .secondaryFactoryInfoText":{
    fontWeight:700,
    fontSize:"14px",
    lineHeight:"22px",
    color:"#323842"
  },
  "& .customerAddress":{
    border:"1px solid #E1E7EF",
    padding:"20px",
    marginTop:"14px",
    justifyContent: 'space-between',
    "@media (max-width:900px)": {
        justifyContent: 'center',
    },
  },
  "& .orderTable":{
    border:"1px solid #E2E8F0",
    width:"100%",
    margin:"23px 0px",
    borderCollapse:"collapse",
  },
  "& .orderTableGrid":{
    "@media (max-width:900px)": {
        overflowY:"scroll",
        width:"85vw",
    },
  },
  "& .th":{
    fontWeight:700,
    fontSize:"14px",
    lineHeight:"22px",
    color:"#565E6C",
    fontFamily:"Inter, sans-serif",
    padding:"16px 30px",
    backgroundColor:"#F3F4F6",
    "@media (max-width:900px)": {
        padding:"16px 3px",
        fontSize:"10px",
    },
  },
  "& .tdProductListRow":{
    borderBottom:"1px solid #E2E8F0",
    padding:"30px 13px",
    backgroundColor:"#FFF",
    verticalAlign:"top",
    "@media (max-width:900px)": {
        padding:"30px 3px",
    },
  },
  "& .index":{
    fontWeight:700,
    fontSize:"14px",
    lineHeight:"22px",
    fontFamily:"Inter, sans-serif",
    color:"#323842",
    textAlign:"center",
    "@media (max-width:900px)": {
        fontSize:"10px"
    },
  },
  "& .ProductListRow__itemTitle":{
    fontWeight:700,
    fontSize:"14px",
    lineHeight:"22px",
    fontFamily:"Inter, sans-serif",
    color:"#323842",
    "@media (max-width:900px)": {
        fontSize:"10px"
    },
  },
  "& .ProductListRow__itemDescription":{
    fontWeight:400,
    fontSize:"14px",
    lineHeight:"16px",
    fontFamily:"Inter, sans-serif",
    color:"#323842",
    "@media (max-width:900px)": {
        fontSize:"10px"
    },
  },
  "& .standartTable":{
    fontWeight:400,
    fontSize:"14px",
    lineHeight:"22px",
    fontFamily:"Inter, sans-serif",
    color:"#323842",
    "@media (max-width:900px)": {
        fontSize:"10px"
    },
  },
  "& .tableHsn":{
    lineHeight:"22px",
    textAlign:"center",
  },
  "& .ProductListRow__mainRate":{
    lineHeight:"22px",
    textAlign:"left",
  },
  "& .ProductListRow__secRate":{
    fontWeight:300,
    fontSize:"12px",
    lineHeight:"18px",
    fontFamily:"Inter, sans-serif",
    color:"#565E6C",
    textAlign:"left",
    "@media (max-width:900px)": {
        fontSize:"8px"
    },
  },
  "& .tableQty":{
    textAlign:"center",
  },
  "& .tableTaxValue":{
    textAlign:"end",
  },
  "& .tdProductListRow__mainTaxAmount":{
    color:"#000",
    textAlign:"end",
  },
  "& .tdProductListRow__secTaxAmount":{
    fontWeight:300,
    fontSize:"14px",
    lineHeight:"22px",
    fontFamily:"Inter, sans-serif",
    color:"#565E6C",
    textAlign:"end",
    "@media (max-width:900px)": {
        fontSize:"10px"
    },
  },
  "& .tableAmount":{
    textAlign:"center",
  },
  "& .responsivePaddingRight":{
    paddingRight:"13px",
    "@media (max-width:900px)": {
        paddingRight:"3px"
    },
  },
  "& .responsivePaddingLeft":{
    paddingLeft:"13px",
    "@media (max-width:900px)": {
        paddingLeft:"3px"
    },
  },
  "& .taxablevaluePadding":{
    paddingRight:"13px", 
    paddingLeft:"24px",
    "@media (max-width:900px)": {
        paddingRight:"1px", 
        paddingLeft:"2px",
    },
  },
  "& .tableMainInfoText":{
    padding:"19px 20px",
    border:"1px solid #E2E8F0",
    fontWeight:700,
    fontSize:"14px",
    lineHeight:"22px",
    fontFamily:"Inter, sans-serif",
    color:"#323842",
    backgroundColor:"#F0F0F0"
  },
  "& .tableMainInfoNumber":{
    padding:"19px 30px",
    border:"1px solid #E2E8F0",
    fontWeight:700,
    fontSize:"14px",
    lineHeight:"22px",
    fontFamily:"Inter, sans-serif",
    color:"#323842",
    backgroundColor:"#F3F4F6",
    textAlign:"right"
  },
  "& .tableMainInfoNumberInWords":{
    padding:"19px 30px",
    border:"1px solid #E2E8F0",
    fontWeight:400,
    fontSize:"14px",
    lineHeight:"22px",
    fontFamily:"Inter, sans-serif",
    color:"#323842",
    backgroundColor:"#F3F4F6",
    textAlign:"right"
  },
  "& .taxTable":{
    border:"1px solid #E2E8F0",
    width:"100%",
    marginBottom:"23px",
    borderCollapse:"collapse",
  },
  "& .taxTh":{
    padding:"10px 45px",
    border:"1px solid #E2E8F0",
    fontWeight:700,
    fontSize:"14px",
    lineHeight:"22px",
    fontFamily:"Inter, sans-serif",
    color:"#565E6C",
    backgroundColor:"#F3F4F6",
  },
  "& .taxThTot":{
    padding:"10px 24px",
    border:"1px solid #E2E8F0",
    fontWeight:700,
    fontSize:"14px",
    lineHeight:"22px",
    fontFamily:"Inter, sans-serif",
    color:"#565E6C",
    backgroundColor:"#F3F4F6",
    textAlign:"right"
  },
  "& .taxThHsn":{
    padding:"10px 24px",
    border:"1px solid #E2E8F0",
    fontWeight:700,
    fontSize:"14px",
    lineHeight:"22px",
    fontFamily:"Inter, sans-serif",
    color:"#565E6C",
    textAlign:"left"
  },
  "& .taxCell":{
    padding:"20px 45px",
    border:"1px solid #E2E8F0",
    fontWeight:400,
    fontSize:"14px",
    lineHeight:"22px",
    fontFamily:"Inter, sans-serif",
    color:"#323842",
    textAlign:"center"
  },
  "& .taxHsn":{
    padding:"20px 24px",
    border:"1px solid #E2E8F0",
    fontWeight:700,
    fontSize:"14px",
    lineHeight:"22px",
    fontFamily:"Inter, sans-serif",
    color:"#323842",
    textAlign:"left"
  },
  "& .totalRow":{
    backgroundColor:"#F3F4F6",
    fontWeight:500
  },
  "& .totalHsn":{
    backgroundColor:"#F0F0F0"
  },
  "& .taxTotal":{
    padding:"20px 24px",
    border:"1px solid #E2E8F0",
    fontWeight:400,
    fontSize:"14px",
    lineHeight:"22px",
    fontFamily:"Inter, sans-serif",
    color:"#323842",
    textAlign:"right"
  },
  "& .taxHeadInfoBottom":{
    fontWeight:700,
    fontSize:"14px",
    lineHeight:"22px",
    fontFamily:"Inter, sans-serif",
    color:"#565E6C",
  },
  "& .taxInfoBottom":{
    fontWeight:700,
    fontSize:"14px",
    lineHeight:"22px",
    fontFamily:"Inter, sans-serif",
    color:"#323842",
  },
  "& .taxInfoBottomSign":{
    fontWeight:500,
    fontSize:"14px",
    lineHeight:"22px",
    fontFamily:"Inter, sans-serif",
    color:"#565E6C",
  },
  "& .signContainer":{
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    alignItems:"end"
  },
  "& .taxDetails":{
    paddingBottom:"24px",
    borderBottom:"2px solid #E2E8F0"
  },
  "& .BottomInfo__title":{
    fontWeight:500,
    fontSize:"14px",
    lineHeight:"22px",
    fontFamily:"Inter, sans-serif",
    color:"#565E6C",
  },
  "& .BottomInfo__text":{
    fontWeight:500,
    fontSize:"14px",
    lineHeight:"22px",
    fontFamily:"Inter, sans-serif",
    color:"#323842",
  },
  "& .BottomInfo":{
    paddingTop:"24px"
  },
  "& .shadow":{
    position:"fixed",
    backgroundColor:"rgba(0, 0, 0, 0.4)",
    width:"100vw",
    height:"100vh",
    top:0,
    left:0,
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },
  "& .popup":{
    border:"1px solid transparent",
    maxWidth:"30vw",
    backgroundColor:"#FFF",
    borderRadius:"8px",
    "@media (max-width:900px)": {
      maxWidth:"95vw"
    },
  },
  "& .popupTitle":{
    fontWeight:700,
    fontSize:"24px",
    lineHeight:"32px",
    fontFamily:"Inter, sans-serif",
    color:"#272727",
  },
  "& .popupHead":{
    display:"flex",
    justifyContent:"space-between",
    padding:"24px",
    alignItems:"center",
    borderBottom:"1px solid #E2E8F0",
    width:"100%"
  },
  "& .popupBottom":{
    display:"flex",
    justifyContent:"flex-end",
    padding:"30px 24px",
    alignItems:"center",
    borderTop:"1px solid #E2E8F0",
    width:"100%"
  },
  "& .mainContent":{
    padding:"20px 24px",
  },
  "& .mainContent__text":{
    fontWeight:400,
    fontSize:"14px",
    lineHeight:"22px",
    fontFamily:"Inter, sans-serif",
    color:"#272727",
  },
  "& .MuiGrid-grid-lg-1":{
    "@media (min-width:1280px)": {
      maxWidth:"10.5%",
    },

  }
}))
// Customizable Area End
import OrderDetailController, {
  Props
} from "./OrderDetailController.web";
import { logo } from "./assets";

export default class OrderDetail extends OrderDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  dummyProductOrderList = Array(3).fill({
    title: "Product Title",
    description: "Sample Product Description goes here",
    hsn: 12345678,
    mainRate: "90.00",
    secRate: "100.00 (-10.00%)",
    qty: 50,
    taxValue: "4,500.00",
    mainTaxAmount: "0.00",
    secTaxAmount: "(0%)",
    amount: "4,500.00"
  });
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <OrderBlock>
        <Navbar history={this.props.history} mycart={true}/>
        <SideBar navigation={this.props.navigation} mycart={true}>
            <Grid container direction="column" className="orderDetailMain">
                <Grid item>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                            <Typography className="orderIdText">
                                PO-240218-0011
                            </Typography>
                        </Grid>
                        <Grid item>
                            <button className="cancelBtn">
                                Cancel
                            </button>
                            <button className="submitBtnTop" onClick={this.handleSubmitPopup}>
                                Submit
                            </button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className="main">
                    <Grid container className="factoryAddress" direction="column">
                        <Grid item>
                            <Grid container className="mainFactoryInfo">
                                <Grid item direction="column" xs={4} sm={4} md={2} lg={1} alignItems="center">
                                    <img src={logo} />
                                </Grid>
                                <Grid item direction="column" xs={8} sm={8} md={6} lg={6}>
                                    <Typography className="factoryName">KRATEX TRADETECH INDIA PRIVATE LIMITED</Typography>
                                    <Typography className="factoryGstin"><span style={{fontWeight:700}}>GSTIN: </span>27AAKCK5699N1ZG</Typography>
                                    <Typography className="factoryPan"><span style={{fontWeight:700}}>PAN: </span>AAKCK5699N</Typography>
                                </Grid>
                                <Grid item direction="column" xs={6} sm={6} md={2} lg={2}>
                                    <Typography className="headerInfo">Address:</Typography>
                                    <Typography className="factoryAddress__factoryInfo">C46, Abhimanshree Society,</Typography>
                                    <Typography className="factoryAddress__factoryInfo">Pune, Maharashtra</Typography>
                                    <Typography className="factoryAddress__factoryInfo">411007</Typography>
                                </Grid>
                                <Grid item direction="column" xs={6} sm={6} md={2} lg={2}>
                                    <Typography className="headerInfo">Contact Details:</Typography>
                                    <Typography className="factoryAddress__factoryInfo">Mobile:<span style={{fontWeight:500}}>+91-9876543210</span></Typography>
                                    <Typography className="factoryAddress__factoryInfo">Email: <span style={{fontWeight:500}}>info@kratex.com</span></Typography>
                                    <Typography className="factoryAddress__factoryInfo">Website: <span style={{fontWeight:500}}>kratex.co</span></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row">
                                <Grid item xs={6} sm={6} md={3} lg={3}>
                                    <Typography className="secondaryFactoryInfoText"><span style={{color:"#565E6C"}}>Purchase Order:</span> PO-240218-0010-1</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={3} lg={3}>
                                    <Typography className="secondaryFactoryInfoText"><span style={{color:"#565E6C"}}>Purchase Order Date:</span> 05 Feb 2024</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={3} lg={3}>
                                    <Typography className="secondaryFactoryInfoText"><span style={{color:"#565E6C"}}>PO Validity:</span> 05 Feb 2024</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={3} lg={3}>
                                    <Typography className="secondaryFactoryInfoText"><span style={{color:"#565E6C"}}>Place of Supply:</span> 27-Maharashtra</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" className="customerAddress">
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <Grid container direction="column">
                                <Grid item>
                                    <Typography className="headerInfo">Customer Details: </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="factoryAddress__factoryInfo">Test Customer</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="factoryAddress__factoryInfo">GSTIN: 27AAKCK5699N1ZG</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="factoryAddress__factoryInfo">PAN: AAKCK5699N</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <Grid container direction="column">
                                <Grid item>
                                    <Typography className="headerInfo">Billing Address: </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="factoryAddress__factoryInfo">C46, Abhimanshree Society</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="factoryAddress__factoryInfo">Abhimanshree Society, </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="factoryAddress__factoryInfo">Pune, Maharashtra, 411007</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <Grid container direction="column">
                                <Grid item>
                                    <Typography className="headerInfo">Shipping Address: </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="factoryAddress__factoryInfo">C46, Abhimanshree Society</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="factoryAddress__factoryInfo">Abhimanshree Society, </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="factoryAddress__factoryInfo">Pune, Maharashtra, 411007</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid> 
                    <Grid container className="orderTableGrid">
                    <table cellPadding={0} cellSpacing={0} className="orderTable">
                        <tr className="orderHeaderRow">
                            <th className="th">#</th>
                            <th className="th" style={{paddingLeft:"13px", textAlign:"left"}}>Item</th>
                            <th className="th">HSN/SAC</th>
                            <th className="th responsivePaddingLeft" style={{textAlign:"left"}}>Rate/Item</th>
                            <th className="th">Qty</th>
                            <th className="th responsivePaddingRight" style={{textAlign:"right"}}>Taxable Value</th>
                            <th className="th responsivePaddingRight" style={{textAlign:"right"}}>Tax Amount</th>
                            <th className="th">Amount</th>
                        </tr>
                        {this.dummyProductOrderList.map((elem, index)=>{
                            return(
                                <tr className="ProductListRow" data-test-id="productOrder">
                                    <td className="tdProductListRow index">{index+1}</td>
                                    <td className="tdProductListRow">
                                        <div className="ProductListRow__itemTitle">
                                            {elem.title}
                                        </div>
                                        <div className="ProductListRow__itemDescription">
                                            {elem.description}
                                        </div>
                                    </td>
                                    <td className="tdProductListRow ProductListRow__itemDescription tableHsn">{elem.hsn}</td>
                                    <td className="tdProductListRow">
                                        <div className="ProductListRow__mainRate ProductListRow__itemDescription">
                                            {elem.mainRate}
                                        </div>
                                        <div className="ProductListRow__secRate">
                                            {elem.secRate}
                                        </div>  
                                    </td>
                                    <td className="tdProductListRow tableQty standartTable">{elem.qty}</td>
                                    <td className="tdProductListRow tableTaxValue standartTable">{elem.taxValue}</td>
                                    <td className="tdProductListRow">
                                        <div className="tdProductListRow__mainTaxAmount standartTable">
                                            {elem.mainTaxAmount}
                                        </div>
                                        <div className="tdProductListRow__secTaxAmount">
                                            {elem.secTaxAmount}
                                        </div>  
                                    </td>
                                    <td className="tdProductListRow tableAmount standartTable">{elem.amount}</td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td colSpan={2} className="tableMainInfoText">
                                Total Items (Qty)
                            </td>
                            <td colSpan={6} className="tableMainInfoNumber">
                                ₹ 150.00
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} className="tableMainInfoText">
                                Taxable Amount
                            </td>
                            <td colSpan={6} className="tableMainInfoNumber">
                                ₹ 1,35,000.00
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} className="tableMainInfoText">
                            Total Discount
                            </td>
                            <td colSpan={6} className="tableMainInfoNumber">
                                ₹ 500.00
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} className="tableMainInfoText">
                            Total Amount (in words)
                            </td>
                            <td colSpan={6} className="tableMainInfoNumberInWords">
                                INR One Lakh Thirty Five Thousand Only
                            </td>
                        </tr>
                    </table>
                    </Grid>
                    <Grid container className="orderTableGrid">
                        <table cellPadding={0} cellSpacing={0} className="taxTable">
                            <thead>
                                <tr>
                                    <th rowSpan={2} className="taxThHsn totalHsn">HSN/SAC</th>
                                    <th rowSpan={2} className="taxTh">Taxable Value</th>
                                    <th colSpan={2} className="taxTh">Central Tax</th>
                                    <th colSpan={2} className="taxTh">State Tax</th>
                                    <th rowSpan={2} className="taxThTot">Total Tax Amount</th>
                                </tr>
                                <tr>
                                    <th className="taxTh">Rate</th>
                                    <th className="taxTh">Amount</th>
                                    <th className="taxTh">Rate</th>
                                    <th className="taxTh">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="taxHsn">12345678</td>
                                    <td className="taxCell">4500</td>
                                    <td className="taxCell">0%</td>
                                    <td className="taxCell">0.00</td>
                                    <td className="taxCell">0%</td>
                                    <td className="taxCell">0.00</td>
                                    <td className="taxTotal">0.00</td>
                                </tr>
                                <tr>
                                    <td className="taxHsn totalHsn">Total</td>
                                    <td className="taxCell totalRow">4500</td>
                                    <td className="taxCell totalRow"></td>
                                    <td className="taxCell totalRow">0.00</td>
                                    <td className="taxCell totalRow"></td>
                                    <td className="taxCell totalRow">0.00</td>
                                    <td className="taxTotal totalRow">0.00</td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                    <Grid container direction="row" className="taxDetails">
                        <Grid item xs={12} sm={12} md={6} lg={6} direction="column" justifyContent="flex-start">
                            <Typography className="taxHeadInfoBottom">Bank Details: </Typography>
                            <Typography className="taxInfoBottom">Bank: Yes Bank</Typography>
                            <Typography className="taxInfoBottom">Account Number: 000863400003864</Typography>
                            <Typography className="taxInfoBottom">IFSC: YESB0000008</Typography>
                            <Typography className="taxInfoBottom">Branch: SHIVAJI NAGAR, PUNE</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} direction="column" className="signContainer">
                            <Typography className="taxHeadInfoBottom">For KrateX Tradetech India Pvt Ltd</Typography>
                            <Typography className="taxInfoBottomSign">Authorized Signatory</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" className="BottomInfo" justifyContent="space-between">
                        <Grid item xs={12} sm={12} md={5} lg={5} direction="column" justifyContent="flex-start">
                            <Typography className="BottomInfo__title">Notes:</Typography>
                            <Typography className="BottomInfo__text">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer 
                                malesuada condimentum odio vitae malesuada. Proin quis ligula 
                                eget lacus luctus pretium. Mauris hendrerit justo vel posuere eleifend. 
                                Nullam mollis odio sem, vel lacinia massa 
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} direction="column">
                        <Typography className="BottomInfo__title">Terms and Conditions:</Typography>
                            <Typography className="BottomInfo__text">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                when an unknown printer took a galley of type 
                                and scrambled it to make a type specimen book.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end">
                    <Hidden>
                        {this.state.showSubmitPopup && <Box className="shadow"><Grid container direction="column" alignItems="center" justifyContent="center" className="popup" data-test-id="submitMessage">
                            <Grid item alignItems="center" justifyContent="space-between" direction="row" className="popupHead">
                                <Typography className="popupTitle">Submit Order</Typography>
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={this.handleSubmitPopup}>
                                    <path d="M23 10.41L21.59 9L16 14.59L10.41 9L9 10.41L14.59 16L9 21.59L10.41 23L16 17.41L21.59 23L23 21.59L17.41 16L23 10.41Z" fill="#334155"/>
                                </svg>
                            </Grid>
                            <Grid item className="mainContent">
                                <Typography className="mainContent__text">
                                    Are you sure want to submit the order <span style={{fontWeight:700}}>PO-240218-00102-1</span> ? 
                                    Once submitted, the order will be released to vendor for further actions.
                                </Typography>
                            </Grid>
                            <Grid item className="popupBottom">
                            <button className="cancelBtn" onClick={this.handleSubmitPopup}>
                                Cancel
                            </button>
                            <button className="submitBtn" onClick={this.handleSubmitPopup}>
                                Submit
                            </button>
                            </Grid>
                        </Grid></Box>}
                    </Hidden>
                </Grid>
            </Grid>      
        </SideBar>
      </OrderBlock>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  
}
// Customizable Area End
