import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start 
  Tab,
  Tabs,
  InputBase,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
  InputAdornment,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
const dummyTableData = [
  {
    payId: "PAY-240218-0011",
    company: {
      project: "Project 1",
      companyName: "Company Name",
    },
    transId: "TID-240218-0010-2",
    status: "Pending",
    date: "09 Feb 2024",
    amount: "28,541,745",
  },
  {
    payId: "PAY-240218-0012",
    company: {
      project: "Project One",
      companyName: "Name",
    },
    transId: "TID-240218-0011-3",
    status: "Rejected",
    date: "10 Feb 2024",
    amount: "64,917,333",
  },
  {
    payId: "PAY-240218-0013",
    company: {
      project: "Project Zero",
      companyName: "Company N",
    },
    transId: "TID-240218-0012-4",
    status: "Confirmed",
    date: "11 Feb 2024",
    amount: "45,456,193",
  },
]
const tableData = Array(3).fill(null).reduce(acc => acc.concat(dummyTableData), []);
import Navbar from "../../../components/src/Navbar.web";
import SideBar from "../../../components/src/SideBar.web";
import { acknowledgement, filtericon, searchicon, sortingicon } from "./assets";
import { styled } from "@material-ui/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";


import PaymentSettlementController, {
  Props,
} from "./PaymentSettlementController";

export const SettleBlock = styled("div")(({ theme }) => ({
  "& .MuiTab-root": {
    minWidth: "160px",
    "@media (max-width:1600px)": {
      minWidth: "120px"
    },
    "@media (max-width:1400px)": {
      minWidth: "100px"
    },
    "@media (max-width:1200px)": {
      minWidth: "80px"
    },
  },
  "& .Maincontainer": {
    width: "94%",
    margin: "0px 20px 0px 45px",
    fontFamily: "Inter, sans-serif",
    "@media (max-width:600px)": {
      margin: "0px 0px 0px 0px",
    },
  },
  "& .MuiTableCell-root": {
    "@media (max-width:600px)": {
      padding: "4px",
      fontSize: "10px",
    },
  },
  "& .TableCellHead": {
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    color: "#565E6C",
    backgroundColor: "#F3F4F6",
    borderTop: "none",
    borderBottom: "none",
    "@media (max-width:600px)": {
      fontSize: "10px",
    },
  },
  "& .searchInput1": {
    width: "40px",
    height: "35px",
    opacity: 1,
    borderRadius: "9px",
    padding: "0px 10px",
    backgroundColor: "#F3F4F6",
    fontFamily: "Inter, sans-serif",
    "@media (min-width:600px)": {
      width: '173px',
    },
    '&:focus-within': {
      width: '173px',
    },
  },
  "& .MuiInputBase-input:focus-within": {
    "@media (max-width:600px)": {
      width: '173px',
    },
  },
  "& .MuiIconButton-root": {
    "@media (max-width:600px)": {
      padding: "2px"
    },
  },
  "& .confirmedStatusRow": {
    backgroundColor: "rgba(55, 154, 230, 0.1)",
    border: "1px solid transparent",
    borderRadius: "6px",
    padding: "8px 10px",
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    lineHeight: "17px",
    fontWeight: 400,
    color: "#379AE6",
    minWidth: "80px"
  },
  "& .pendingStatusRow": {
    backgroundColor: "#FFF6F0",
    border: "1px solid transparent",
    borderRadius: "6px",
    padding: "8px 10px",
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    lineHeight: "17px",
    fontWeight: 400,
    color: "#EE6900",
    minWidth: "80px"
  },
  "& .rejectedStatusRow": {
    backgroundColor: "rgba(222, 59, 64, 0.1)",
    border: "1px solid transparent",
    borderRadius: "6px",
    padding: "8px 10px",
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    lineHeight: "17px",
    fontWeight: 400,
    color: "#DE3B40",
    minWidth: "80px"
  },
  "& .MuiPaper-elevation8": {
    boxShadow: "none"
  },
  "& .globalContainer": {
    border: "1px solid #E7E2F0",
    borderRadius: "6px"
  },
  "& .navTabCont": {
    flexGrow: 1,
    display: "flex",
    overflowX: "auto",
    borderRadius: "10px 0px"
  },
  "& .icoSize":{
    width: 20, height: 20
  },
  "& .headerTab":{
    textTransform: "none", 
    height: "58px",
  },
  "& .headerTab__box":{
    display: "flex"
  },
  "& .tableHead":{
    backgroundColor: "#F3F4F6",
  },
  "& .emptyTableCell":{
    border: 'none', 
    width: '0%'
  },
  "& .TableBody":{
    width: "94%",
    margin: "0 auto",
  },
  "& .EmptyTableRow":{
    height: "40px"
  },
  "& .TableRow":{
    border: "none",
  },
  "& .TableRow:nth-of-type(odd)": {
    backgroundColor: "#F9F9F9",
  },
}))

export default class PaymentSettlement extends PaymentSettlementController {
  constructor(props: Props) {
    super(props);

  }

  render() {
    const tabLabels = [
      "All Payments",
      "Pending Confirmation",
      "Confirmed",
      "Rejected",
    ];

    const headers = [
      { id: 'payId', label: 'PAY ID' },
      { id: 'date', label: 'Date' },
      { id: 'company', label: 'Company' },
      { id: 'transactionId', label: 'Transaction ID' },
      { id: 'amount', label: 'Amount' },
      { id: 'status', label: 'Status' },
      { id: 'actions', label: 'Actions' },
    ];

    const { value } = this.state;
    return (
      <SettleBlock>
        <Navbar history={this.props.history} mycart={true} />
        <Box component="nav" style={{ backgroundColor: "#fff" }}>
          <SideBar navigation={this.props.navigation} mycart={true}>
            <Box className="MainContainer">
              <Box style={styles.header}>
                <Typography variant="h6" component="div" style={styles.heading}>
                  Payment Settlements
                </Typography>
              </Box>

              <Box className="globalContainer">
                <Box style={styles.navTabContainer}>
                  <Box className="navTabCont">
                    <Tabs value={value} onChange={this.handleTabChange} TabIndicatorProps={{
                      style: { background: "#EE6900", height: "4px", borderRadius: "8px" },
                    }}>
                      {tabLabels.map((label, index) => (
                        <Tab
                          key={index}
                          className="headerTab"
                          label={
                            <Box className="headerTab__box" alignItems="center">
                              <Typography
                                style={
                                  value === index
                                    ? { ...styles.activeTab, textAlign: 'center' }
                                    : { ...styles.inactiveTab, textAlign: 'center' }
                                }
                              >
                                {label}
                              </Typography>
                            </Box>
                          }
                        />
                      ))}
                    </Tabs>
                  </Box>
                  <Box style={styles.searchContainer2}>
                    <InputBase
                      placeholder="Search"
                      className="searchInput1"
                      inputProps={{
                        style: { fontFamily: 'inter, sans-serif', fontSize: 12, fontWeight: 400, color: "rgba(0, 0, 0, 0.87)",
                        },
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <img src={searchicon} alt="search icon" className="icoSize" />
                        </InputAdornment>
                      }
                    />
                    <img src={sortingicon} data-testId="filterIcon"
                      alt="sorting icon" className="icoSize" style={{marginLeft: "10px" }} />
                    <IconButton>
                      <img src={filtericon} data-testId="MoreVertIcon"
                        alt="filter icon" className="icoSize"/>
                    </IconButton>
                  </Box>
                </Box>

                <TableContainer style={styles.tableContainer}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow className="tableHead">
                        <TableCell className="emptyTableCell"></TableCell>
                        {headers.map(({ id, label }) => (
                          <TableCell key={id} align="left" className="TableCellHead">
                            {label}
                          </TableCell>
                        ))}
                        <TableCell className="emptyTableCell"></TableCell>


                      </TableRow>
                    </TableHead>
                    <TableBody className="TableBody">
                      <TableRow className="EmptyTableRow"></TableRow>
                      {tableData.map((row: any) => (
                          <TableRow className="TableRow">
                            <TableCell style={{ ...styles.tableCell, width: "0%", border: "none" }}></TableCell>
                            <TableCell style={{ ...styles.purchaseId, fontFamily: "Inter, sans-serif", borderLeft: "1px solid #E7E2F0", borderTop: "1px solid #E7E2F0" }}>
                              {row.payId}
                            </TableCell>
                            <TableCell style={{ ...styles.amount, fontFamily: "Inter, sans-serif", borderTop: "1px solid #E7E2F0" }}>{row.date}</TableCell>
                            <TableCell style={styles.tableCell}>
                              <Box>
                                <Typography style={{ ...styles.projectname, fontFamily: "Inter, sans-serif" }}>
                                  {row.company.project}
                                </Typography>
                                <Typography variant="caption" style={{ ...styles.companyName, fontFamily: "Inter, sans-serif" }}>
                                  {row.company.companyName}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell style={{ ...styles.amount, fontFamily: "Inter, sans-serif", borderTop: "1px solid #E7E2F0" }}>{row.transId}</TableCell>
                            <TableCell style={{ ...styles.amount, fontFamily: "Inter, sans-serif", borderTop: "1px solid #E7E2F0" }}>
                              <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.52034e-05 0.857208C8.52034e-05 0.383065 0.357239 0 0.799311 0H7.19312C7.63519 0 7.99234 0.383065 7.99234 0.857208C7.99234 1.33135 7.63519 1.71442 7.19312 1.71442H5.78948C6.02925 2.10016 6.20657 2.53412 6.30648 3.00023H7.19312C7.63519 3.00023 7.99234 3.38329 7.99234 3.85744C7.99234 4.33158 7.63519 4.71464 7.19312 4.71464H6.30398C5.9743 6.27637 4.75798 7.47914 3.25194 7.68541L6.85844 10.4472C7.21809 10.7231 7.30051 11.2589 7.04326 11.642C6.78601 12.025 6.2865 12.1161 5.92934 11.8402L0.334761 7.55415C0.0525344 7.33984 -0.0673495 6.95142 0.0375489 6.59515C0.142447 6.23887 0.452147 6.00046 0.799311 6.00046H2.79738C3.61658 6.00046 4.3209 5.47274 4.6281 4.71464H0.799311C0.357239 4.71464 8.52034e-05 4.33158 8.52034e-05 3.85744C8.52034e-05 3.38329 0.357239 3.00023 0.799311 3.00023H4.6281C4.3209 2.24214 3.61658 1.71442 2.79738 1.71442H0.799311C0.357239 1.71442 8.52034e-05 1.33135 8.52034e-05 0.857208Z" fill="#323842" />
                              </svg>&nbsp;
                              {row.amount}
                            </TableCell>
                            <TableCell style={styles.tableCell}>
                              {this.renderStatus(row.status)}
                            </TableCell>
                            <TableCell style={{
                              ...styles.tableCell,
                              borderRight: "1px solid #E7E2F0",
                            }}>
                              <IconButton
                                aria-label="more"
                                data-test-id="open-menu-ico"
                                onClick={(event) =>
                                  this.handleMenuClick(event)
                                }
                                data-testId="menuIcon"
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                anchorEl={this.state.anchorEL}
                                PaperProps={{style:{boxShadow: 'none', border: "1px solid #E7E2Ef", borderRadius:"8px"}}}
                                keepMounted
                                open={Boolean(this.state.anchorEL)}
                                onClose={this.handleMenuClose}
                                data-test-id="popup-menu"
                              >
                                <MenuItem data-testId="invoicemenuItem" style={{ ...styles.menuitem, fontFamily: "Inter, sans-serif" }} onClick={() => this.getNavigationMessage('PaymentAcknowledgement')}>
                                  <img src={acknowledgement} style={styles.imagedropdown} />
                                  View Acknowledgement
                                </MenuItem>
                              </Menu>
                            </TableCell>
                            <TableCell style={{ width: "0%", border: "none" }}></TableCell>
                          </TableRow>
                      ))}
                      <TableRow className="EmptyTableRow"></TableRow>

                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </SideBar >
        </Box >
      </SettleBlock>
    )
  }
}

export const styles = {
  Maincontainer: {
    width: "94%",
    margin: "0px 20px 0px 45px",
    fontFamily: "Inter, sans-serif",

  },
  header: {
    alignItems: "left",
    margin: "45px 0px"
  },
  heading: {
    fontFamily: "Inter, sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "26px",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
  },
  searchInput: {
    width: "173px",
    height: "35px",
    borderRadius: "8px",
    backgroundColor: "F3F4F6"
  },
  navTabContainer: {
    fontfamily: "Inter, sans-serif",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  inactiveTab: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "left",
    color: "#000000",
  },
  activeTab: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "20px",
    textAlign: "left",
    color: "#EE6900",
  },
  TableHead: {
    backgroundColor: "#F3F4F6",
  },
  TableCellHead: {
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    color: "#565E6C",
    backgroundColor: "#F3F4F6",
  },
  tableCell: {
    borderTop: "1px solid #E7E2F0"
  },
  firstChip: {
    fontfamily: "Inter, sans-serif",
    height: "23px",
    borderRadius: "6px",
    fontSize: "11px",
    fontWeight: "400",
    backgroundColor: "#EB8689",
    color: "#FFF"
  },

  secondchip: {
    fontfamily: "Inter, sans-serif",
    height: "23px",
    borderRadius: "6px",
    fontSize: "11px",
    fontWeight: "400",
    backgroundColor: "#FFFFFF",
    color: "#E7A573",
    border: "1px solid #E7A573",
  },
  thirdchip: {
    fontfamily: "Inter, sans-serif",
    height: "23px",
    borderRadius: "6px",
    fontSize: "11px",
    fontWeight: "400",
    backgroundColor: "#E7A573",
    color: "#FFFFFF",
    lineHeight: "17px",
    padding: "0px 14px",
    display: "flex",
    alignItems: "center"
  },
  TableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F9F9F9",
    },
  },
  TableCell: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    color: "#101828",
    borderTop: "1px solid #E2E8F0",
    width: "100%",
  },
  searchContainer2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 20px 0 0",
  },
  searchInput1: {
    width: "173px",
    height: "35px",
    opacity: 1,
    borderRadius: "9px",
    padding: "0px 10px",
    backgroundColor: "#F3F4F6",
    fontFamily: "Inter, sans-serif",
  },
  purchaseId: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"
  },
  Project: {
    fontSize: "13px",
    fontWeight: 700,
  },
  companyName: {
    fontSize: "12px",
    fontWeight: 300,
    color: "#64748B"
  },
  amount: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#323842"
  },
  projectname: {
    fontSize: "13px",
    fontWeight: 700,
    color: "#323842"
  },
  Statusbutton: {
    height: "32px",
    borderRadius: "9px",
    backgroundColor: "#FFF6F0",
    color: "#EE6900",
    fontSize: "12px",
    fontWeight: 400,
  },
  date: {
    fontSize: "13px",
    fontWeight: 400
  },
  time: {
    fontSize: "12px",
    fontWeight: 300,
    color: "#64748B"
  },
  imagedropdown: {
    width: "18px",
    height: "18px",
    marginRight: "5px"
  },
  menu: {
    hight: "120px",
  },
  menuitem: {
    fontSize: "14px",
    fontWeight: 400,
  },
  tableContainer: {
    borderRadius: "4px",
    boxShadow: "none",
  },
  TBody: {
    width: "94%",
    margin: "0 auto",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonContainer: {
    marginTop: "20px",
    display: "flex",
    width: "474px",
    gap: "8px",
  },
  poid: {
    fontSize: "12px",
    fontWeight: 700,
  },
  subcribed: {
    height: "22px",
    borderRadius: "6px",
    margin: "0px 0px 0px 9px",
    fontSize: "11px",
    fontWeight: 400,
    backgroundColor: "#E3E7ED",
    color: "#565E6C"
  },
  viewbutton: {
    fontSize: "13px",
    fontWeight: 400,
    width: "130px",
    height: "36px",
    borderRadius: "12px",
    color: "#E7A573",
    backgroundColor: "#FFFFFF",
    marginTop: "5px"
  },
  Product: {
    width: "100%",
    marginTop: "10px",
    marginRight: "0px",
  },
  producttitle: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#323842"
  },
  productvalue: {
    fontSize: "12px",
    fontWeight: 300,
    color: "#64748B"
  },
  purchaseID: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#323842",
    marginLeft: "50px"
  },
  Purchasehead: {
    marginRight: "20px",
    fontSize: "12px",
    fontWeight: 500,
    color: "#67748B",
  },
  Scheduled: {
    fontSize: "12px",
    fontWeight: 500,
    color: "rgba(50, 56, 66, 1)",
    marginLeft: "50px"
  },
  DeliveryDate: {
    marginTop: "8px",
    marginRight: "30px",
    fontSize: "12px",
    fontWeight: 500,
    color: "#67748B"
  },
  Floorchip: {
    backgroundColor: "#F0F6FF",
    fontSize: "11px",
    fontWeight: 400,
    color: "#579AFF"
  }
}

// Customizable Area End
