import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start 
  Tab,
  Tabs,
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start


import PaymentAcknowledgementController, {
  Props,
} from "./PaymentAcknowledgementController";
import Navbar from "../../../components/src/Navbar.web";
  import SideBar from "../../../components/src/SideBar.web";
  import { styled } from "@material-ui/styles";
import { logo, paid_status } from "./assets";
import { styles } from "./PaymentSettlement.web";

  const SettleBlock = styled("div")(({ theme }) => ({
    "& .MuiTab-root":{
      minWidth: "160px",
      "@media (max-width:1600px)": {
        minWidth:"120px"
      },
      "@media (max-width:1400px)": {
        minWidth:"100px"
      },
      "@media (max-width:1200px)": {
        minWidth:"80px"
      },
    },
    "& .Maincontainer": {
      width: "94%",
      margin: "0px 20px 0px 45px",
      fontFamily: "Inter, sans-serif",
      "@media (max-width:600px)": {
        margin: "0px 0px 0px 0px",
      },
    },
    "& .factoryAddress":{
      border:"1px solid #E1E7EF",
      padding:"20px",
      marginTop:"14px"
    },
    "& .factoryName":{
      fontWeight:700,
      fontSize:"14px",
      lineHeight:"22px",
      color:"#323842"
    },
    "& .factoryGstin":{
      fontWeight:500,
      fontSize:"14px",
      lineHeight:"22px",
      color:"#323842"
    },
    "& .factoryPan":{
      fontWeight:500,
      fontSize:"14px",
      lineHeight:"22px",
      color:"#323842"
    },
    "& .headerInfo":{
      fontWeight:700,
      fontSize:"14px",
      lineHeight:"22px",
      color:"#64748B"
    },
    "& .factoryAddress__factoryInfo":{
      fontWeight:700,
      fontSize:"14px",
      lineHeight:"22px",
      color:"#323842"
    },
    "& .mainFactoryInfo":{
      borderBottom:"1px solid #E1E7EF",
      paddingBottom:"16px",
      marginBottom:"16px"
    },
    "& .factoryAddress.MuiGrid-container": {
      flexWrap: "nowrap",
    },
    "& .MuiTypography-root":{
      fontFamily:"Inter, sans-serif"
    },
    "& .secondaryFactoryInfoText":{
      fontWeight:700,
      fontSize:"14px",
      lineHeight:"22px",
      color:"#323842",
      marginBottom:"16px"
    },
    "& .customerInvoiceDetail":{
      paddingTop:"16px"
    },
    "& .customerInvoiceDetails":{
      fontWeight:700,
      fontSize:"14px",
      lineHeight:"22px",
      color:"#323842",
    },
    "& .paidStatus":{
      height:"120px"
    },
    "& .paidStatusCont":{
      display:"flex",
      justifyContent:"flex-end"
    },
    "& .payStatus":{
      borderBottom:"1px solid #E2E8F0"
    },
    "& .downloadBtn":{
      fontWeight:500,
      fontSize:"16px",
      lineHeight:"24px",
      color:"#FFF",
      padding:"10px 16px",
      backgroundColor:"#FFA76C",
      border:"1px solid transparent",
      cursor:"pointer",
      borderRadius:"12px"
    },
    "& .downloadContainer":{
      display:"flex",
      justifyContent:"flex-end",
      marginTop:"137px",
      marginBottom:"44px",
      "@media (max-width:900px)": {
        marginTop:"50px"
      },
    },
    "& .MuiTabs-flexContainer":{
      overflowY:"auto"
    }
  }))

export default class PaymentAcknowledgement extends PaymentAcknowledgementController {
  constructor(props: Props) {
    super(props);

  }

  render() {
    const tabLabels = [
        "Ack 01",
        "Ack 2",
        "Ack 3",
        "Ack 4",
        "Ack 5",
      ];

      const { value } = this.state;
    return(
        <SettleBlock>
        <Navbar history={this.props.history} mycart={true}/>
        <Box component="nav" style={{ backgroundColor: "#fff" }}>
            <SideBar navigation={this.props.navigation} mycart={true}>
            <Box className="MainContainer">
                <Box style={styles.header}>
                <Typography variant="h6" component="div" style={styles.heading}>
                  Payment Acknowledgement
                </Typography>
                </Box>
                <Box style={{ border: "1px solid #E7E2F0", borderRadius: "6px" }}>
                <Box style={styles.navTabContainer}>
                    <Box
                    style={{
                        flexGrow: 1,
                        display: "flex",
                        overflowX: "auto",
                        borderRadius: "10px 0px"
                    }}
                    >
                    <Tabs value={value} onChange={this.handleTabChange} TabIndicatorProps={{
                        style: { background: "#EE6900", height: "4px", borderRadius: "8px" },
                    }}>
                        {tabLabels.map((label, index) => (
                        <Tab
                            key={index}
                            style={{
                            textTransform: "none", height: "58px",
                            }}
                            label={
                            <Box style={{ display: "flex" }} alignItems="center">
                                <Typography
                                style={
                                    value === index
                                    ? { ...styles.activeTab, textAlign: 'center' }
                                    : { ...styles.inactiveTab, textAlign: 'center' }
                                }
                                >
                                {label}
                                </Typography>
                            </Box>
                            }
                        />
                        ))}
                    </Tabs>
                    </Box>
                </Box>
                <Box style={{padding:"20px", borderTop:"1px solid #E2E8F0"}}>
                  <Grid container className="factoryAddress" direction="column">
                          <Grid item>
                              <Grid container className="mainFactoryInfo">
                                  <Grid item direction="column" xs={4} sm={4} md={2} lg={2} xl={1} alignItems="center">
                                      <img src={logo} />
                                  </Grid>
                                  <Grid item direction="column" xs={8} sm={8} md={7} lg={6} xl={7}>
                                      <Typography className="factoryName">KRATEX TRADETECH INDIA PRIVATE LIMITED</Typography>
                                      <Typography className="factoryGstin"><span style={{fontWeight:700}}>GSTIN: </span>27AAKCK5699N1ZG</Typography>
                                      <Typography className="factoryPan"><span style={{fontWeight:700}}>PAN: </span>AAKCK5699N</Typography>
                                  </Grid>
                                  <Grid item direction="column" xs={6} sm={6} md={2} lg={2} xl={2}>
                                      <Typography className="headerInfo">Address:</Typography>
                                      <Typography className="factoryAddress__factoryInfo">C46, Abhimanshree Society,</Typography>
                                      <Typography className="factoryAddress__factoryInfo">Pune, Maharashtra</Typography>
                                      <Typography className="factoryAddress__factoryInfo">411007</Typography>
                                  </Grid>
                                  <Grid item direction="column" xs={6} sm={6} md={2} lg={2} xl={2}>
                                      <Typography className="headerInfo">Contact Details:</Typography>
                                      <Typography className="factoryAddress__factoryInfo">Mobile:<span style={{fontWeight:500}}>+91-9876543210</span></Typography>
                                      <Typography className="factoryAddress__factoryInfo">Email: <span style={{fontWeight:500}}>info@kratex.com</span></Typography>
                                      <Typography className="factoryAddress__factoryInfo">Website: <span style={{fontWeight:500}}>kratex.co</span></Typography>
                                  </Grid>
                              </Grid>
                          </Grid>
                          <Grid item>
                              <Grid container direction="row" justifyContent="space-between" alignItems="center" className="payStatus">
                                  <Grid item xs={12} sm={12} md={9} lg={9} direction="column">
                                      <Typography className="secondaryFactoryInfoText"><span style={{color:"#565E6C"}}>Payment #:</span> PAYIN-2</Typography>
                                      <Typography className="secondaryFactoryInfoText"><span style={{color:"#565E6C"}}>Payment Date:</span> 04 Apr 2024</Typography>
                                      <Typography className="secondaryFactoryInfoText"><span style={{color:"#565E6C"}}>Status:</span> Success</Typography>
                                      <Typography className="secondaryFactoryInfoText"><span style={{color:"#565E6C"}}>Mode:</span> Net Banking</Typography>
                                      <Typography className="secondaryFactoryInfoText"><span style={{color:"#565E6C"}}>Amount:</span> 1,70,002.60</Typography>
                                      <Typography className="secondaryFactoryInfoText"><span style={{color:"#565E6C"}}>Invoice:</span> INV - 5, 170002.6</Typography>
                                      <Typography className="secondaryFactoryInfoText"><span style={{color:"#565E6C"}}>Notes:</span> -</Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <div className="paidStatusCont">
                                      <img src={paid_status} className="paidStatus" data-test-id="payment-status"/>
                                    </div>
                                  </Grid>
                              </Grid>
                          </Grid>
                          <Grid item direction="column" className="customerInvoiceDetail">
                            <Typography className="customerInvoiceDetails" style={{color:"#565E6C"}}>Customer Details:</Typography>
                            <Typography className="customerInvoiceDetails">Test Customer</Typography>
                            <Typography className="customerInvoiceDetails">GSTIN: 27AAKCK5699N1ZG</Typography>
                            <Typography className="customerInvoiceDetails">PAN: AAKCK5699N</Typography>
                          </Grid>
                  </Grid>  
                </Box>
                
                </Box>
                <Box className="downloadContainer">
                  <button className="downloadBtn">Download</button>
                </Box>
            </Box>
            </SideBar >
        </Box >
      </SettleBlock>
    )
  }
}


// Customizable Area End
