import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import {
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
import { glass } from "./assets";
export const configJSON = require("./config");

export interface Props {
  history:any
  navigation:any
}

interface S {
    selectedCategory:string[];
    dummyLevelData:any[];
    openPopupVersion:boolean;
    openPopupMap:boolean;
    newVersion:string;
    dummyUpidData:any[];
}

interface SS {
  id: string;
}

export default class CategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllCompanyList: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {
        selectedCategory:["Categorization"], 
        openPopupVersion:false,
        openPopupMap:false,
        newVersion:'',
        dummyLevelData:[
          {
            upid:'XXXXXXXX01',
            nVersion:2,
            nSku:4
          },
          {
            upid:'XXXXXXXX02',
            nVersion:5,
            nSku:25
          },
          {
            upid:'XXXXXXXX03',
            nVersion:3,
            nSku:14
          },
          {
            upid:'XXXXXXXX04',
            nVersion:7,
            nSku:45
          },
          {
            upid:'XXXXXXXX05',
            nVersion:9,
            nSku:12
          },
          {
            upid:'XXXXXXXX06',
            nVersion:10,
            nSku:65
          },
          {
            upid:'XXXXXXXX07',
            nVersion:1,
            nSku:1
          },
          {
            upid:'XXXXXXXX08',
            nVersion:234,
            nSku:54
          },
          {
            upid:'XXXXXXXX09',
            nVersion:23,
            nSku:32
          }     
        ],
        dummyUpidData:Array(9).fill(
          {
            img:glass,
            name:'AAC Blocks4"',
            size:'625x240x100mm',
            make:'Alpiine',
            category:"Blackwork",
            subCategory:"AAC Blocks",
            brand:"Havells"
          }
        ),
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount(); 
  }
  renderCategory = (categories: any) => {
    return(<>
    <Typography className="heading-txt">{this.state.selectedCategory[this.state.selectedCategory.length - 1] || "Categories"}</Typography>
    <Grid container spacing={4} className="category">{categories.map((category:any, index:number) => {
      return (
        <Grid item xs={6} sm={6} md={2} lg={2} xl={2} key={index} onClick={() => this.setState({ selectedCategory: [...this.state.selectedCategory, this.handleBredcrums(category)] })}>
          <Box sx={{ height: 130, bgcolor: "#FBFBFB", borderRadius: 12, border: "1px solid #F5F6F7", display:"flex", justifyContent:"center", alignItems:"end", padding:"10px"}} className="type-txt-cat" style={{cursor:"pointer"}}>
            <Typography className="type-txt">{category}</Typography>
          </Box>
        </Grid>
      );
    })}
    </Grid>
    </>)
  }
  handleBredcrums = (category:any) =>{
    if(this.state.selectedCategory.length ==1){
      return 'Level 01'
      
    }else return category
  }
  renderLevel = (upidData:any[], level:number) =>{
    return(<>
      <Typography className="heading-txt">{this.state.selectedCategory[this.state.selectedCategory.length - 1] || "Categories"}{this.state.selectedCategory.length==9?'(UPID)':''}</Typography>
        <table cellPadding={0} cellSpacing={0} className="levelTable">
      <thead className="levelTable__head">
        <tr className="levelTable__head__row">
          <th className="th tableUpid">
            UPID
          </th>
          <th className="th tableOthers">
            No of Versions
          </th>
          <th className="th tableOthers">
            No of SKUs
          </th>
          <th className="th tableOthersActions">
            Actions
          </th>
        </tr>
      </thead>
      <tr>
      <td colSpan={4}>
      <table className="levelTable__body" cellPadding={0} cellSpacing={0}>

        
        {upidData.map((elem:any, i:number)=>{
          return(
            <tr className="levelTable__body__row" onClick={() => this.levelHandleClick(elem.upid, level)}>
              <td className="table__start td tableUpidBody">{elem.upid}</td>
              <td className="table__middle td tableOthersBody">{elem.nVersion}</td>
              <td className="table__middle td tableOthersBody">{elem.nSku}</td>
              <td className="table__end td tableOthersBodyActions">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM10 18C10 16.9 10.9 16 12 16C13.1 16 14 16.9 14 18C14 19.1 13.1 20 12 20C10.9 20 10 19.1 10 18Z" fill="#979797"/>
              </svg>
              </td>
            </tr>
          )
        })
        }
        {this.footerTR()}
      </table>
      </td>
      </tr>
    </table>
    
    </>
    )
    
  }
  renderUpid = (upidData:any[]) =>{
    return(<>
    <div className="upidProductsContainer">
      <Typography className="heading-txt">{this.state.selectedCategory[this.state.selectedCategory.length - 1]}</Typography>
      <button className="newProductBtn">Add New Product</button>
    </div>
        <table cellPadding={0} cellSpacing={0} className="levelTable">
      <thead className="levelTable__head">
        <tr className="levelTable__head__row">
          <th className="th tableUpid">
            Product
          </th>
          <th className="th tableOthers">
            Category
          </th>
          <th className="th tableOthers">
            Brand
          </th>
          <th className="th tableOthersActions">
            Actions
          </th>
        </tr>
      </thead>
      <tr>
      <td colSpan={4}>
      <table className="levelTable__body" cellPadding={0} cellSpacing={0}>

        
        {upidData.map((elem:any, i:number)=>{
          return(
            <tr className="levelTable__body__row_u">
              <td className="table__start td">
                <div className="upidTableContainer">
                  <div className="upidImgContainer">
                    <img src={elem.img} className="upidImg"/>
                  </div>
                  <div>
                    <Typography className="upidTableName">{elem.name}</Typography>
                    <div className="upidTableSubname">
                      <Typography className="upidTableSize">Size: {elem.size}&nbsp;</Typography>
                      <Typography className="upidTableMake">Make: {elem.make}</Typography>
                    </div>
                  </div>
                </div>
              </td>
              <td className="table__middle td tableOthersBody">
                <Typography className="upidTableCategory">{elem.category}</Typography>
                <Typography className="upidTableSubcategory">{elem.subCategory}</Typography>
              </td>
              <td className="table__middle td tableOthersBody">{elem.brand}</td>
              <td className="table__end td tableOthersBodyActions">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM10 18C10 16.9 10.9 16 12 16C13.1 16 14 16.9 14 18C14 19.1 13.1 20 12 20C10.9 20 10 19.1 10 18Z" fill="#979797"/>
              </svg>
              </td>
            </tr>
          )
        })
        }
        {this.footerTR()}
      </table>
      </td>
      </tr>
    </table>
    </>
    )
    
  }

  footerTR = () =>{
    return(
    <tr>
          <td colSpan={4}>
          <Grid container direction="row" justifyContent="space-between" className="paginationTable">
            <Grid item direction="row">
              <Typography className="pagination__info">
                1 - 12 of 48 results
              </Typography>
            </Grid>
            <Grid item direction="row" className="pagination">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5016 15.2292L11.2682 11.9958L14.5016 8.76249C14.8266 8.43749 14.8266 7.91249 14.5016 7.58749C14.3459 7.43144 14.1345 7.34375 13.9141 7.34375C13.6936 7.34375 13.4823 7.43144 13.3266 7.58749L9.50156 11.4125C9.17656 11.7375 9.17656 12.2625 9.50156 12.5875L13.3266 16.4125C13.6516 16.7375 14.1766 16.7375 14.5016 16.4125C14.8182 16.0875 14.8266 15.5542 14.5016 15.2292Z" fill="#979797"/>
            </svg>
            <Typography className="pagination__number-active">1</Typography>
            <Typography className="pagination__number">2</Typography>
            <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.65341 16.0682C4.44318 16.0682 4.26278 15.9929 4.11222 15.8423C3.96165 15.6918 3.88636 15.5114 3.88636 15.3011C3.88636 15.0909 3.96165 14.9105 4.11222 14.7599C4.26278 14.6094 4.44318 14.5341 4.65341 14.5341C4.86364 14.5341 5.04403 14.6094 5.1946 14.7599C5.34517 14.9105 5.42045 15.0909 5.42045 15.3011C5.42045 15.4403 5.38494 15.5682 5.31392 15.6847C5.24574 15.8011 5.15341 15.8949 5.03693 15.9659C4.9233 16.0341 4.79545 16.0682 4.65341 16.0682ZM7.9581 16.0682C7.74787 16.0682 7.56747 15.9929 7.4169 15.8423C7.26634 15.6918 7.19105 15.5114 7.19105 15.3011C7.19105 15.0909 7.26634 14.9105 7.4169 14.7599C7.56747 14.6094 7.74787 14.5341 7.9581 14.5341C8.16832 14.5341 8.34872 14.6094 8.49929 14.7599C8.64986 14.9105 8.72514 15.0909 8.72514 15.3011C8.72514 15.4403 8.68963 15.5682 8.61861 15.6847C8.55043 15.8011 8.4581 15.8949 8.34162 15.9659C8.22798 16.0341 8.10014 16.0682 7.9581 16.0682ZM11.2628 16.0682C11.0526 16.0682 10.8722 15.9929 10.7216 15.8423C10.571 15.6918 10.4957 15.5114 10.4957 15.3011C10.4957 15.0909 10.571 14.9105 10.7216 14.7599C10.8722 14.6094 11.0526 14.5341 11.2628 14.5341C11.473 14.5341 11.6534 14.6094 11.804 14.7599C11.9545 14.9105 12.0298 15.0909 12.0298 15.3011C12.0298 15.4403 11.9943 15.5682 11.9233 15.6847C11.8551 15.8011 11.7628 15.8949 11.6463 15.9659C11.5327 16.0341 11.4048 16.0682 11.2628 16.0682Z" fill="#979797"/>
            </svg>
            <Typography className="pagination__number">8</Typography>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.50156 7.59246C9.17656 7.91746 9.17656 8.44246 9.50156 8.76746L12.7349 12.0008L9.50156 15.2341C9.17656 15.5591 9.17656 16.0841 9.50156 16.4091C9.82656 16.7341 10.3516 16.7341 10.6766 16.4091L14.5016 12.5841C14.8266 12.2591 14.8266 11.7341 14.5016 11.4091L10.6766 7.58412C10.3599 7.26746 9.82656 7.26746 9.50156 7.59246Z" fill="#979797"/>
            </svg>

            </Grid>
          </Grid>
          </td>
        </tr>)
  }
  levelHandleClick = (upid:any, level:number) =>{
    if(this.state.selectedCategory.length==9){
      this.setState({ selectedCategory: [...this.state.selectedCategory, upid] })
    } else{
      this.setState({ selectedCategory: [...this.state.selectedCategory, `Level 0${level}`] })
    }
  }
  onChangeVersion = (event: any) => {
    const inputValue = event.target.value.toUpperCase().replace(/[^A-Z0-9-]/g, '');
    this.setState({ newVersion: inputValue });
  };
  handlePopupVersion = () =>{
    this.setState({openPopupVersion:!this.state.openPopupVersion})
  }
  handlePopupMap = () =>{
    this.setState({openPopupMap:!this.state.openPopupMap})
  }
  handleNewVersion = () =>{
    this.setState({dummyLevelData:[...this.state.dummyLevelData, {
      upid:this.state.newVersion,
      nVersion:5,
      nSku:4
    },],
    openPopupVersion:false})
  }
  renderCategoryNine = (categories: any) => {
    return(<>
      <Grid container direction="column" data-test-id='lastCategory'>
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography className="heading-txt">{this.state.selectedCategory[this.state.selectedCategory.length - 1] || "Categories"}</Typography>
            </Grid>
            <Grid item alignItems="center" justifyContent="space-around" className="button" xs={12} sm={12} md={6} lg={5} xl={5}>
              <button className="buttonMap" onClick={this.handlePopupMap}>Map Variation</button>
              <button className="buttonVersion" onClick={this.handlePopupVersion}>Add New Version</button>
              <button className="buttonUpid">Add UPID Details</button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={4} className="category">{categories.map((category:any, index:number) => {
            return (
              <Grid item xs={6} sm={6} md={2} lg={2} xl={2} key={index} onClick={() => this.setState({ selectedCategory: [...this.state.selectedCategory, category.upid] })}>
                <Box sx={{ height: 130, bgcolor: "#FBFBFB", borderRadius: 12, border: "1px solid #F5F6F7", display:"flex", justifyContent:"center", alignItems:"end", padding:"10px" }} style={{cursor:"pointer"}}>
                  <Typography className="type-txt">{category.upid}</Typography>
                </Box>
              </Grid>
            );
          })}
          </Grid>
        </Grid>
        
      </Grid>
    </>
    )
  }
  handleToggleSidebar = () => { }   
}
