//@ts-nocheck
import React from "react";
// Customizable Area Start
import {
  Typography,
  Box,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  InputAdornment,
  Chip,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Navbar from "../../../components/src/Navbar.web";
import SideBar from "../../../components/src/SideBar.web";
import { filterIcon, invoiceIcon, RupeeIcon, seacrhIcon, sortingIcon } from "./assets";
// Customizable Area End

import InvoiceBillingController, {
  Props,
  configJSON,
} from "./InvoiceBillingController.web";

export default class InvoiceBilling extends InvoiceBillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  taxinvoice = () => {
    const tabData = [
      { label: 'All Invoices', value: 0 },
      { label: 'Pending', value: 1 },
      { label: 'Paid', value: 2 },
      { label: 'Cancelled', value: 3 },
    ];

    const headers = [
      { id: 'taxinvoice', label: 'Tax Invoice' },
      { id: 'poreference', label: 'PO Reference' },
      { id: 'amount', label: 'Amount' },
      { id: 'irn', label: 'IRN' },
      { id: 'status', label: 'Status' },
      { id: 'company', label: 'Company' },
      { id: 'datetime', label: 'Date & Time' },
      { id: 'action', label: 'Actions' },
    ];

    const invoiceDetails = [
      {
        taxinvoice: 'INV-240218-0010-1',
        poreference: 'PO-240218-0010-1',
        amount: '28,541,745',
        irn: '1234...9876',
        status: 'Pending',
        company: {
          project: 'Project 1',
          cname: 'Company Name'
        },
        datetime: {
          date: '09 Feb 2024',
          time: '10:00 AM'
        },
      },
      {
        taxinvoice: 'INV-240218-0010-2',
        poreference: 'PO-240218-0010-2',
        amount: '28,541,745',
        irn: '1234...9876',
        status: 'Paid',
        datetime: {
          date: '09 Feb 2024',
          time: '10:00 AM'
        },
        company: {
          project: 'Project 1',
          cname: 'Company Name'
        },
      },
      {
        taxinvoice: 'INV-240218-0010-3',
        poreference: 'PO-240218-0010-3',
        irn: '1234...9576',
        status: 'Cancelled',
        company: {
          project: 'Project 2',
          cname: 'Company Name1'
        },
        datetime: {
          date: '09 Feb 2024',
          time: '10:00 AM'
        },
        amount: '28,541,775',
      },
      {
        poreference: 'PO-240218-0010-3',
        taxinvoice: 'INV-240218-0010-3',
        amount: '28,541,745',
        status: 'Pending',
        irn: '1234...9876',
        company: {
          project: 'Project 1',
          cname: 'Company Name',
        },
        datetime: {
          time: '10:20 AM',
          date: '09 Feb 2023',
        }
      }, 
      {
        taxinvoice: 'INV-240218-0010-1',
        irn: '1234...9876',
        status: 'Paid',
        amount: '28,541,745',
        datetime: {
          date: '09 Feb 2024',
          time: '10:00 AM'
        },
        company: {
          project: 'Project 1',
          cname: 'Company Name'
        },
        poreference: 'PO-240218-0010-1',
      },
       {
         poreference: 'PO-240218-0010-1',
         irn: '1234...9876',
         amount: '28,541,745',
         company: {
           project: 'Project 1',
           cname: 'Company Name'
          },
          taxinvoice: 'INV-240218-0010-1',
          datetime: {
            date: '09 Feb 2024',
            time: '10:00 AM'
          },
          status: 'Cancelled',
      },
    ];

    return (
      <Box style={{ width: "94%", ...style.mainContainer }}>
        <Box style={style.header}>
          <Typography variant="h6" style={{...style.heading, fontFamily: "Inter, sans-serif"}}>
            Tax Invoices
          </Typography>
        </Box>
        <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
          <Box style={{ ...style.navTabContainer }}>
            <Box
              style={{
                flexGrow: 1,
                overflowX: "auto",
                display: "flex",
              }}>
          <Tabs
  TabIndicatorProps={{style: {  height: "4px",background: "#EE6900", borderRadius: "8px" }}}
  value={this.state.value}
onChange={(event, newValue) => this.handleTabChange(newValue)}
  
>
  {tabData.map(({ label, value }) => (
    <Tab
      label={
        <Typography
          style={this.state.value !== value ? style.inactiveTab : style.activeTab}
        >
          {label}
        </Typography>
      }
      style={{
        textTransform: "none",
        fontFamily: "Inter, sans-serif",
        minWidth: "80px", 
        padding: "6px 35px", 
        height: "58px",
      }}
      key={value}
    />
  ))}
</Tabs>
            </Box>
            <Box style={style.searchContainer}>
              <InputBase
                placeholder="Search"
                style={style.searchInput}
                inputProps={{
                  style: {
                    fontFamily: 'inter, sans-serif',
                    fontSize: 12,
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 0.87)",
                  },
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <img src={seacrhIcon} alt="search icon" style={{ width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
              <img src={sortingIcon}  data-testId="filterIcon" alt="sorting icon" style={{ width: 20, height: 20, marginLeft: "8px" }} />
              <IconButton>
                <img src={filterIcon} data-testId="" alt="MoreVertIcon" style={{ width: 20, height: 20 }} />
              </IconButton>
            </Box>
          </Box>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead style={{ ...style.tableHead }}>
                <TableRow style={{ borderBottom: "none" }}>
                  <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                  {headers.map(({ id, label }) => (
                    <TableCell key={id} align="left" style={{ ...style.TableCell , borderTop: "none", borderBottom: "none", fontFamily: "Inter, sans-serif" }}>
                      {label}
                    </TableCell>
                  ))}
                  <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              <TableRow style={{ height: "40px" }}></TableRow>
{invoiceDetails.map((row, index) => (
  <TableRow key={index}>
    <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
    <TableCell align="left" style={{ ...style.taxinvoice , borderTop: "1px solid #E7E2Ef", borderLeft: "1px solid #E7E2EF" , fontFamily:"Inter, sans-serif"}}>
      {row.taxinvoice}
    </TableCell>
    <TableCell align="left" style={{ ...style.poreference, borderTop: "1px solid #E7E2Ef", fontFamily:"Inter, sans-serif" }}>
      {row.poreference}
    </TableCell>
    <TableCell align="left" style={{ ...style.amount , borderTop: "1px solid #E7E2Ef" , fontFamily:"Inter, sans-serif" }}>
      <img src={RupeeIcon} style={{marginRight:"7px", marginTop:"2px"}} />
      {row.amount}
    </TableCell>
    <TableCell align="left" style={{ ...style.irn , borderTop: "1px solid #E7E2Ef", fontFamily:"Inter, sans-serif" }}>
      {row.irn}
    </TableCell>
    <TableCell style={{ borderTop: "1px solid #E7E2Ef" }}>
      <Chip
        label={row.status}
        style={{
          ...style.Statusbutton,
          ...this.getStyleBasedOnStatus(row.status), 
          textTransform: "none"
        }}
      />
    </TableCell>
    <TableCell style={{ borderTop: "1px solid #E7E2Ef" }}>
      <Box>
        <Typography style={{ ...style.projectname ,fontFamily: "Inter, sans-serif" }}>
          {row.company.project}
        </Typography>
        <Typography style={{ ...style.cName ,fontFamily: "Inter, sans-serif" }}>
          {row.company.cname}
        </Typography>
      </Box>
    </TableCell>
    <TableCell style={{ borderTop: "1px solid #E7E2Ef" }}>
      <Box>
        <Typography style={{ ...style.Date,fontFamily: "Inter, sans-serif" }}>
          {row.datetime.date}
        </Typography>
        <Typography style={{ ...style.Time,fontFamily: "Inter, sans-serif" }}>
          {row.datetime.time}
        </Typography>
      </Box>
    </TableCell>
    <TableCell align="left" style={{ borderTop: "1px solid #E7E2Ef", borderRight: "1px solid #E7E2EF" }}>
    <IconButton aria-label="more" data-testId="MoreVertIcon" onClick={this.handleMenuClick}>
  <MoreVertIcon />
</IconButton>
<Menu
  anchorEl={this.state.anchorEl}
  keepMounted
  open={Boolean(this.state.anchorEl)}
  onClose={this.handleMenuClose} 
  PaperProps={{
    style: { boxShadow: 'none', border: "1px solid #E7E2Ef" },
  }}
  style={{ margin: "5px", borderRadius: "10px" }}
>
  <MenuItem
    onClick={() => {
      this.navigateToTaxInvoice();
      this.handleMenuClose(); 
    }}
    data-testId="InvoicemenuItem"
    style={{ width: "170px", fontFamily: "Inter, sans-serif", fontSize: "14px", fontWeight: 400, color: "#323842" }}
  >
    <img src={invoiceIcon} style={{ marginRight: "7px" }} alt="Invoice Icon" />
    View Tax Invoice
  </MenuItem>
</Menu>
    </TableCell>
  </TableRow>
))}
<TableRow style={{ height: "40px" }}></TableRow>

              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (<Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
      <Navbar {...this.props} history={this.props.history} handleNavigate={this.handleNavigate} />
      <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
        {this.taxinvoice()}
      </SideBar>
    </Box>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const style = {
  mainContainer: {
    marginLeft: "47px",
    fontFamily: "Inter, sans-serif",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "24px 0px",
    fontFamily: "Inter, sans-serif",
  },
  menuHeader: { fontSize: "14px", fontWeight: 500, fontFamily: "inter, sans-serif", color: '#323842', lineHeight: '22px', width: "1085px" },

  heading: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "26px",
    textAlign: "left",
  },
  tableHead: {
    backgroundColor: "#f3f4f6",
  },
  TableCell: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    textAlign: "left",
    color: "#565E6C",
    backgroundColor: "#F3F4F6",
  },
  taxinvoice:{
    fontSize: "14px",
    fontWeight: 700
  },
  poreference:{
    fontSize: "14px",
    fontWeight: 400,
    color: "#323842"
  },
  amount: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#323842"
  },
  irn:{
 fontSize: "14px",
    fontWeight: 400,
    color: "#323842"
  },
  projectname :{
    fontSize: "14px",
    fontWeight : 700
  },
  cName:{
    fontSize: "12px",
    fontWeight : 300,
  color : "#64748B"
  },
  Date:{
    fontSize: "13px",
    fontWeight : 400,
    color : "#323842"
  },
  Time: {
    fontSize: "12px",
    fontWeight : 300,
  color : "#64748B"
  },
  singleBox: { display: "flex", gap: "15px" },
  toggleBox: { display: "flex", alignItems: "center", justifyContent: "space-between", padding: "17px", cursor: "pointer", backgroundColor: "#f4f4f4", margin: "19px", border: "8px" },
  mainContent: { display: "flex", alignItems: "center", justifyContent: "space-between", padding: "17px", margin: "19px" },


  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  Tabfont: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  navTabContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "58px"
  },

  icon: {
    margin: "auto 5px",
  },
  searchContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 0px 0 0",
    width: "265px"
  },
  actionmenu: {
    width: "150px",
    boxShadow: "none",
  },
  searchInput: {
    width: "173px",
    height: "35px",
    opacity: 1,
    borderRadius: "9px",
    padding: "0px 10px",
    backgroundColor: "#F3F4F6",
    fontFamily: "Inter, sans-serif",

  },
  inactiveTab: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "left",
    color: "#000000",
  },
  activeTab: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "20px",
    textAlign: "left",
    color: "#EE6900",
  },
};
// Customizable Area End
