import React from "react";

import {
  Box,
  Typography,
  Checkbox,
  Hidden,
  // Customizable Area Start
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import { close, flag, imgDown } from "./assets";

const InfoBlock = styled("div")(({ theme }) => ({
  cursor:"default",
  "& .label-text": {
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
  },
  "& .input-data": {
    width: "100%",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    marginTop: "10px",
    padding: "10px"
  },
  "& .input-data:focus": {
    borderColor: "#FF6600",
    outline: "none",
  },
  '& .country-code': {
    gap: "10px",
    marginTop: "13px",
    border: "1px solid #CBD7E1",
    width: "83px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    padding: "10px",
    borderRadius: "10px 0px 0px 10px",
    height: "23px"
  },
  '& .country-code-red': {
    display: "flex",
    gap: "10px",
    marginTop: "13px",
    border: "1px solid red",
    width: "83px",
    padding: "10px",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    borderRadius: "10px 0px 0px 10px",
    height: "23px"
  },
  "& .flag-icon": {
    width: "29px",
    height: "20px",
    borderRadius: "3px"
  },
  "& .phone-input": {
    width: "100%",
    height: "45px",
    padding: "10px",
    marginTop: "13px",
    border: "1px solid #CBD7E1",
    borderRadius: "0px 10px 10px 0px",
    pointerEvents: "none",
    backgroundColor: "#f4f4f4",
    color: "#888",
  },
  "& .phone-input: focus": {
    borderColor: "#FF6600",
    outline: "none",
  },
  "& .phone-input-red": {
    width: "236px",
    height: "46px",
    padding: "10px",
    marginTop: "12px",
    border: "1px solid red",
    borderRadius: "0px 10px 10px 0px"
  },
  "& .phone-input[type=number]::-webkit-inner-spin-button,.phone-input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    "appearance": "none",
    margin: 0,
  },
  "& .phone-input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& .error-msg": {
    color: "red",
    marginTop: "5px",
    fontSize: "12px"
  },
  "& .dropdown-list-data-language li": {
    padding: "5px",
    margin: "7px 0px"
  },
  "& .dropdown-list-data-language": {
    fontFamily: "Red Hat Display",
    position: "absolute",
    maxHeight: "400px",
    top: "64px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    listStyle: "none",
    transition: "all 0.3s ease-out",
    fontSize: "16px",
    zIndex: 100,
    padding: "5px",
    cursor: "pointer",
    width: "98%",
  },
  "& .dropdown-list-data-language li:last-of-type a": {
    border: "none",
  },
  '& .dropdown-list-language': {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: "41px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "10px"
  },
  "& .MuiCheckbox-colorSecondary.Mui-checked": {
    color: "#FF6600"
  },
  "& .MuiCheckbox-root": {
    color: "#CBD5E1"
  },
  "& .check-btn": {
    width: "20px",
    height: "20px",
    borderRadius: "6px",
  },
  "& .dropdown-item": {
    display: "flex",
    alignItems: "center",
  },
  "& .dropdown-item:hover": {
    backgroundColor: "#FFE0CC",
    borderRadius: "8px",
  },
}))
// Customizable Area End

import GeneralInfoController, {
  Props, configJSON,
} from "./GeneralInfoController";

export default class GeneralInfo extends GeneralInfoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getLoader = () => {
    return this.state.loader && <Box style={{
      display: "flex", justifyContent: "center",alignItems: 'center',
      maxHeight: '527px', minWidth:"600px"
    }}><CircularProgress /></Box>
  }
  // Customizable Area End

  render() {

    const comItems = [
      { label: "Pvt Ltd" },
      { label: "Co Ltd" },
    ];

    return (
      // Customizable Area Start      
      <InfoBlock>
        <Box>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography className="label-text">Company Name<span style={{ color: "red" }}>&nbsp;*</span></Typography>
              <input data-test-id="company-name" id="company" className="input-data" type="text" value={this.state.generalData.companyname} onChange={this.handleGeneralInfoChange} name="companyname"></input>
              <Typography className="error-msg">{this.state.fieldRequiredData.companyname}</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box style={{ position: "relative" }}>
                <Typography className="label-text">Company Type<span style={{ color: "red" }}>&nbsp;*</span></Typography>
                <Box data-test-id="company-btn" className='dropdown-list-language' onClick={this.handleDropdownCom}>
                  <Typography style={{ marginLeft: "15px" }}>{this.state.selectedComOption}</Typography>
                  <img style={{ marginRight: "15px" }} src={imgDown} alt="down_arrow" />
                </Box>
                <Typography className="error-msg">{this.state.fieldRequiredData.type}</Typography>
                <Hidden >
                  {this.state.showDropDownCom && (
                    <ul className="dropdown-list-data-language">
                      {comItems.map((item: { label: string }) => (
                        <li
                          key={item.label}
                          id="dropdowndata"
                          className="dropdown-item"
                          data-test-id="drop-company"
                          onClick={() => this.handleComChange(item.label)}
                        >
                          <div>{item.label}</div>
                        </li>
                      ))}
                    </ul>
                  )}
                </Hidden>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box>
                <Typography className="label-text">Contact Person<span style={{ color: "red" }}>&nbsp;*</span></Typography>
                <input className="input-data" id="contact" type="text" value={this.state.generalData.contact} onChange={this.handleGeneralInfoChange} name="contact"></input>
                <Typography className="error-msg">{this.state.fieldRequiredData.contact}</Typography>

              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box>
                <Typography className="label-text">Email<span style={{ color: "red" }}>&nbsp;*</span></Typography>
                <input data-test-id="email-data" className="input-data" type="text" value={this.state.generalData.email} onChange={this.handleGeneralInfoChange} name="email"></input>
                <Typography className="error-msg">{this.state.fieldRequiredData.email}</Typography>
                <Typography className="error-msg">{this.state.validMsg}</Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography className="label-text">Mobile Number<span style={{ color: "red" }}>&nbsp;*</span></Typography>
              <Box style={{ position: "relative", display: "flex" }}>
                <Box className="country-code">
                  <img className='flag-icon' src={flag} alt="location" />
                  <Typography>+91</Typography>
                </Box>
                <input
                  type="number"
                  data-test-id="phone-input-data"
                  className="phone-input"
                  value={this.state.generalData.phonenumber}
                  onChange={this.handleGeneralInfoChange}
                  name="phonenumber"
                >
                </input>
              </Box>
            </Grid>
          </Grid>
          <Box style={{ display: "flex", alignItems: "center", marginTop: "25px" }}>
            <Checkbox style={{ width: "20px", borderRadius: "6px" }}
              data-testId="check-btn"
              checked={this.state.isChecked}
              onChange={this.handleCheckboxChange}
              className="check-btn"
            />
            <Typography style={{ fontFamily: 'Inter, sans-serif' }}>
              I agree with <span data-testId="privacy" style={{ color: '#007AFF', cursor: 'pointer' }} onClick={() => this.handleClickOpenPrivacy(configJSON.privacy)}> {configJSON.privacy} </span>&amp;<span style={{ color: '#007AFF', cursor: 'pointer' }} data-testId="termsandcondition" onClick={() => this.handleClickOpenPrivacy(configJSON.termsCondition)}> {configJSON.termsCondition}</span>
            </Typography>
          </Box>
          <Dialog aria-labelledby="customized-dialog-title" open={this.state.openPrivacy} style={{
            paddingBottom: '20px', minWidth:"600px",
          }}>
            <DialogTitle id="customized-dialog-title"  >
              <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Box fontWeight={700}>{this.state.modalTitle}</Box>
                <Box data-testId="closePrivacy" onClick={this.handleClosePrivacy} style={{
                  cursor: 'pointer'
                }}><img src={close} /></Box> </Box>
            </DialogTitle>
            <DialogContent dividers style={{maxHeight: '527px'}}>
              {this.getLoader()}
              {!this.state.loader && this.state.termsContent.description && <div dangerouslySetInnerHTML={{ __html: `${this.state.termsContent.description}` }} style={{ fontFamily: 'Inter, sans-serif' }}></div>}
            </DialogContent>

          </Dialog>
        </Box>
      </InfoBlock>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End