import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
 
   quotationData: {
    type: string;
    quantity: string;
  }[];
  
  sidebar:boolean;
  open: { [key: string]: boolean };
  role:string
  toggle: boolean;
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  active:string;
  value: number,
  searchTerm: string;
  showAdditionalRow: boolean;
  anchorEL: HTMLElement | null;
  totalResults: number;
  resultperPage : number;
  currentPage: number; 
  expandedRows: { [key: number]: boolean }; 
  QuotationitemList: {
    id: number;
    type: string;
    attributes: {
        quotation_id: string;
        account_id : number;
        category_id: 	number ;       
       total_price: number;
       status: string;
        amount: number;
        Project: string;
        Company: string;
        validity: string;
        last_updated_date: string,
        last_updated_time: string
        brand: string;
        quotation_items : {
          id : number,
          quotation_id : number,
          product_id : number,
          product_name : string,
          brand_name : string
        }
    };
}[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class QuatationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllquotationItemList: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),      
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      type: "",
     
      sidebar:false,
      quotationData: [],
      totalCandidates: "",
      errorMsg: "",
      open: {},
      token: "",
      role:"user",
      toggle: false,
      loading: false,
      active:"",
      value: 0,
      searchTerm: "",
      showAdditionalRow: false,
      anchorEL: null,
      totalResults: 0,
      resultperPage: 12,
      currentPage: 1 ,
      QuotationitemList: [],
      expandedRows: {},
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    this.getToken();
    this.getAllQuatationItems();
    // Customizable Area End
  }

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
      if (responseJson?.error) {
        this.parseApiErrorResponse(responseJson.error);
        this.parseApiCatchErrorResponse(responseJson.error);
        return;
      }
  
      const responseHandlers = {
        [this.getAllquotationItemList]: () => {
          this.setState({ QuotationitemList: responseJson.data || [], 
            currentPage:responseJson.meta.pagination.current_page,
            totalResults:responseJson.meta.pagination.total_count,
          });
        },
      };
      if (responseHandlers[apiRequestCallId]) {
        responseHandlers[apiRequestCallId]();
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  componentDidUpdate(prevProps:Props, prevState:S) {
    // Check if the current page has changed
    if (this.state.currentPage !== prevState.currentPage) {
      this.getAllQuatationItems(); // Call when the page changes
    }
  }
  getAllQuatationItems = async() => { 
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData('authToken'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAllquotationItemList = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getQuotationItem}?page=${this.state.currentPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getToken = () => {
    const tokenMessage: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMessage);
  };


  handleNavigate = (route?:string,id?:number) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    id && message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);

    this.send(message);
  }

  handleTabChange = (event:React.ChangeEvent<{}>, newValue:number) => {
    this.setState({ value: newValue });
  };

  handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    this.setState({ anchorEL: event.currentTarget || null });
  };
  handleMenuClose = (): void => {
    this.setState({ anchorEL: null });
  };

  handleViewClick = (id: number) => {
    this.handleNavigate("QuotationComparision",id)
  };

  handleExpandClick= (id: number) => {
    this.setState(prevState => ({
      expandedRows: {
        ...prevState.expandedRows,
        [id]: !prevState.expandedRows[id], 
      },
    }));
  };
  
    handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
      this.setState({ currentPage: value });
    };
  
    getResultText = (page: number): string => {
      const start = (page - 1) * this.state.resultperPage + 1;
      const end = Math.min(start + this.state.resultperPage - 1, this.state.totalResults);
      return `${start} - ${end} of ${this.state.totalResults} results`;
    };

    badgecount = (count : number) => {
      return count > 100 ? '99+' : count.toString()   
    };
  // Customizable Area End
}
