import React from "react";


// Customizable Area Start
import { Grid, Box, Typography } from "@material-ui/core";

import LedgerDetailController, {
  Props,
} from "./LedgerDetailController";
import Navbar from "../../../components/src/Navbar.web";
  import SideBar from "../../../components/src/SideBar.web";
  import { styled } from "@material-ui/styles";
  import { logo } from "./assets";
  import { OrderBlock } from "../../dashboard/src/OrderDetail.web";

  const LedgerDetailBlock = styled("div")(({ theme }) => ({
    "& .factoryAddress__ledger":{
        display:"flex",
        alignItems:"center"
    },
    "& .leftAlignText":{
        paddingLeft:"0px", 
        textAlign:"left"
    },
    "& .ledgerTable__font":{
        fontFamily:"Inter, sans-serif",
        fontSize:"14px",
        lineHeight:"22px",
        padding:"30px auto 30px 0px",
        borderBottom:"1px solid #E2E8F0",
        color:"#323842",
    },
    "& .ledgerTable__index":{
        fontWeight:700,
        textAlign:"center", 
        padding:"30px 10px 30px 20px",      
    },
    "& .ledgerTable__id":{
        fontWeight:700
    },
    "& .ledgerTable__info":{
        fontWeight:400
    },
    "& .totalMount":{
        padding:"12px 16px",
        border:"1px solid transparent",
        borderRadius:"18px",
        minWidth:"244px",
        paddingBottom:"5px",
        margin:"7px",
        "@media (min-width:1330px) and (max-width:1510px)": {
            minWidth: "200px",
        },
    },
    "& .totalMount__name":{
        fontFamily: "Inter, sans-serif",
        fontSize: "16px",
        lineHeight:"26px",
        fontWeight: 400,
        color: "#323743",
        paddingBottom:"37px",
    },
    "& .totalMount__number":{
        fontFamily: "Inter, sans-serif",
        fontSize: "26px",
        lineHeight:"48px",
        fontWeight: 400,
        color: "#323743",
    },
    "& .totalMount__prevBalance":{
        backgroundColor:"#FFE2CE"
    },
    "& .totalMount__debit":{
        backgroundColor:"#F5C4C6"
    },
    "& .totalMount__credit":{
        backgroundColor:"#FFF6F0"
    },
    "& .totalMount__closeBalance":{
        backgroundColor:"#F5C4C6"
    },
    "& .balance":{
       marginTop:"7px" 
    }
  }))

export default class LedgerDetail extends LedgerDetailController {
  constructor(props: Props) {
    super(props);

  }
  filledArray:any[] = [
    {
        id:"Revised-240218-0010-1",
        date:"25-07-2024",
        mode:"NA",
        debit:"3,86,400.00",
        credit:"",
        closingBalance:"22,82,786.47",
    },
    {
        id:"INV-240218-0010-2",
        date:"25-07-2024",
        mode:"NA",
        debit:"3,86,400.00",
        credit:"",
        closingBalance:"22,82,786.47",
    },
    {
        id:"SRTN-4, Sales Return",
        date:"25-07-2024",
        mode:"UPI",
        debit:"",
        credit:"4,500.00",
        closingBalance:"22,82,786.47",
    },
    {
        id:"PAYIN-2, Payment In",
        date:"25-07-2024",
        mode:"Net Banking",
        debit:"",
        credit:"4,500.00",
        closingBalance:"22,82,786.47",
    },
    {
        id:"Balance as of 01-01-2024",
        date:"",
        mode:"",
        debit:"",
        credit:"",
        closingBalance:"0.00",
    },
  ]
  dummyProductLedgerList = this.filledArray.concat(this.filledArray);
  
  render() {


    return(
        <LedgerDetailBlock>
        <OrderBlock>
        <Navbar history={this.props.history} mycart={true}/>
        <SideBar navigation={this.props.navigation} mycart={true}>
        <Grid container direction="column" className="orderDetailMain">
                <Grid item>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                            <Typography className="orderIdText">
                                KrateX Ledger
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className="main" style={{paddingBottom:"0px"}}>
                    <Grid container className="factoryAddress" direction="column">
                        <Grid item>
                            <Grid container className="factoryAddress__ledger">
                                <Grid item direction="column" xs={4} sm={4} md={2} lg={2} xl={1} alignItems="center">
                                    <img src={logo} />
                                </Grid>
                                <Grid item direction="column" xs={8} sm={8} md={6} lg={6} xl={7}>
                                    <Typography className="factoryName">KRATEX TRADETECH INDIA PRIVATE LIMITED</Typography>
                                    <Typography className="factoryGstin"><span style={{fontWeight:700}}>GSTIN: </span>27AAKCK5699N1ZG</Typography>
                                    <Typography className="factoryPan"><span style={{fontWeight:700}}>PAN: </span>AAKCK5699N</Typography>
                                </Grid>
                                <Grid item direction="column" xs={6} sm={6} md={2} lg={2} xl={2}>
                                    <Typography className="headerInfo">Address:</Typography>
                                    <Typography className="factoryAddress__factoryInfo">C46, Abhimanshree Society,</Typography>
                                    <Typography className="factoryAddress__factoryInfo">Pune, Maharashtra</Typography>
                                    <Typography className="factoryAddress__factoryInfo">411007</Typography>
                                </Grid>
                                <Grid item direction="column" xs={6} sm={6} md={2} lg={2} xl={2}>
                                    <Typography className="headerInfo">Contact Details:</Typography>
                                    <Typography className="factoryAddress__factoryInfo">Mobile:<span style={{fontWeight:500}}>+91-9876543210</span></Typography>
                                    <Typography className="factoryAddress__factoryInfo">Email: <span style={{fontWeight:500}}>info@kratex.com</span></Typography>
                                    <Typography className="factoryAddress__factoryInfo">Website: <span style={{fontWeight:500}}>kratex.co</span></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" className="customerAddress">
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <Grid container direction="column">
                                <Grid item>
                                    <Typography className="headerInfo">Customer Details: </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="factoryAddress__factoryInfo">Sameer Shaikh</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="factoryAddress__factoryInfo">GSTIN: <span style={{fontWeight:400}}>27AAKCK5699N1ZG</span></Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="factoryAddress__factoryInfo">Phone: <span style={{fontWeight:400}}>9881413576</span></Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="factoryAddress__factoryInfo">Email: <span style={{color:"#565E6C"}}>purchase@mihirgroup.com</span></Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="factoryAddress__factoryInfo">Date: <span style={{color:"#565E6C"}}>01/01/2021-31/12/2024</span></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <Grid container direction="column">
                                <Grid item>
                                    <Typography className="headerInfo">Vendor Details:  </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="factoryAddress__factoryInfo">Havells</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="factoryAddress__factoryInfo">GSTIN: <span style={{fontWeight:400}}>27AAKCK5699N1ZG</span></Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="factoryAddress__factoryInfo">Phone: <span style={{color:"#565E6C"}}>9881413576</span></Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="factoryAddress__factoryInfo">Email: <span style={{color:"#565E6C"}}>support@havells.com</span></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid> 
                    <Grid container direction="row" justifyContent="space-between" className="balance">
                        <Grid item className="totalMount totalMount__prevBalance" data-test-id="total=amount" xs={10} sm={5} md={2} lg={2} xl={2}>
                            <Typography className="totalMount__name">Previous Balance</Typography>
                            <Typography className="totalMount__number">₹0.00</Typography>
                        </Grid>
                        <Grid item className="totalMount totalMount__debit" data-test-id="total=amount" xs={10} sm={5} md={2} lg={2} xl={2}>
                            <Typography className="totalMount__name">Total Debit(-)</Typography>
                            <Typography className="totalMount__number">₹2584949.07</Typography>
                        </Grid>
                        <Grid item className="totalMount totalMount__credit" data-test-id="total=amount" xs={10} sm={5} md={2} lg={2} xl={2}>
                            <Typography className="totalMount__name">Total Credit(+)</Typography>
                            <Typography className="totalMount__number">₹320162.60</Typography>
                        </Grid>
                        <Grid item className="totalMount totalMount__closeBalance" data-test-id="total=amount" xs={10} sm={5} md={2} lg={2} xl={2}>
                            <Typography className="totalMount__name">Closing Balance</Typography>
                            <Typography className="totalMount__number">₹2282786.47</Typography>
                        </Grid>
                    </Grid>
                    <Grid container className="orderTableGrid">
                    <table cellPadding={0} cellSpacing={0} className="orderTable" style={{marginBottom:"0px"}}>
                        <tr className="orderHeaderRow">
                            <th className="th" style={{paddingRight:"10px"}}>#</th>
                            <th className="th leftAlignText">Id</th>
                            <th className="th leftAlignText">Date</th>
                            <th className="th leftAlignText">Mode</th>
                            <th className="th leftAlignText">Debit(-)</th>
                            <th className="th leftAlignText">Credit(+)</th>
                            <th className="th leftAlignText">Closing Balance</th>
                        </tr>
                        {this.dummyProductLedgerList.map((elem, index)=>{
                            return(
                                <tr className="ProductListRow">
                                    <td className="ledgerTable__index ledgerTable__font">{index+1}</td>
                                    <td className="ledgerTable__font leftAlignText ledgerTable__id">
                                      {elem.id}
                                    </td>
                                    <td className="ledgerTable__font leftAlignText ledgerTable__info">{elem.date}</td>
                                    <td className="ledgerTable__font leftAlignText ledgerTable__info">
                                        {elem.mode}
                                    </td>
                                    <td className="ledgerTable__font leftAlignText ledgerTable__info">{elem.debit}</td>
                                    <td className="ledgerTable__font leftAlignText ledgerTable__info">{elem.credit}</td>
                                    <td className="ledgerTable__font leftAlignText ledgerTable__info">
                                        {elem.closingBalance}
                                    </td>
                                </tr>
                            )
                        })}
                    </table>
                    </Grid>
                </Grid>
        </Grid>  
        </SideBar>
        </OrderBlock>
      </LedgerDetailBlock>
    )
  }
}


// Customizable Area End
