import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history:any;
  // Customizable Area End
}


interface S {
  // Customizable Area Start
  value:number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PaymentAcknowledgementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.state = {
      value:0,
      // Customizable Area Start
      
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {

  }
  handleTabChange = (event:React.ChangeEvent<{}>, newValue:number) => {
    this.setState({ value: newValue });
  };
 
  // Customizable Area End
}
