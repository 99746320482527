export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const viewIcon = require("../assets/view_ic.png");
export const DeleteIcon = require("../assets/DeleteIcon.png");
export const imgDone = require("../assets/image_done_24px.jpg");
export const img1 = require("../assets/image1.png");
export const img2 = require("../assets/image2.png");
export const img3 = require("../assets/image3.png");
export const img4 = require("../assets/image4.png");
export const img5 = require("../assets/image5.png");    
export const view = require("../assets/view.png");
export const searchicon = require("../assets/search.svg");
export const sortingicon = require("../assets/sorting.svg");
export const filtericon = require("../assets/filter.svg");
export const acknowledgement = require("../assets/ackowledgement.svg");
export const logo = require("../assets/kx_logo.jpg");
export const paid_status = require("../assets/paid_status.png")
export const listIsEmpty = require("../assets/listIsEmptyIco.png");
