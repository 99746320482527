import React from "react";

import { Typography, Grid, Box} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';


import PriceHistoryController, { Props } from "./PriceHistoryController";
import Navbar from "../../../components/src/Navbar.web";
import Footer from "../../../components/src/Footer.web";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

       
       
export const PriceHistoryBlock = styled("div")(({ theme }) => ({
  fontFamily: 'Inter, sans-serif',
  cursor:"default",
  "& .MuiTypography-body1":{
    fontFamily: 'Inter, sans-serif',
  },
  "& .main_label":{
    fontSize: "20px",
    fontWeight: 500,
    color:"#171A1F",
    marginLeft:"48px",
    marginTop:"19px"
  },
  "& .price__history__text":{
    display:'flex',
    fontSize:"14px",
    fontWeight:500,
    color:"#9095A1",
    marginLeft:"48px",
    marginTop:"12px"
  },
  "& .price__history":{
    margin:"-17px 0px 64px 0px",
  },
  "& .price__history__chart":{
    marginTop:"64px",
    padding:"24px",
    border:"1px solid #F4F4F4",
    borderRadius:"18px",
    "@media (max-width:900px)": {
      padding:"0px",
    },
  },
  "& .price__history__cards":{
    "@media (max-width:900px)": {
      paddingLeft:"25px",
      marginTop:"20px"
    },
  },
  "& .price__history__chart_cont":{
    "@media (max-width:900px)": {
      marginLeft:"35px"
    },
  },
  "& .price__history__cards__label":{
    fontSize: "20px",
    fontWeight: 500,
    color:"#323743",
  },
  "& .price__history__cards__card":{
    padding:"15px 24px",
    border:"1px solid #F7F7F7",
    borderRadius:"18px",
    marginTop:"31px",
    cursor:"pointer"
  },
  "& .price__history__cards__card__label":{
    fontSize: "16px",
    fontWeight: 500,
    color:"#171A1F",
  },
  "& .price__history__cards__card__location":{
    display:'flex',
    fontSize:"14px",
    fontWeight:500,
    color:"#9095A1",
    marginTop:"3px"
  },
  "& .price__history__cards__card__price":{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"flex-end",
    width:"100%",
    marginTop:"45px"
  },
  "& .price__history__cards__card__price_indian":{
    whiteSpace:"nowrap",
    marginBottom:"-2px",
    "@media (max-width:1400px)": {
      fontSize:"14px"
    },
    "@media (max-width:900px)": {
      fontSize:"16px"
    },
  },
  "& .MuiGrid-grid-md-3": {
    "@media (min-width:960px) and (max-width:1100px)": {
      maxWidth: "30%",
    },
  },
  "& .price__history__cards__card__price__changed__percent":{
    color:"#FFF",
    backgroundColor:"#DE3B40",
    padding:"7px 12px",
    borderRadius:"12px",
    fontSize:"12px",
    fontWeight:500,
    textAlign:"center",
    display:"inline",
    whiteSpace:"nowrap"
  },
  "& .price__history__cards__card__price__changed__time":{
    fontSize:"12px",
    fontWeight:400,
    color:"#9095A1",
    marginTop:"10px",
    whiteSpace:"nowrap"
  },
  "& .price__history__cards__card__price__changed":{
    display:"flex",
    flexDirection:"column",
    alignItems:"flex-end"
  },
  "& .middle":{
    backgroundColor:"#FFA76C"
  },
  "& .price__history__cards__all__label":{
    fontSize:"16px",
    fontWeight:500,
    color:"#FF6600",
    cursor:"pointer"
  },
  "& .price__history__cards__all__label__container":{
    display:"flex",
    justifyContent:"flex-end",
    marginTop:"20px"
  },
  "& .price__history__chart__info":{
    display:"flex",
    width:"100%",
    justifyContent:"space-between"
  },
  "& .price__history__chart__time":{
    display:"flex",
    marginTop:"19px",
    fontSize:"12px",
    fontWeight:400,
  },
  "& .price__history__chart__info__name__main":{
    fontSize:"20px",
    fontWeight:700,
    color:"#323743",
  },
  "& .price__history__chart__info__name__sec":{
    fontSize:"14px",
    fontWeight:400,
    color:"#9095A1",
    marginTop:"8px"
  },
  "& .price__history__chart__info__price_percent":{
    display:"flex",
    backgroundColor:"#DE3B40",
    padding:"7px 12px",
    borderRadius:"8px",
    color:"#FFF",
    fontSize:"14px",
    fontWeight:400,
    alignItems:"center",
    justifyContent:"center"
  },
  "& .price__history__chart__info__price_percent__ico":{
    margin:"0px 3px",
  },
  "& .price__history__chart__info__price__updated":{
    fontSize:"14px",
    fontWeight:400,
    color:"#9095A1",
    marginTop:"10px",
    paddingLeft:"10px"
  },
  "& .price__history__chart__info__price":{
    display:"flex",
    flexDirection:"column",
    justifyContent:"flex-end",
    alignItems:"flex-end"
  },
  "& .price__history__chart__time__active":{
    color:"#FFF",
    backgroundColor:"#FF6600",
    borderRadius:"9px",
    padding:"6px 9px",
    cursor:"pointer"
  },
  "& .price__history__chart__time__passive":{
    color:"#FF6600",
    backgroundColor:"#FFF",
    padding:"6px 9px",   
    cursor:"pointer"
  },
  "& .time__separator":{
    height:"16px",
    border:"1px solid #FFE4D2",
    marginTop:"10px"
  },
  "& .legend":{
    width:"100%",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  },
  "& .legend__text":{
    fontSize:"14px",
    fontWeight:400,
    color:"#171A1F",
  },
  "& .legend__color":{
    height:"12px",
    width:"12px",
    borderRadius:"100%",
    backgroundColor:"#FF6600",
    marginRight:"5px"
  },
  " & .navMenu":{
    marginLeft:"48px",
    marginTop:"22px",
    fontFamily: 'Inter, sans-serif',
    fontSize: "14px",
    fontWeight: 400,
      "@media (max-width:900px)": {
        width:"80vw"
      },
  },
}));


export default class PriceHistory extends PriceHistoryController {
  constructor(props: Props) {
    super(props);    
  }
  priceCards=[0, 1, 2];
  render() {
    return (
      <div>
        <Navbar history={this.props.history} handleNavigate={() => this.handleNavigateBar()} />
        <PriceHistoryBlock>
            <Grid container direction="column" style={{width:"90vw"}}>
            <Grid item>
                    <Typography>
                        <Grid container direction="row" alignItems="center" className="navMenu" onClick={()=>this.props.history.push('/Price')}>
                            <Grid item style={webStyle.navMenuItem} id="navigation">
                                <span>Prices</span>           
                            </Grid>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={webStyle.arroForw}>
                            <path d="M4.32812 13.4199L5.50813 14.5999L12.1081 7.9999L5.50813 1.3999L4.32812 2.5799L9.74813 7.9999L4.32812 13.4199Z" fill="#424856"/>
                            </svg>
                            <Grid item style={webStyle.navMenuItem} id="navigation">
                                <span>{this.state.matName}</span>           
                            </Grid>  
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={webStyle.arroForw}>
                            <path d="M4.32812 13.4199L5.50813 14.5999L12.1081 7.9999L5.50813 1.3999L4.32812 2.5799L9.74813 7.9999L4.32812 13.4199Z" fill="#424856"/>
                            </svg>     
                            <Grid item style={webStyle.navMenuItem} id="navigation">
                                <span>{this.state.subMatName}</span>           
                            </Grid>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={webStyle.arroForw}>
                            <path d="M4.32812 13.4199L5.50813 14.5999L12.1081 7.9999L5.50813 1.3999L4.32812 2.5799L9.74813 7.9999L4.32812 13.4199Z" fill="#424856"/>
                            </svg>    
                            <Grid item style={webStyle.navMenuItem} id="navigation">
                                <span>{this.state.name} </span>           
                            </Grid>          
                        </Grid>
                    </Typography>
                </Grid>
                <Grid item>
                  <Typography className="main_label">
                    Price History for {this.state.name}
                  </Typography>
                  <Typography className="price__history__text">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.0033 1.71484C8.23192 1.71484 4.28906 4.62913 4.28906 9.42913C4.28906 13.972 11.1462 20.9148 11.4033 21.172C11.5748 21.3434 11.7462 21.4291 12.0033 21.4291C12.2605 21.4291 12.4319 21.3434 12.6033 21.172C12.8605 20.9148 19.7176 13.972 19.7176 9.42913C19.7176 4.62913 15.7748 1.71484 12.0033 1.71484ZM12.0033 12.0006C10.5462 12.0006 9.43192 10.8863 9.43192 9.42913C9.43192 7.97199 10.5462 6.8577 12.0033 6.8577C13.4605 6.8577 14.5748 7.97199 14.5748 9.42913C14.5748 10.8863 13.4605 12.0006 12.0033 12.0006Z" fill="#323743"/>
                      </svg>
                      {this.state.location}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container className="price__history" justifyContent="space-around">
                    <Grid item xs={12} sm={10} md={8} lg={8} className="price__history__chart_cont">
                      <div className="price__history__chart">
                        <Grid container direction="column">
                          <Grid item direction="row" className="price__history__chart__info">
                            <div>
                              <Typography className="price__history__chart__info__name__main">
                                Price History
                              </Typography>
                              <Typography className="price__history__chart__info__name__sec">
                                {this.state.name} - {this.state.location}
                              </Typography>
                            </div>
                            <div className="price__history__chart__info__price">
                              {this.renderMainPercentage(this.state.priceChange)}
                              <Typography className="price__history__chart__info__price__updated">
                                Last Updated - {this.state.updated}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item direction="row" className="price__history__chart__time">
                            <Typography className={this.checkRange('1W')} data-test-id='timing' onClick={()=>this.changeRange('1W')}>
                              1 W
                            </Typography>
                            <Box className="time__separator"/>
                            <Typography className={this.checkRange('1M')} data-test-id='timing' onClick={()=>this.changeRange('1M')}>
                              1 M
                            </Typography>
                            <Box className="time__separator"/>
                            <Typography className={this.checkRange('3M')} data-test-id='timing' onClick={()=>this.changeRange('3M')}>
                              3 M
                            </Typography>
                            <Box className="time__separator"/>
                            <Typography className={this.checkRange('6M')} data-test-id='timing' onClick={()=>this.changeRange('6M')}>
                              6 M
                            </Typography>
                            <Box className="time__separator"/>
                            <Typography className={this.checkRange('1Y')} data-test-id='timing'onClick={()=>this.changeRange('1Y')}>
                              1 Y
                            </Typography>
                            <Box className="time__separator"/>
                            <Typography className={this.checkRange('5Y')} data-test-id='timing' onClick={()=>this.changeRange('5Y')}>
                              5 Y
                            </Typography>
                          </Grid>
                        </Grid>
                        {this.state.data&&this.state.options? //chart error handler
                          <Line data={this.state.data} options={this.state.options} data-test-id='chart'/>                      
                        :""
                        }
                        <div className="legend">
                          <div className="legend__color">                            
                          </div>
                          <Typography className="legend__text">
                          {this.state.name}
                          </Typography>
                        </div>
                      </div>                     
                    </Grid>
                    <Grid item xs={12} sm={10} md={3} lg={3} xl={2} className="price__history__cards">
                      <Typography className="price__history__cards__label">
                        {this.state.subMatName} Prices
                      </Typography>
                      {(this.state.showAllPrices ? this.state.allSubMat : this.state.allSubMat.slice(0, 3)).map((elem, i)=>{
                        return(
                        <Grid container className="price__history__cards__card" data-test-id='priceCard' onClick={()=>this.redirectAnotherHistory(elem?.id)}>
                        <Grid item>
                          <Typography className="price__history__cards__card__label">
                            {elem.attributes.name}
                          </Typography>
                          <Typography className="price__history__cards__card__location">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.0033 1.71484C8.23192 1.71484 4.28906 4.62913 4.28906 9.42913C4.28906 13.972 11.1462 20.9148 11.4033 21.172C11.5748 21.3434 11.7462 21.4291 12.0033 21.4291C12.2605 21.4291 12.4319 21.3434 12.6033 21.172C12.8605 20.9148 19.7176 13.972 19.7176 9.42913C19.7176 4.62913 15.7748 1.71484 12.0033 1.71484ZM12.0033 12.0006C10.5462 12.0006 9.43192 10.8863 9.43192 9.42913C9.43192 7.97199 10.5462 6.8577 12.0033 6.8577C13.4605 6.8577 14.5748 7.97199 14.5748 9.42913C14.5748 10.8863 13.4605 12.0006 12.0033 12.0006Z" fill="#323743"/>
                          </svg> &nbsp;{elem.attributes.location}
                          </Typography>
                        </Grid>
                        <Grid item className="price__history__cards__card__price">
                          <Typography className="price__history__cards__card__price_indian">
                            ₹ {elem.attributes.price}/MT
                          </Typography>
                          <div className="price__history__cards__card__price__changed">
                              {this.renderSecondPercentage(elem.attributes.price_change_percentage)}
                            <Typography className="price__history__cards__card__price__changed__time">
                              {elem.attributes.updated}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>)
                      })}
                      <div className="price__history__cards__all__label__container">
                        <Typography className="price__history__cards__all__label" onClick={()=>this.toggleNumberCards()}>
                        {this.toggleText()} All {this.state.subMatName} Prices
                        </Typography>
                      </div>                      
                    </Grid>
                  </Grid>
                </Grid>
            </Grid>
        </PriceHistoryBlock>
        
        <Footer />
      </div>
    );
  }
}

const webStyle = {
  navMenu:{
      marginLeft:"48px",
      marginTop:"22px",
      fontFamily: 'Inter, sans-serif',
      fontSize: "14px",
      fontWeight: 400,
        "@media (max-width:900px)": {
          width:"100px"
        },
    },
    navMenuItem:{
      height:"19px",
      alignItems:"center",
      color:"#FF6600",
      cursor:"pointer"
    },
    arroForw:{
      margin:"0px 10px",
      height:"16px"
    }
};
