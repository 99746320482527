import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history:any
  // Customizable Area End
}

type ProductFeatureData = {
  id: string;
  type: string;
  attributes: {
      feature_name: string;
      feature_options: {
          id: number;
          option_value: string;
          product_feature_id: number;
      }[];
  };
};
interface ProductFeature {
  data: ProductFeatureData[];
}
interface Image {
  file_name: string;
  content_type: string;
  id: number;
  url: string;
}
interface Price {
  id: number;
  action: string;
  quantity: number;
  amount: number;
  product_id: number | null;
  created_at: string;
  updated_at: string;
  seller_product_id: number;
}
interface ProductAttributes {
  id: number;
  name: string;
  brand_name: string;
  price: number | null;
  prices:Price[];
  change: string;
  change_up: boolean;
  description: string;
  manufacturer: string;
  specification: string;
  applications: string;
  certifications: string;
  is_recommended: boolean;
  status: string;
  product_id: string;
  images?: Image[];
  category: string;
  sub_category: string;
  brand:string;
  updated: string;
  is_new: string;
  is_best_seller: boolean;
  product_feature: ProductFeature;
}
interface ProductData {
  id: string;
  type: string;
  attributes: ProductAttributes;
}
interface SellerProductAttributes {
  price: string | number;
  account_id: number;
  product_id: number;
  created_at: string;
  updated_at: string;
  prices: Price[];
  product: {
    data: ProductData;
  };
}
export interface SellerProduct {
  id: number;
  type: string;
  attributes: SellerProductAttributes;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  sidebar:boolean;
  open: { [key: string]: boolean };
  role:string
  toggle: boolean;
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  active:string;
  value: number,
  searchTerm: string;
  showAdditionalRow: boolean;
  notificationCounts: number[],
  anchorEL: HTMLElement | null;
  isSidebarCollapsed:boolean;

  
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PaymentSettlementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postCreateProApiCallId: string = "";
  getSearchListApiCallId: string = "";
  getDeleteApiCallId: string = "";
  getDeletePriceApiCallId: string = "";
  updatePriceApiCallId: string = "";
  productPriceApiCallId: string = "";
  viewProductApiCallId: string = "";
  postProUpdateApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      type: "",
      sidebar:false,
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      open: {},
      token: "",
      role:"user",
      toggle: false,
      loading: false,
      active:"",
      value: 0,
      searchTerm: "",
      showAdditionalRow: false,
      notificationCounts: [2, 5, 0, 1, 3, 0],
      anchorEL: null,
      isSidebarCollapsed:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {

  }
  handleTabChange = (event:React.ChangeEvent<{}>, newValue:number) => {
    this.setState({ value: newValue });
  };

  handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    this.setState({ anchorEL: event.currentTarget || null });
  };
  handleMenuClose = (): void => {
    this.setState({ anchorEL: null });
  };

  handleViewClick = () => {
    this.setState((prevState) => ({
      showAdditionalRow: !prevState.showAdditionalRow,
    }));
  };
  toggleSlider = () => {
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }));    
  };
  getNavigationMessage = (path:string|undefined) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
     message.addData(getName(MessageEnum.NavigationTargetMessage), path);
     message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
     this.send(message);
   }
 
  renderStatus = (label:string) => {
    let className:string = 'confirmedStatusRow';

    if(label == "Pending"){
      className = 'pendingStatusRow';
    } else if(label == "Rejected"){
      className = 'rejectedStatusRow';
    }
return(
   <button className={className}>{label}</button>
  )
  }
  // Customizable Area End
}
