import React from "react";
// Customizable Area Start
import { Box, TableContainer, Typography, Table, TableHead, TableBody, TableRow, TableCell, Radio, FormControlLabel, Checkbox } from "@material-ui/core";

import QuotationComparisionController, { Props } from "./QuotationComparisionController";
import Navbar from "../../../components/src/Navbar.web";
import SideBar from "../../../components/src/SideBar.web";
import { acceptIcon, negotiateIcon, rejectIcon } from "./assets";
import CheckIcon from '@material-ui/icons/Check'; 

// Customizable Area End
export default class QuotationComparision extends QuotationComparisionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  Comparisiontable = () => {
    const labelData = [
      { text: "KEI Cable" },
      { text: "RR Cable" },
      { text: "POLYCAB" },
      { text: "Bosch" },
    ];

    const rowData = [
      { name: "Coated Glass", qty: "08", rate: [8, 8, 8, 8], amount: [450000, 450000, 450000, 450000] },
      { name: "Tinted Glass", qty: "08", rate: [8, 8, 8, 8], amount: [450000, 450000, 450000, 450000] },
      { name: "PVC Wood", qty: "08", rate: [8, 8, 8, 8], amount: [450000, 450000, 450000, 450000] },
      { name: "Drill Machine", qty: "08", rate: [8, 8, 8, 8], amount: [450000, 450000, 450000, 450000] },
      { name: "Router", qty: "08", rate: [8, 8, 8, 8], amount: [450000, 450000, 450000, 450000] },
    ];

    const footerData = [
      { label: "Subtotal", value: ["4500000.00", "3600.00", "4100.00", "4100.00"] },
      { label: "Taxes", value: ["214562.00", "214562.00", "214562.00", "214562.00"] },
      { label: "Transport", value: ["00.00", "00.00", "00.00", "00.00"] },
      { label: "Loading/Unloading", value: ["00.00", "00.00", "00.00", "00.00"] },
      { label: "Subtotal", value: ["45214562.00", "45214562.00", "45214562.00", "45214562.00"] },
      { label: "Payment Terms", value: ["50% advance required", "50% advance required", "50% advance required", "50% advance required"] },
      { label: "Validity of Quotation", value: ["04/11/2023", "04/11/2023", "04/11/2023", "04/11/2023"] },
      { label: "Lavel 1", value: ["", "", "L1", ""] },
    ];

    return (
      <Box style={{ ...styles.Maincontainer, width: "94%" }}>
        <Box style={styles.header}>
          <Typography variant="h6" component="div" style={styles.heading}>
            QID52421458
          </Typography>
        </Box>
      <Box style={{width : "1500px"}}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', border: '1px solid #E7E8EF' }}>
          <div style={{ display: 'flex', backgroundColor: '#F3F4F6' }}>
            <div style={{ ...styles.Brandname, fontFamily: "Inter, sans-serif", padding: '8px 0px 8px 17px' }}>
              <span style={{ margin:"auto 0px", fontFamily: "Inter, sans-serif" }}>Brand Name</span>
            </div>
            {labelData.map((item, index) => (
              <div key={index} style={{ flex: 1, textAlign: "center", padding: '8px 0px 8px 17px',    borderRight: "1px solid #E7E8EF"}}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <span style={{ ...styles.Kefname, fontFamily: "Inter, sans-serif" }}>{item.text}</span>
                  <Radio data-testId="Radiobutton" />
                </div>
              </div>
            ))}
          </div>
        </div>

        <TableContainer style={{ overflowX: 'initial' }}>
          <Table style={{ tableLayout: 'fixed', width: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell style={{ ...styles.maintcell, width: "150px", borderTop: "none", fontFamily: "Inter, sans-serif" }}>Product Name</TableCell>
                <TableCell style={{ ...styles.maintcell, width: "70px", borderTop: "none", fontFamily: "Inter, sans-serif" }}>Qty</TableCell>
                {labelData.flatMap((_, index) => [
                  <TableCell key={`rate-${index}`} style={{ ...styles.maintcell, borderTop: "none", fontFamily: "Inter, sans-serif" }}>Rate</TableCell>,
                  <TableCell key={`amount-${index}`} style={{ ...styles.maintcell, borderTop: "none", fontFamily: "Inter, sans-serif" }}>Amount</TableCell>
                ])}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell style={{ ...styles.CellHeight, display: 'flex', alignItems: 'center', justifyContent: 'left' , height:'60px'}}>

                  <label className="custom-checkbox" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
        <input
          type="checkbox"
          data-testId="CheckBox"
          checked={this.state.isChecked}
          onChange={this.handleCheckboxChange}
          style={{
            width: '20px',                
            height: '20px',              
            marginRight: '8px',        
            cursor: 'pointer',          
            appearance: 'none',          
            backgroundColor: this.state.isChecked ? '#FF8A3C' : '#F3F4F6', 
            border:'1px solid #E1E7EF',
            borderRadius: '4px',          
            position: 'relative',        
            transition: 'background-color 0.2s ease', 
          }}
        />
        <span style={styles.label}>{item.name}</span>
        
      
        {this.state.isChecked && (
            <CheckIcon
              style={{
                position: 'absolute',       
                top: '4px',                
                left: '5px',                
                fontSize: '16px',           
                color: '#fff',            
              }}
            />
          )}
      </label>
                  </TableCell>
                  <TableCell align='center' style={{ ...styles.CellHeight, fontFamily: "Inter, sans-serif",height:'60px' }}>{item.qty}</TableCell>
                  {item.rate.map((rate, rateIndex) => (
                    <React.Fragment key={rateIndex}>
                      <TableCell style={{ ...styles.CellHeight1, fontFamily: "Inter, sans-serif" ,height:'60px'}}>{rate}</TableCell>
                      <TableCell style={{ ...styles.CellHeight, fontFamily: "Inter, sans-serif",height:'60px' }}>{item.amount[rateIndex]}</TableCell>
                    </React.Fragment>
                  ))}
                </TableRow>
              ))}
              {footerData.map((footerItem, footerIndex) => {
               
                return(
                <TableRow key={footerIndex}>
                  <TableCell colSpan={2} style={{ ...styles.colspanhead, fontFamily: "Inter, sans-serif",height:'60px' }}>
                    {footerItem.label}
                  </TableCell>
                  {footerItem.value.map((val, index) => {
                    const isLightFont = footerItem.label === "Total Cost" || footerItem.label === "Subtotal" || footerItem.label === "Payment Terms" || footerItem.label === 'Validity of Quotation'
                    const isCenter =  footerItem.label === "Payment Terms" || footerItem.label === 'Validity of Quotation' || footerItem.label === "Lavel 1" 
                    const greenBack = footerItem.label === "Lavel 1" && val
                    return(
                    <TableCell
                      key={index}
                      colSpan={2}
                      align={isCenter ? "center" : "right"}
                      style={{
                        ...styles.firstother,
                        height:'60px',
                        fontWeight: isLightFont ? 700 : 400, 
                        fontFamily: "Inter, sans-serif",
                        backgroundColor: greenBack ? '#D1FAE5' :"#F3F4F6"
                      }}>
                      {val}
                    </TableCell>
                  )})}
                </TableRow>
              )})}

              
            </TableBody>
          </Table>
        </TableContainer>
        <Box>
            <Box style={{ ...styles.note, fontFamily: "Inter, sans-serif", padding:'10px 10px 10px 10px' }}>Note:</Box>
        </Box>
      </Box>
        <div style={{ display: "flex", marginTop: "40px", justifyContent: "right" }}>
          <div style={{ marginLeft: "17px" }}><img src={negotiateIcon} data-testId="Negotiatebutton" alt="negotiate" /></div>
          <div style={{ marginLeft: "17px" }}><img src={rejectIcon} data-testId="Rejectbutton" alt="reject" /></div>
          <div style={{ marginLeft: "17px" }}><img src={acceptIcon} data-testId="Acceptbutton" alt="accept" /></div>
        </div>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
   
    return (
      <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <Navbar {...this.props} history={this.props.history} />
        <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
          {this.Comparisiontable()}
        </SideBar>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  header: {
    alignItems: "left",
    margin: "45px 0px",
  },
  heading: {
    fontFamily: "Inter, sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "26px",
  },
  Maincontainer: {
    width: "94%",
    margin: "0px 20px 0px 45px",
    fontFamily: "Inter, sans-serif",
  },
  Brandname: {
    width: "268px",
    height: "46px",
    fontWeight: 700,
    fontSize: "14px",
    backgroundColor: "#F3F4F6",
    borderRight: "1px solid #E7E8EF",
    color: "#565E6C",
    alignContent:"center",
  },
  Kefname: {
    fontWeight: 700,
    fontSize: "14px",
    color: "#64748B",
  },
  maintcell: {
    fontSize: "14px",
    fontWeight: 700,
    backgroundColor: "#F3F4F6",
    color: "#565E6C",
    border: "1px solid #E7E8EF",
    BorderTop: "none",
    height: "69px",
    width:'100%'
  },
  label: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"
  },
  firstother: {
    fontSize: "14px",
    padding:'12px 24px 12px 20px',
    color: "#272727",
    lineHight:'22px',
    border: "1px solid #E7E8F0",
  },
  CellHeight: {
    lineHeight: "22px",
    padding:'12px 24px 12px 20px',

    border: "1px solid #E7E8EF",
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842",
  },
  common: {
    fontSize: "14px",
    fontWeight: 700,
  },
  CellHeight1: {
    lineHeight: "22px",
    padding:'12px 24px 12px 20px',
    border: "1px solid #E7E8EF",
    fontSize: "14px",
    fontWeight: 400,
  },
  colspanhead: {
    lineHeight: "22px",
    padding:'12px 24px 12px 20px',
    fontSize: "14px",
    fontWeight: 700,
    color: "#272727",
    border: "1px solid #E7E8F0",
    backgroundColor: "#F0F0F0"
  },
  colspanhead1: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#272727",
    border: "1px solid #E7E8F0",
    lineHeight: "37px",
    backgroundColor: "#F3F4F6"
  },
  note: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#272727",
    border: "1px solid #E7E8F0",
    borderTop: "none",
    lineHeight: "50px",
    backgroundColor: "#F3F4F6",
    height:"40px"
  },
};
// Customizable Area End
