import React from "react";
// Customizable Area Start
import {
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import Navbar from "../../../components/src/Navbar.web";
import SideBar from "../../../components/src/SideBar.web";
import { Logo, RupeeIcon } from "./assets";
import CreditNoteDetailController, {
    Props
  } from "./CreditNoteDetailController.web";
// Customizable Area End



export default class CreditNoteDetail extends CreditNoteDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  CredotNoteDetail = () => {

    return (
      <Box style={{ width: "94%", ...style.MainDIV }}>
        <Box style={style.Head}>
          <Typography style={{ fontFamily: "Inter, sans-serif", fontSize: "24px", fontWeight: 700 }}>
          CN-240218-0010-1
          </Typography>
        </Box>
        <Box style={{ borderRadius: "8px", border: "1px solid #E2E7F0" }} >
          <div
            style={{
              maxWidth: "100%",
              padding: "30px",
              flexDirection: "column",
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                maxHeight: "calc(100vh - 80px)",
                flexDirection: "column",
                padding: "0",
                overflowY: "auto" }}>
              <div style={{ overflow: "hidden", maxHeight: "100vh", margin: "none" }}> 
                <div style={{ border: "1px solid #E7E2EF", padding: "20px", overflowY: "auto", maxHeight: "calc(50vh - 40px)" , margin:"none"}}> 
                  <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "21px" }}>  
                    <div style={{ display: "flex", margin: "auto 0px" }}>  

                      <div style={{ margin: "auto 0px" }}>  
                        <img src={Logo} alt="logo" style={{ width: "112.5px", height: "58px" }} />
                      </div>
                      <div style={{ marginLeft: "20px" }}>
                        <Typography style={{ ...style.companyname, fontFamily: "Inter, sans-serif" }}>
                          KRATEX TRADETECH INDIA PRIVATE LIMITED
                        </Typography>
                        <Typography style={{ ...style.gst, fontFamily: "Inter, sans-serif" }}>
                          GSTIN: <span style={{ ...style.gstno, marginLeft: "2px", fontFamily: "Inter, sans-serif" }}>27AAKCK5699N1ZG</span>
                        </Typography>
                        <Typography style={{ ...style.pan, fontFamily: "Inter, sans-serif" }}>
                          PAN: <span style={{ ...style.PanNO, marginLeft: "2px", fontFamily: "Inter, sans-serif" }}>AAKCK5699N</span>
                        </Typography>
                      </div>

                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: "50px" }}> 

                        <Typography style={{ ...style.add, fontFamily: "Inter, sans-serif" }}>Address:</Typography>
                        <Typography style={{ ...style.Add1, fontFamily: "Inter, sans-serif" }}>C46, Abhimanshree Society,</Typography>
                        <Typography style={{ ...style.Add1, fontFamily: "Inter, sans-serif" }}>Pune, Maharashtra</Typography>
                        <Typography style={{ ...style.Add1, fontFamily: "Inter, sans-serif" }}>411007</Typography>
                        
                      </div>
                      <div style={{ width: "220px" }}>
                        <Typography style={{ ...style.ConHed, fontFamily: "Inter, sans-serif" }}>
                          Contact Details:
                        </Typography>
                        <Typography style={{ ...style.ConDethead, fontFamily: "Inter, sans-serif", float: "left" }}>
                          Mobile: <span style={{ ...style.ConDet, fontFamily: "Inter, sans-serif" }}>+91-9876543210</span>
                        </Typography>
                        <Typography style={{ ...style.ConDethead, fontFamily: "Inter, sans-serif", float: "left" }}>
                          Email: <span style={{ ...style.ConDet, fontFamily: "Inter, sans-serif" }}>info@kratex.com</span>
                        </Typography>
                        <Typography style={{ ...style.ConDethead, fontFamily: "Inter, sans-serif", float: "left" }}>
                          Website: <span style={{ ...style.ConDet, fontFamily: "Inter, sans-serif" }}>kratex.co</span>
                        </Typography>
                      </div>

                    </div>
                  </div>

                  <div style={{ borderTop: "1px solid #E7E2EF", paddingTop: "20px" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
  <div style={{ display: "flex", justifyContent: "space-between" }}>
    <Box style={{ display: "flex" }}>
      <Typography style={{ ...style.Invokey, fontFamily: "Inter, sans-serif", minWidth: "200px", textAlign: "start" }}>
        Sales Return / Credit Note No.:
        <span style={{ ...style.InvoiVal, fontFamily: "Inter, sans-serif" }}> CN-240218-0010-2</span>
      </Typography>
    </Box>
    <Box style={{ display: "flex" }}>
      <Typography style={{ ...style.Invokey, fontFamily: "Inter, sans-serif", minWidth: "200px", textAlign: "start" }}>
        Sales Return / Credit Note Date:
        <span style={{ ...style.InvoiVal, fontFamily: "Inter, sans-serif" }}> 05 Feb 2024</span>
      </Typography>
    </Box>
    <Box style={{ display: "flex" }}>
      <Typography style={{ ...style.Invokey, fontFamily: "Inter, sans-serif", minWidth: "200px", textAlign: "start" }}>
        Place of Supply:
        <span style={{ ...style.InvoiVal, fontFamily: "Inter, sans-serif" }}> 27-Maharashtra</span>
      </Typography>
    </Box>
  </div>

  <div style={{ display: "flex", justifyContent: "space-between", marginTop:"15px" }}>
    <Box style={{ display: "flex" }}>
      <Typography style={{ ...style.Invokey, fontFamily: "Inter, sans-serif", minWidth: "200px", textAlign: "start" }}>
        PO Validity:
        <span style={{ ...style.InvoiVal, fontFamily: "Inter, sans-serif" }}> 31 July 2024</span>
      </Typography>
    </Box>
    <Box style={{ display: "flex",marginLeft:"59px" }}>
      <Typography style={{ ...style.Invokey, fontFamily: "Inter, sans-serif", minWidth: "200px",  }}>
        Against Invoice No.:
        <span style={{ ...style.InvoiVal, fontFamily: "Inter, sans-serif" }}> INV-240218-0010-2</span>
      </Typography>
    </Box>
    <Box style={{ display: "flex" }}>
      <Typography style={{ ...style.Invokey, fontFamily: "Inter, sans-serif", minWidth: "200px", textAlign: "start" }}>
        DN Request No.:
        <span style={{ ...style.InvoiVal, fontFamily: "Inter, sans-serif" }}> DN-240218-0010-2</span>
      </Typography>
    </Box>
  </div>

  <div style={{ display: "flex", justifyContent: "space-between",marginTop:"15px" }}>
    <Box style={{ display: "flex" }}>
      <Typography style={{ ...style.Invokey, fontFamily: "Inter, sans-serif", minWidth: "200px", textAlign: "start" }}>
        Debit Return Request Reason:
        <span style={{ ...style.InvoiVal, fontFamily: "Inter, sans-serif" }}> Broken</span>
      </Typography>
    </Box>
    <Box style={{ display: "flex" , marginLeft:"59px"}}>
      <Typography style={{ ...style.Invokey, fontFamily: "Inter, sans-serif", minWidth: "200px", textAlign: "start" }}>
        Delivery Challan No.:
        <span style={{ ...style.InvoiVal, fontFamily: "Inter, sans-serif" }}> DC-240218-0010-2</span>
      </Typography>
    </Box>
    <Box style={{ display: "flex" }}>
      <Typography style={{ ...style.Invokey, fontFamily: "Inter, sans-serif", minWidth: "200px", textAlign: "start" }}>
      Delivery Date:
        <span style={{ ...style.InvoiVal, fontFamily: "Inter, sans-serif" }}> 16 Aug 2024</span>
      </Typography>
    </Box>
  </div>
</div>

                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", padding: "20px", border: "1px solid #E7E2EF", marginTop: "20px", overflowY: "auto", maxHeight: "calc(50vh - 40px)" }}>
                  <div style={{ margin: "auto 0px" }}>
                    <Typography style={{ ...style.Cudet, fontFamily: "Inter, sans-serif" }}>Vendor Details: </Typography>
                    <Typography style={{ ...style.CuDetail, fontFamily: "Inter, sans-serif" }}>Test Vendor</Typography>
                    <Typography style={{ ...style.CuDetail, fontFamily: "Inter, sans-serif" }}>GSTIN: 27AAKCK5699N1ZG</Typography>
                    <Typography style={{ ...style.CuDetail, fontFamily: "Inter, sans-serif" }}>PAN: AAKCK5699N</Typography>
                  </div>
                  <div style={{ margin: "auto 0px" }}>
                    <Typography style={{ ...style.add, fontFamily: "Inter, sans-serif" }}>Billing Address:</Typography>
                    <Typography style={{ ...style.Add1, fontFamily: "Inter,sans-serif" }}>C46, Abhimanshree Society</Typography>
                    <Typography style={{ ...style.Add1, fontFamily: "Inter, sans-serif" }}>Abhimanshree Society</Typography>
                    <Typography style={{ ...style.Add1, fontFamily: "Inter,sans-serif" }}>Pune, Maharashtra, 411007</Typography>
                  </div>
                  <div style={{ display: "flex", margin: "0px 0px auto 0px" }}>
                    <div>
                      <Typography style={{ ...style.Ref, fontFamily: "Inter,sans-serif" }}>Reference:</Typography>
                      <Typography style={{ ...style.POID, fontFamily: "Inter, sans-serif" }}>PO-240218-0010-1</Typography>

                    </div>
                  </div>
                </div>
              </div>
              


            </div>
            <TableContainer style={{ marginTop: "20px", border: "1px solid #E7E2EF" }}>
              <Table aria-label="simple table" >
                <TableHead style={{ ...style.tableHead, padding: "20px" }}>
                  <TableRow style={{ borderBottom: "none" }}>
                    <TableCell style={{ fontFamily: "Inter, sans-serif", color: "#565E6C",fontWeight: 700, fontSize: "14px",  }}>#</TableCell>
                    <TableCell style={{ fontFamily: "Inter, sans-serif", color: "#565E6C",fontWeight: 700, fontSize: "14px",  }}>Item</TableCell>
                    <TableCell style={{ fontFamily: "Inter, sans-serif", color: "#565E6C",fontWeight: 700, fontSize: "14px",  }}>HSN/SAC</TableCell>
                    <TableCell style={{ fontFamily: "Inter, sans-serif", color: "#565E6C",fontWeight: 700, fontSize: "14px",  }}>Rate/Item</TableCell>
                    <TableCell style={{ fontFamily: "Inter, sans-serif", color: "#565E6C",fontWeight: 700, fontSize: "14px" , width:"100px" }}>Qty</TableCell>
                    <TableCell style={{ fontFamily: "Inter, sans-serif", color: "#565E6C",fontWeight: 700, fontSize: "14px", }}>Taxable Value</TableCell>
                    <TableCell style={{ fontFamily: "Inter, sans-serif", color: "#565E6C",fontWeight: 700, fontSize: "14px", }}>Tax Amount</TableCell>
                    <TableCell style={{ fontFamily: "Inter, sans-serif", color: "#565E6C",fontWeight: 700, fontSize: "14px", }}>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ ...style.Tabiid, fontFamily: "Inter, sans-serif", width: "0px" }}>1</TableCell>
                    <TableCell style={{ width: "0px" }}>
                      <Typography style={{ ...style.TabSecfir, fontFamily: "Inter,sans-serif" }}>Product Title</Typography>
                      <Typography style={{ ...style.TabSecdata, fontFamily: "Inter,sans-serif", width: "150px" }}>Sample Product 
                      Description</Typography>
                    </TableCell>
                    <TableCell style={{ ...style.TabComm, fontFamily: "Inter,sans-serif" }}>12345678</TableCell>
                    <TableCell>
                      <Typography style={{ ...style.TabComm, fontFamily: "Inter,sans-serif" }}>90.00</Typography>
                      <Typography style={{ ...style.Percent, fontFamily: "Inter,sans-serif" }}>100.00 (-10.00%)</Typography>

                    </TableCell>
                    <TableCell style={{ ...style.TabComm, fontFamily: "Inter,sans-serif", width:"100px" }}> 60</TableCell>
                    <TableCell style={{ ...style.TabComm, fontFamily: "Inter,sans-serif"}}>4,500.00</TableCell>
                    <TableCell>
                      <Typography style={{ ...style.TabComm, fontFamily: "Inter,sans-serif" }}>0.00 </Typography>
                      <Typography style={{ ...style.TabComm, fontFamily: "Inter,sans-serif" }}>(0%)</Typography>
                    </TableCell>
                    <TableCell style={{ ...style.TabComm, fontFamily: "Inter,sans-serif" }}>4,500.00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} style={{ ...style.TablesSp, fontFamily: "Inter, sans-serif" }}>Total Items (Qty)</TableCell>
                    <TableCell colSpan={6} style={{ ...style.TabSoanCell, fontFamily: "Inter, sans-serif",  textAlign:"right"}}>60</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} style={{ ...style.TablesSp, fontFamily: "Inter, sans-serif" }}>Taxable Amount</TableCell>
                    <TableCell colSpan={6} style={{ ...style.TabSoanCell, fontFamily: "Inter, sans-serif", textAlign: "right" }}><img src={RupeeIcon} alt="rupeeIcon" />4,500.00</TableCell>
                  </TableRow><TableRow>
                    <TableCell colSpan={2} style={{ ...style.TablesSp, fontFamily: "Inter, sans-serif" }}>CGST (6.0%)</TableCell>
                    <TableCell colSpan={6} style={{ ...style.TabSoanCell, fontFamily: "Inter, sans-serif", textAlign: "right" }}> <img src={RupeeIcon} alt="rupeeIcon" />250.00</TableCell>
                  </TableRow><TableRow>
                    <TableCell colSpan={2} style={{ ...style.TablesSp, fontFamily: "Inter, sans-serif" }}>SGST (6.0%)</TableCell>
                    <TableCell colSpan={6} style={{ ...style.TabSoanCell, fontFamily: "Inter, sans-serif", textAlign: "right" }}> <img src={RupeeIcon} alt="rupeeIcon" />250.00</TableCell>
                  </TableRow><TableRow>
                    <TableCell colSpan={2} style={{ ...style.TablesSp, fontFamily: "Inter, sans-serif" }}>Total Amount</TableCell>
                    <TableCell colSpan={6} style={{ ...style.TabSoanCell, fontFamily: "Inter, sans-serif", textAlign: "right" }}> <img src={RupeeIcon} alt="rupeeIcon" />5,000</TableCell>
                  </TableRow><TableRow>
                    <TableCell colSpan={2} style={{ ...style.TablesSp, fontFamily: "Inter, sans-serif", borderBottom:"none" }}>Total Amount (in words)</TableCell>
                    <TableCell colSpan={6} style={{ ...style.Amount, fontFamily: "Inter, sans-serif", textAlign: "right", borderBottom:"none" }}>INR One Lakh Thirty Five Thousand Only</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer style={{ marginTop: "20px" }}>
              <Table>
                <TableHead style={{ ...style.tableHead, padding: "20px" }}>
                  <TableRow>
                    <TableCell rowSpan={2} style={{ ...style.SeTabCel, fontFamily: "Inter, sans-serif" }}>HSN/SAC</TableCell>
                    <TableCell rowSpan={2} style={{ ...style.SeTabCel, fontFamily: "Inter, sans-serif" }}>Taxable Value</TableCell>
                    <TableCell colSpan={2} style={{ ...style.SeTabCel, textAlign: 'center', fontFamily: "Inter, sans-serif" }}>
                      Central Tax
                    </TableCell>
                    <TableCell colSpan={2} style={{ ...style.SeTabCel, textAlign: 'center', fontFamily: "Inter, sans-serif" }}>
                    State/UT Tax
                    </TableCell>
                    <TableCell rowSpan={2} style={{ ...style.SeTabCel, textAlign: "center", fontFamily: "Inter, sans-serif" }}>Total Tax Amount</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ ...style.SeTabCel, textAlign: "center", fontFamily: "Inter, sans-serif" }}>Rate</TableCell>
                    <TableCell style={{ ...style.SeTabCel, textAlign: "center", fontFamily: "Inter, sans-serif" }}>Amount</TableCell>
                    <TableCell style={{ ...style.SeTabCel, textAlign: "center", fontFamily: "Inter, sans-serif" }}>Rate</TableCell>
                    <TableCell style={{ ...style.SeTabCel, textAlign: "center", fontFamily: "Inter, sans-serif" }}>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ ...style.SeTaFirst, fontFamily: "Inter,sans-serif" }}>12345678</TableCell>
                    <TableCell style={{ ...style.SeTaComm, fontFamily: "Inter,sans-serif", textAlign:"center" }}>4500.00</TableCell>
                    <TableCell style={{ ...style.SeTaComm, fontFamily: "Inter,sans-serif", textAlign: "center", }}>6%</TableCell>
                    <TableCell style={{ ...style.SeTaComm, fontFamily: "Inter,sans-serif", textAlign: "center", }}>250.00</TableCell>
                    <TableCell style={{ ...style.SeTaComm, fontFamily: "Inter,sans-serif", textAlign: "center", }}>0%</TableCell>
                    <TableCell style={{ ...style.SeTaComm, fontFamily: "Inter,sans-serif", textAlign: "center", }}>250.00</TableCell>
                    <TableCell style={{ ...style.SeTaComm, fontFamily: "Inter,sans-serif", textAlign: "right", }}>500.00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ ...style.SecTabkey, fontFamily: "Inter,sans-serif" }}>Total</TableCell>
                    <TableCell style={{ ...style.TabSoanCell, fontFamily: "Inter,sans-serif", textAlign:"center" }}>4500.00</TableCell>
                    <TableCell style={{...style.TabSoanCell}}></TableCell>
                    <TableCell style={{ ...style.TabSoanCell, textAlign: "center", fontFamily: "Inter,sans-serif" }}>250.00</TableCell>
                    <TableCell style={{...style.TabSoanCell}}></TableCell>
                    <TableCell  style={{ ...style.TabSoanCell, textAlign: "center", fontFamily: "Inter,sans-serif" }}>250.00</TableCell>
                    <TableCell style={{ ...style.TabSoanCell, borderRight: "1px solid #E7E3EF",textAlign: "right", fontFamily: "Inter,sans-serif" }}>500.00</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box style={{ padding: "20px 0px", display: "flex", justifyContent: "space-between" }}>
                            <div style={{ justifyContent: "space-between" }}>
                                <Typography style={{ ...style.ConHed, fontFamily: "Inter, sans-serif" }}>Bank Details</Typography>
                                <Box style={{ display: "flex" }}>
                                    <Typography style={{ ...style.BankKEY, fontFamily: "Inter, sans-serif" }}>
                                        Bank:
                                        <span style={{ ...style.BankDetVal, fontFamily: "Inter, sans-serif", marginLeft: "5px" }}>
                                            Yes Bank
                                        </span>
                                    </Typography>
                                </Box>

                                <Box style={{ display: "flex" }}>
                                    <Typography style={{ ...style.BankKEY, fontFamily: "Inter, sans-serif" }}>
                                        Account Number:
                                        <span style={{ ...style.BankDetVal, fontFamily: "Inter, sans-serif", marginLeft: "5px" }}>
                                            000863400003864
                                        </span>
                                    </Typography>
                                </Box>

                                <Box style={{ display: "flex" }}>
                                    <Typography style={{ ...style.BankKEY, fontFamily: "Inter, sans-serif" }}>
                                        IFSC:
                                        <span style={{ ...style.BankDetVal, fontFamily: "Inter, sans-serif", marginLeft: "5px" }}>
                                            YESB0000008
                                        </span>
                                    </Typography>
                                </Box>

                                <Box style={{ display: "flex" }}>
                                    <Typography style={{ ...style.BankKEY, fontFamily: "Inter, sans-serif" }}>
                                        Branch:
                                        <span style={{ ...style.BankDetVal, fontFamily: "Inter, sans-serif", marginLeft: "5px" }}>
                                            SHIVAJI NAGAR, PUNE
                                        </span>
                                    </Typography>
                                </Box>
                            </div>

                            <Box>
                                <Typography style={{  fontFamily: "Inter, sans-serif",...style.ConHed, }}>For KrateX Tradetech India Pvt Ltd</Typography>
                                <Typography style={{ marginTop: "90px", ...style.BankDetVal,   fontFamily: "Inter, sans-serif",textAlign: "right", }}>Authorized Signatory</Typography>
                            </Box>
                        </Box>
          </div>

        </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box style={{ display: "flex",  flexDirection: "column",gap: "15px" }}>
      <Navbar {...this.props} history={this.props.history} />
      <SideBar data-testId="searchText" navigation={this.props.navigation} mycart={true} >
        {this.CredotNoteDetail()}
      </SideBar>
    </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const style = {

  navtab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "58px"
  },
  companyname: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"
  },
  gst: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"
  },
  gstno: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#323842"
  },
  MainDIV: {
    marginLeft: "47px",
    fontFamily: "Inter, sans-serif",
  },
  pan: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"

  },
  BankKEY: {
    fontSize: "14px",
    fontWeight: 500
},
BankDetVal: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#323842"
},
  PanNO: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#323842"
  },
  add: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#565E6C"
  },
  Add1: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"
  },
  Head: {
    margin: "24px 0px",
    fontWeight: 700,
    fontSize: "24px"
  },
  menuHeader: { fontSize: "14px", 
    fontWeight: 500, 
    fontFamily: "inter, sans-serif",
     color: '#323842', 
     lineHeight: '22px',
      width: "1085px" },

  heading: {
    fontWeight: 700,
    lineHeight: "26px",
    textAlign: "left",
    fontSize: "24px",
  },
  tableHead: { backgroundColor: "#f3f4f6" },
  
  singleBox: { display: "flex", 
    
    gap: "15px" },

  toggleBox:
   { display: "flex", 
    alignItems: "center",
     justifyContent: "space-between",
      padding: "17px", 
      cursor: "pointer", 
      backgroundColor: "#f4f4f4",
       margin: "19px", 
       border: "8px" },

  mainContent: { display: "flex",
     alignItems: "center", 
     justifyContent: "space-between", 
     padding: "17px", 
     margin: "19px" },


  Tabfont: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Inter",
    lineHeight: "20px",
  },
  ConHed: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#565E6C"
  },
  ConDethead: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"
  },
  ConDet: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#323842",
  },
  Invokey: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#565E6C"
  },
  InvoiVal: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"
  },
  Cudet: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#565E6C"
  },
  CuDetail: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"
  },
  Ref: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#565E6C"
  },

  POID: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"
  },

  Percent: {
    fontSize: "13px",
    fontWeight: 300,
    color: "#64748B"
  },
  Tabiid: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"
  },
  TabSecfir: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"
  },
  TableCell: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    textAlign: "left",
    color: "#565E6C",
  },
  TabSecdata: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#323842"
  },
  TabComm: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#323842"
  },
  percentage: {
    fontSize: "13px",
    fontWeight: 300,
    color: "#64748B"
  },
  TablesSp: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#383242",
    borderRight: "1px solid #E2E8F0",
    borderTop: "1px solid #E3E8F0",
    backgroundColor: "#F0F0F0",
  },

  Amount:{
    fontSize : "14px",
    fontWeight : 400,
    backgroundColor: "#F3F4F6",
    color : "#323842",

  },
  TabSoanCell: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#383242",
    border: "1px solid #E2E8F0",
    backgroundColor: "#F3F4F6",
  },
  SeTabCel: {
    fontSize: "14px",
    fontWeight: 700,
    border: "1px solid #E2E8F0",
    color: "#565E6C"
  },
  SeTaFirst: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842",
    border: "1px solid #E2E8F0",
  },
  SeTaComm: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#323842",
    border: "1px solid #E7E2EF"
  },
  SecTabkey: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#383242",
    border: "1px solid #E2E8F0",
    backgroundColor: "#F0F0F0",
  },
}
// Customizable Area End
