import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  // Customizable Area Start
  navigation: object;
  id: string;
  history : any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  sidebarToggle: boolean;
  openDialog: boolean;
  activeComponent: string;
  anchorEl: HTMLElement | null;
  selectedAction: string | null;
  searchText: string,
  value: number | 0;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class CreditCardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      sidebarToggle: false,
      openDialog: true,
      activeComponent: "Company",
      anchorEl: null,
      selectedAction: null,
      searchText: '',
      value: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
   
    // Customizable Area End
  }


  // Customizable Area Start

  navigateToCreditnoteDetail = () => {
    this.handleNavigate('CreditnoteDeatil')
   };
 
   handleNavigate = (route?: string) => {
     const message: Message = new Message(getName(MessageEnum.NavigationMessage))
     message.addData(
       getName(MessageEnum.NavigationTargetMessage),
       route
     );
     message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
     this.send(message);
   }
  handleTabChange = (newValue: number): void => {
    this.setState({ value: newValue});
  }

  handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {      
    this.setState({ anchorEl: event.currentTarget});
  };
  
  getStyleBasedOnStatus = (status: string): React.CSSProperties => {
    switch (status) {
      case 'Generated':
        return { height: "32px",
          borderRadius: "9px",
          backgroundColor: "#FFF6F0",
          color: "#EE6900",
          width: "auto",
          fontSize: "12px",
          fontWeight: 400 }; 
      case 'Cancelled':
        return { height: "32px",
          borderRadius: "9px",
          background: "#DE3B401A",
          color: "#DE3B40",
          width: "auto",
          fontSize: "12px",
          fontWeight: 400, }; 
      default:
        return { backgroundColor: 'none', color: 'none',  }; 
    }
  };
  handleMenuClose = (): void => {
    this.setState({ anchorEl: null });
  };
  
  // Customizable Area End
}
