import React, { ChangeEvent } from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: {
    location: {
      state:{
        user_id: string;
        phone: string
      }
    }
  }
  handleNext: () => void;
  // Customizable Area Start
  currentStep: number;
  passState:Function;
  bankStateData: {banknane: string, beneficiaryname: string, accountnumber: string, ifsccode: string, branchname: string};
  // Customizable Area End
}

interface S {
  openDialog: boolean;
  currentStep: number;
  fieldRequiredData: string;
  bankData: {banknane: string, beneficiaryname: string, accountnumber: string, ifsccode: string, branchname: string};
  // Customizable Area Start
  fieldRequiredDataBank:string;
  fieldRequiredDataBen:string;
  fieldRequiredDataAcc:string;
  fieldRequiredDataIfsc:string;
  fieldRequiredDataBranch:string;
  bankFieldData: {banknane: string, beneficiaryname: string, accountnumber: string, ifsccode: string, branchname: string}
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BankDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      openDialog: false,
      currentStep: 1,
      fieldRequiredData: "",
      bankData:{banknane: "", beneficiaryname: "", accountnumber: "", ifsccode: "", branchname:""}
      // Customizable Area Start
      ,fieldRequiredDataBank: "",
      fieldRequiredDataBen: "",
      fieldRequiredDataAcc: "",
      fieldRequiredDataIfsc: "",
      fieldRequiredDataBranch: "",
      bankFieldData:{banknane: "", beneficiaryname: "", accountnumber: "", ifsccode: "", branchname:""}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    await super.componentDidMount(); 
    this.setState({bankData: {...this.props.bankStateData}})
  }

  handleValidation = () => {
    let message = "Required field";
    if(this.state.bankData.banknane && this.state.bankData.beneficiaryname && this.state.bankData.accountnumber && this.state.bankData.ifsccode && this.state.bankData.branchname){
      return true     
    }
    else{
      if(!this.state.bankData.banknane){
        this.setState(prevState => ({
          bankFieldData: {...prevState.bankFieldData, bankname: message}
        }))
      }
      if(!this.state.bankData.beneficiaryname){
        this.setState(prevState => ({
          bankFieldData: {...prevState.bankFieldData, beneficiaryname: message}
        }))
      }
      if(!this.state.bankData.accountnumber){
        this.setState(prevState => ({
          bankFieldData: {...prevState.bankFieldData, accountnumber: message}
        }))
      }
      if(!this.state.bankData.ifsccode){
        this.setState(prevState => ({
          bankFieldData: {...prevState.bankFieldData, ifsccode: message}
        }))
      }
      if(!this.state.bankData.branchname){
        this.setState(prevState => ({
          bankFieldData: {...prevState.bankFieldData, branchname: message}
        }))
      }
    }
    }   

  handleBankInfoChange = (event:ChangeEvent<HTMLInputElement>) => {
    this.setState({bankData:{...this.state.bankData, [event.target.name]:event.target.value}});
    this.props.passState({...this.state.bankData, [event.target.name]:event.target.value});
    this.setState({bankFieldData:{...this.state.bankFieldData, [event.target.name]:''}});
    this.props.passState({...this.state.bankFieldData, [event.target.name]:''});
  }  

  handleBlur = () => {

    const { accountnumber } = this.state.bankData;
    const maskedAccountNumber = accountnumber.length > 4
      ? 'xxxxxxxxxxxxxxxxxx'.slice(0, -accountnumber.length) + accountnumber.slice(-4)
      : accountnumber;

    this.setState((prevState) => ({
      bankData: {
        ...prevState.bankData,
        accountnumber: maskedAccountNumber,
      },
    }));
  };

  // Customizable Area End
}
