import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from 'react';
import { Typography} from "@material-ui/core";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: {
    location: {
      state:{
        user_id: string;
        phone: string
      }
    }
  }
}

interface S {
  corosolTopImage: string[];
  corosolBottomImage: string[];
  token: string | null;
  page:number;
  data:any;
  options:any;
  selProdId:number;
  range:string;
  name:string;
  location:string;
  priceChange:number;
  updated:string;
  subMatId:number;
  matId:number;
  allSubMat:any[];
  showAllPrices:boolean;
  matName:string;
  subMatName:string;
}

interface SS {
  id: any;
}

export default class PriceHistoryController extends BlockComponent<any, S, SS> {
  getHistory:string="";
  getSubMat:string="";
  getSubMatName:string="";
  getMatName:string="";
  constructor(props: any) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      corosolTopImage: [],
      corosolBottomImage: [],
      token: "",
      page:2,
      selProdId:18,
      subMatId:11,
      matId:2,
      range:'1W',
      data:{
        labels: [],
        datasets: [
          {
            label: "Price",
            data: [],
            fill: true,
            backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "rgba(255, 102, 0, 1)"
          }
        ],
      },
      options :{
    
        scales: {
            y: {
             
              min: 0,
              max: 0,
              ticks: {
                stepSize: 1000,
                color:"#323743",
                font:{
                  size:12,
                  family:"Inter, sans-serif",
                  weight:"400"
                },
                padding:26
              },
              grid: {
                lineWidth:3,
                borderDash:[8, 12],
                borderColor:'#DEE1E6', 
                drawTicks: false, 
                drawOnChartArea: true ,
                width:10
                },
                elements:{
                  line:{borderDash:[4, 4]}
                },
            },
            x: {
              ticks: {
                color: "#323743",
                font: {
                  size: 12,
                  family: "Inter, sans-serif",
                  weight: "400"
                },
                padding:10
              },
            }
        },
        plugins: {
          legend: {
              display: false,
             
          }
      }
      },
      name:'',
      location:'',
      priceChange:0,
      updated:'',
      allSubMat:[],
      showAllPrices:false,
      matName:'',
      subMatName:''
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    await super.componentDidMount();
    const { match } = this.props;
    const { matId, subMatId, selMatId } = match.params;
    this.setState({selProdId:selMatId, matId:matId, subMatId:subMatId})
    const token = (typeof localStorage !== "undefined" && localStorage.getItem('otp-token')) || '';
    this.setState({ token: token});
    await this.getPriceHistory();
    await this.getSubMaterial();
    await this.getSubMaterialName();
    await this.getMaterialName();
  }
  async receive(from: string, message: Message) {
    
    runEngine.debugLog("Starting getting history", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId == this.getHistory) {
      const newLocation = responseJson?.location;
      const newName = responseJson?.name;
      const change = responseJson?.price_change_percentage;
      const lastUpdate = responseJson?.updated;
      let dates:any = [];
      let prices:any = [];
      let min:number = Infinity;
      let max:number = -Infinity;

      for (const key in responseJson?.data) {
        const value = responseJson?.data[key];
      
        if (Array.isArray(value)) {
          value.forEach((item: any) => {
            const formattedDate = new Date(item.date).toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
            });
            dates = [...dates, formattedDate];
            prices = [...prices, item.price];
      
            min = Math.min(min, item.price);
            max = Math.max(max, item.price);
          });
        } else {
          for (const month in value) {
            const datesArray = value[month];
            datesArray.forEach((item: any) => {
              const formattedDate = new Date(item.date).toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
              });
              dates = [...dates, formattedDate];
              prices = [...prices, item.price];
      
              min = Math.min(min, item.price);
              max = Math.max(max, item.price);
            });
          }
        }
      }

      this.setState({
        name:newName,
        location:newLocation,
        priceChange:change,
        updated:lastUpdate,
        data:{labels:dates, datasets: [{ label: "Price", data: prices, fill: true, backgroundColor: "rgba(75,192,192,0.2)", borderColor: "rgba(255, 102, 0, 1)" } ], },
        options :{ scales: { y: { min:min-500, max: max+500, ticks: { stepSize: 1000, color:"#323743", font:{ size:12, family:"Inter, sans-serif", weight:"400" }, padding:26 }, grid: { lineWidth:3, borderDash:[8, 12], borderColor:'#DEE1E6', drawTicks: false, drawOnChartArea: true , width:10 }, elements:{ line:{borderDash:[4, 4]} }, }, x: { ticks: { color: "#323743", font: { size: 12, family: "Inter, sans-serif", weight: "400" }, padding:10 }, } }, plugins: { legend: { display: false, } } },
      }, ()=>{
        this.calcStep()
      })     
    }
    if (apiRequestCallId == this.getSubMat) {
      const answer = responseJson.data;
      let cards:any[] = [];
      answer.map((elem:any)=>{
        cards = [...cards, elem]
      })
      this.setState({allSubMat:cards})
           
    }
    if (apiRequestCallId == this.getSubMatName) {
      const answer = responseJson?.data;
       answer?.filter((elem: any) => elem.id === this.state.subMatId).map((elem1:any)=>{
       this.setState({subMatName:elem1.attributes.name})
       })
       
    }
    if (apiRequestCallId == this.getMatName) {
      this.setState({matName:responseJson?.data.attributes.name})
    }
    
  }
  handleNavigateBar = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage),'Signuplogin');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  getPriceHistory = async () => {
    
    const headers = {
      token: this.state.token,
    };

    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.getHistory = getValidationsMsg.messageId

  getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),
  
      `bx_block_categories/material_products/price_history?material_product_id=${this.state.selProdId}&range=${this.state.range}`)
      

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      "GET"

    );
    
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    
  }
  getSubMaterial = async () => {
    
    const headers = {
      token: this.state.token,
    };

    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.getSubMat = getValidationsMsg.messageId

  getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),
  
      `bx_block_categories/material_products?material_id=${this.state.matId}&sub_material_id=${this.state.subMatId}`)
      

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      "GET"

    );
    
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    
  }
  getSubMaterialName = async () => {
    
    const headers = {
      token: this.state.token,
    };

    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.getSubMatName = getValidationsMsg.messageId

  getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),
  
      `bx_block_categories/sub_materials?material_id=${this.state.matId}`)
      

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      "GET"

    );
    
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    
  }
  getMaterialName = async () => {
    
    const headers = {
      token: this.state.token,
    };

    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.getMatName = getValidationsMsg.messageId

  getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),
  
      `bx_block_categories/materials/${this.state.matId}`)
      

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      "GET"

    );
    
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    
  }
  checkRange = (r:string) =>{
  return this.state.range==r?"price__history__chart__time__active":"price__history__chart__time__passive"
  }
  changeRange = (newRange:string) => {
    this.setState({range:newRange}, async ()=>{
      await this.getPriceHistory()
    })
  }
  toggleNumberCards = () => {
    this.setState({showAllPrices:!this.state.showAllPrices})
  }
  toggleText = () =>{
    if(!this.state.showAllPrices){
      return 'View'
    } else return 'Close'
  }
  redirectAnotherHistory = (id:number) =>{
    const { matId, subMatId } = this.state;
    this.props.history.push(`/PriceHistory/${matId}/${subMatId}/${id}`);
    this.setState({ selProdId: id }, () => {
      this.getPriceHistory();
      this.getSubMaterial();
      this.getSubMaterialName();
      this.getMaterialName();
    });
  }
  renderMainPercentage = (percent:number) =>{
    let arrow:any;
    let backColor;
    if(percent>=0){
      backColor = "#FFA76C"
      arrow = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="price__history__chart__info__price_percent__ico">
      <path d="M8 2.28516V13.7137" stroke="white" stroke-width="1.37143" stroke-miterlimit="10"/>
      <path d="M12 6.28516L8 2.28516L4 6.28516" stroke="white" stroke-width="1.37143" stroke-miterlimit="10" stroke-linecap="square"/>
    </svg>
    } else{
      backColor = "#DE3B40"
      percent = -percent;
      arrow = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="price__history__chart__info__price_percent__ico">
      <path d="M8 2.28516V13.7137" stroke="white" stroke-width="1.37143" stroke-miterlimit="10"/>
      <path d="M12 9.71484L8 13.7148L4 9.71484" stroke="white" stroke-width="1.37143" stroke-miterlimit="10" stroke-linecap="square"/>
    </svg>
    }
    if(percent==0){
      backColor = "#858885"
    }
    return(
      <Typography className="price__history__chart__info__price_percent" style={{backgroundColor:backColor}}>
        {arrow}
        {percent}%
      </Typography>
    )
  }
  renderSecondPercentage = (percent:number) =>{
    let arrow:any;
    let backColor;
    if(percent>=0){
      backColor = "#FFA76C"
      arrow = <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginBottom:"-1px"}}>
      <path d="M6.5 1.71484V10.2863" stroke="white" stroke-width="1.37143" stroke-miterlimit="10"/>
      <path d="M9.5 4.71484L6.5 1.71484L3.5 4.71484" stroke="white" stroke-width="1.37143" stroke-miterlimit="10" stroke-linecap="square"/>
    </svg>
    } else{
      backColor = "#DE3B40"
      percent = -percent;
      arrow = <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginBottom:"-1px"}}>
      <path d="M6.5 1.71484V10.2863" stroke="white" stroke-width="1.37143" stroke-miterlimit="10"/>
      <path d="M9.5 7.28516L6.5 10.2852L3.5 7.28516" stroke="white" stroke-width="1.37143" stroke-miterlimit="10" stroke-linecap="square"/>
    </svg>
    }
    if(percent==0){
      backColor = "#858885"
    }
    return(
      <Typography className="price__history__cards__card__price__changed__percent" style={{backgroundColor:backColor}}>
        {arrow}
        {percent}%
      </Typography>
    )
  }
  calcStep = () =>{
    const min:number = this.state?this.state.options.scales.y.min:0;
    const max:number = this.state?this.state.options.scales.y.max:0;
    const diff:number = max - min;
    const step:number = diff / 5;
    if(isFinite(step)){
      this.setState({options :{ scales: { y: { min:min, max: max, ticks: { stepSize: step, color:"#323743", font:{ size:12, family:"Inter, sans-serif", weight:"400" }, padding:26 }, grid: { lineWidth:3, borderDash:[8, 12], borderColor:'#171A1F', drawTicks: false, drawOnChartArea: true , width:10, borderWidth:2 }, elements:{ line:{borderDash:[4, 4]} }, }, x: { ticks: { color: "#323743", font: { size: 12, family: "Inter, sans-serif", weight: "400" }, padding:10 }, grid: { lineWidth:0,  borderColor:'#171A1F', drawTicks: false, drawOnChartArea: true , width:10, borderWidth:2 }} }, plugins: { legend: { display: false, } } },})
    }
  }
}