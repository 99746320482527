import React from "react";
// Customizable Area Start
import {
  Typography,
  Box,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  InputAdornment,
  Chip,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Navbar from "../../../components/src/Navbar.web";
import SideBar from "../../../components/src/SideBar.web";
import { filterIcon, invoiceIcon, RupeeIcon, seacrhIcon, sortingIcon } from "./assets";
import CreditCardController, {
    Props
  } from "./CreditCardController.web";
// Customizable Area End



export default class CreditCard extends CreditCardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    

    // Customizable Area End
  }

  // Customizable Area Start
  creditdetail = () => {
    const Creditnotedetails = [
      {
        amount: '28,541,745',
        againstinvoice: 'INV-240218-0010-1',
        creditno: 'CN-240218-0010-1',
        vender: 'Jindal Steel & Power',
        date: '09 Feb 2024',
        status: 'Generated'
      },
      {
        creditno: 'CN-240218-0010-2',
        againstinvoice: 'INV-240218-0010-2',
        amount: '28,541,645',
        date: '08 Feb 2024',
        status: 'Cancelled',
        vender: 'Havells'
      },
      {
        creditno: 'CN-240218-0010-3',
        amount: '28,551,745',
        againstinvoice: 'INV-240218-0010-3',
        status: 'Cancelled',
        vender: 'ACC Concrete',
        date: '09 Feb 2024'
      },
      {
        amount: '28,541,745',
        againstinvoice: 'INV-240218-0010-4',
        creditno: 'CN-240218-0010-4',
        vender: 'Polycab',
        date: '25 Feb 2024',
        status: 'Generated'
      },
      {
        creditno: 'CN-240218-0010-5',
        amount: '28,541,645',
        date: '08 Feb 2024',
        againstinvoice: 'INV-240218-0010-5',
        vender: 'Hitachi',
        status: 'Generated'
      },
      {
        creditno: 'CN-240218-0010-6',
        amount: '28,751,745',
        againstinvoice: 'INV-240218-0010-6',
        status: 'Cancelled',
        vender: 'Bosch',
        date: '20 Feb 2024'
      }
    ];

    const tableheaders = [
      { id: 'creditnoteno', label: 'Credit Note No' },
      { id: 'amount', label: 'Amount' },
      { id: 'againstinvoice', label: 'Against Invoice' },
      { id: 'status', label: 'Status' },
      { id: 'vendor', label: 'Vendor' },
      { id: 'date', label: 'Date' },
      { id: 'action', label: 'Actions' }
    ];

    const tabhead = [
      { label: 'All Results', value: 0 },
      { label: 'Generated', value: 1 },
      { label: 'Cancelled', value: 2 },
    ];

    return (
      <Box style={{ width: "94%", ...style.MainDiv }}>
        <Box>
          <Typography variant="h6" style={{ ...style.Head, fontFamily: "Inter, sans-serif" }}>
          Credit Notes
          </Typography>
        </Box>
        <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
          <Box style={{ ...style.NavTabdiv }}>
            <Box
              style={{
                flexGrow: 1,
                overflowX: "auto",
                display: "flex"
              }}
            >
           <Tabs
  TabIndicatorProps={{
    style: { background: "#EE6900", height: "4px", borderRadius: "8px" }
  }}
  onChange={(event, newValue) => this.handleTabChange(newValue)}
  value={this.state.value}
>
  {tabhead.map(({ label, value }) => (
    <Tab
      style={{
        fontFamily: "Inter, sans-serif",
        textTransform: "none",
        height: "58px",
        minWidth: "80px", 
        padding: "6px 34px" 
      }}
      label={
        <Typography
          style={this.state.value !== value ? style.InactiveTabs : style.ActiveTabs}
        >
          {label}
        </Typography>
      }
      key={value}
    />
  ))}
</Tabs>
            </Box>
            <Box style={style.SearchContainers}>
              <InputBase
                placeholder="Search"
                style={style.SearchInputs}
                inputProps={{
                  style: {
                    fontFamily: 'inter, sans-serif',
                    fontSize: 12,
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 0.87)"
                  }
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <img src={seacrhIcon} alt="search icon" style={{ width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
              <img src={sortingIcon} data-testId="filterIcon" alt="sorting icon" style={{ width: 20, height: 20, marginLeft: "8px" }} />
              <IconButton>
                <img src={filterIcon} data-testId="MoreVertIcon" alt="MoreVertIcon" style={{ width: 20, height: 20 }} />
              </IconButton>
            </Box>
          </Box>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead style={{ ...style.TableHeads }}>
                <TableRow style={{ borderBottom: "none" }}>
                  <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                  {tableheaders.map(({ id, label }) => (
                    <TableCell key={id} align="left" style={{ ...style.tableCells, borderTop: "none", borderBottom: "none", fontFamily: "Inter, sans-serif" }}>
                      {label}
                    </TableCell>
                  ))}
                  <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow style={{ height: "45px" }}></TableRow>
                {Creditnotedetails.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                    <TableCell align="left" style={{ ...style.Creditno, borderTop: "1px solid #E7E2Ef", borderLeft: "1px solid #E7E2EF", fontFamily: "Inter, sans-serif" }}>
                      {row.creditno}
                    </TableCell>
                    <TableCell align="left" style={{ ...style.Amount, borderTop: "1px solid #E7E2Ef", fontFamily: "Inter, sans-serif" }}>
                      <img src={RupeeIcon} alt="rupee" style={{ marginRight: "5px" }} />
                      {row.amount}
                    </TableCell>
                    <TableCell align="left" style={{ ...style.againstInvoice, borderTop: "1px solid #E7E2Ef", fontFamily: "Inter, sans-serif" }}>
                      {row.againstinvoice}
                    </TableCell>
                    <TableCell style={{ borderTop: "1px solid #E7E2Ef" }}>
                      <Chip
                        label={row.status}
                        style={{
                          ...this.getStyleBasedOnStatus(row.status),
                          textTransform: "none"
                        }}
                      />
                    </TableCell>
                    <TableCell style={{ ...style.Vendor, borderTop: "1px solid #E7E2Ef", fontFamily: "Inter,sans-serif" }}>
                      {row.vender}
                    </TableCell>
                    <TableCell style={{ ...style.Date,fontFamily: "Inter, sans-serif" ,borderTop: "1px solid #E7E2Ef" }}>
                      {row.date}
                    </TableCell>
                    <TableCell align="left" style={{ borderRight: "1px solid #E7E2EF", borderTop: "1px solid #E7E2Ef" }}>
                      <IconButton aria-label="more" onClick={this.handleMenuClick}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        PaperProps={{
                          style: { boxShadow: 'none', border: "1px solid #E7E2Ef" }
                        }}
                        style={{ margin: "5px", borderRadius: "10px" }}
                      >
                        <MenuItem
                          data-testId="menuIcon"
                          onClick={() => {
                            this.navigateToCreditnoteDetail();
                            this.handleMenuClose(); 
                          }}  
                          style={{ width: "170px", fontFamily: "Inter, sans-serif", fontSize: "14px", fontWeight: 400, color: "#323842" }}
                        >
                          <img src={invoiceIcon} style={{ marginRight: "10px" }} alt="Invoice Icon" />
                          View Credit Notes
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow style={{ height: "45px" }}></TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
      <Navbar {...this.props} history={this.props.history} />
      <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
        {this.creditdetail()}
      </SideBar>
    </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const style = {
  MainDiv: {
    fontFamily: "Inter, sans-serif",
    marginLeft: "47px",
  },
  Head: {
    justifyContent: "space-between",
    display: "flex",
    margin: "24px 0px",
    alignItems: "center",
    fontFamily: "Inter, sans-serif",
    fontWeight: 700,
    fontSize: "24px",
  },

  heading: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "26px",
    textAlign: "left",
  } as React.CSSProperties,
  TableHeads: {
    backgroundColor: "#f3f4f6",
  },
  tableCells: {
    fontSize: "14px",
    lineHeight: "22px",
    textAlign: "left",
    fontWeight: 600,
    color: "#565E6C",
    backgroundColor: "#F3F4F6",
  } as React.CSSProperties,
  Creditno: {
    fontSize: "14px",
    fontWeight: 700
  },
  againstInvoice: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#323842"
  },
  Vendor: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#323842"
  },
  Amount: {
    fontSize: "14px",
    color: "#323842",
    fontWeight: 400
  },
  Date: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#323842"
  },

  Tabfont: {
    fontFamily: "Inter",
    fontWeight: 400,
    lineHeight: "20px",
    fontSize: "12px"
  },
  NavTabdiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "58px"
  },

  SearchContainers: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 0px 0 0",
    width: "265px"
  },
  actionmenu: {
    width: "150px",
    boxShadow: "none",
  },
  SearchInputs: {
    width: "173px",
    height: "35px",
    opacity: 1,
    borderRadius: "9px",
    padding: "0px 10px",
    backgroundColor: "#F3F4F6",
    fontFamily: "Inter, sans-serif",

  },
  InactiveTabs: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "left",
    color: "#000000",
  } as React.CSSProperties,
  ActiveTabs: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "20px",
    textAlign: "left",
    color: "#EE6900",
  } as React.CSSProperties,
};
// Customizable Area End
