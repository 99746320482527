import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start 
  Tab,
  Tabs,
  InputBase,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
  InputAdornment,
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import {acknowledgement, filtericon, listIsEmpty, searchicon, sortingicon } from "./assets";
import LedgerController, {
  Props,
} from "./LedgerController";
import Navbar from "../../../components/src/Navbar.web";
  import SideBar from "../../../components/src/SideBar.web";
  import { styled } from "@material-ui/styles";
  import { styles } from "./PaymentSettlement.web";
  import MoreVertIcon from "@material-ui/icons/MoreVert";
  import { SettleBlock } from "./PaymentSettlement.web";

  const tableData = Array(1).fill({
    vendor:"KrateX",
    closingBalance:"(+) 13,25.878.59"
  });

  const LedgerBlock = styled("div")(({ theme }) => ({
    "& .MuiTab-root":{
      minWidth: "160px",
      "@media (max-width:1600px)": {
        minWidth:"120px"
      },
      "@media (max-width:1400px)": {
        minWidth:"100px"
      },
      "@media (max-width:1200px)": {
        minWidth:"80px"
      },
    },
    "& .Maincontainer": {
      width: "94%",
      margin: "0px 20px 0px 45px",
      fontFamily: "Inter, sans-serif",
      "@media (max-width:600px)": {
        margin: "0px 0px 0px 0px",
      },
    },
    "& .factoryAddress":{
      border:"1px solid #E1E7EF",
      padding:"20px",
      marginTop:"14px"
    },
    "& .factoryName":{
      fontWeight:700,
      fontSize:"14px",
      lineHeight:"22px",
      color:"#323842"
    },
    "& .factoryGstin":{
      fontWeight:500,
      fontSize:"14px",
      lineHeight:"22px",
      color:"#323842"
    },
    "& .factoryPan":{
      fontWeight:500,
      fontSize:"14px",
      lineHeight:"22px",
      color:"#323842"
    },
    "& .headerInfo":{
      fontWeight:700,
      fontSize:"14px",
      lineHeight:"22px",
      color:"#64748B"
    },
    "& .factoryAddress__factoryInfo":{
      fontWeight:700,
      fontSize:"14px",
      lineHeight:"22px",
      color:"#323842"
    },
    "& .mainFactoryInfo":{
      borderBottom:"1px solid #E1E7EF",
      paddingBottom:"16px",
      marginBottom:"16px"
    },
    "& .factoryAddress.MuiGrid-container": {
      flexWrap: "nowrap",
    },
    "& .MuiTypography-root":{
      fontFamily:"Inter, sans-serif"
    },
    "& .secondaryFactoryInfoText":{
      fontWeight:700,
      fontSize:"14px",
      lineHeight:"22px",
      color:"#323842",
      marginBottom:"16px"
    },
    "& .customerInvoiceDetail":{
      paddingTop:"16px"
    },
    "& .customerInvoiceDetails":{
      fontWeight:700,
      fontSize:"14px",
      lineHeight:"22px",
      color:"#323842",
    },
    "& .paidStatus":{
      height:"120px"
    },
    "& .paidStatusCont":{
      display:"flex",
      justifyContent:"flex-end"
    },
    "& .payStatus":{
      borderBottom:"1px solid #E2E8F0"
    },
    "& .downloadBtn":{
      fontWeight:500,
      fontSize:"16px",
      lineHeight:"24px",
      color:"#FFF",
      padding:"10px 16px",
      backgroundColor:"#FFA76C",
      border:"1px solid transparent",
      cursor:"pointer",
      borderRadius:"12px"
    },
    "& .downloadContainer":{
      display:"flex",
      justifyContent:"flex-end",
      marginTop:"137px",
      marginBottom:"44px",
      "@media (max-width:900px)": {
        marginTop:"50px"
      },
    },
    "& .MuiTabs-flexContainer":{
      overflowY:"auto"
    },
    "& .empty":{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      border:"1px solid #E1E7EF",
      height:"80vh"
    },
    "& .empty__ico":{
      height:"220px"
    }
  }))

export default class Ledger extends LedgerController {
  constructor(props: Props) {
    super(props);

  }

  render() {
    const tabLabels = [
      "All Results",
      "KrateX"
    ];

    const { value } = this.state;
    return(
      <SettleBlock>
        <LedgerBlock>
        <Navbar history={this.props.history} mycart={true}/>
        <SideBar navigation={this.props.navigation} mycart={true}>
            <Box className="MainContainer">
                <Box style={styles.header}>
                <Typography variant="h6" component="div" style={styles.heading}>
                    Ledger
                </Typography>
                </Box>

                <Box style={{ border: "1px solid #E7E2F0", borderRadius: "6px", marginBottom:"31px"}}>
                <Box style={styles.navTabContainer}>
                    <Box style={{ flexGrow: 1, display: "flex", overflowX: "auto", borderRadius: "10px 0px" }} >
                    <Tabs value={value} onChange={this.handleTabChange} TabIndicatorProps={{
                        style: { background: "#EE6900", height: "4px", borderRadius: "8px" },
                    }}>
                        {tabLabels.map((label, index) => (
                        <Tab key={index} style={{ textTransform: "none", height: "58px", }}
                            label={
                            <Box style={{ display: "flex" }} alignItems="center">
                                <Typography style={ value === index ? { ...styles.activeTab, textAlign: 'center' } : { ...styles.inactiveTab, textAlign: 'center' } } >
                                {label}
                                </Typography>
                            </Box>
                            }
                        />
                        ))}
                    </Tabs>
                    </Box>
                    <Box style={styles.searchContainer2}>
                    <InputBase
                        placeholder="Search"
                        className="searchInput1"
                        inputProps={{
                        style: { fontFamily: 'inter, sans-serif', fontSize: 12, fontWeight: 400, color: "rgba(0, 0, 0, 0.87)", },
                        }}
                        startAdornment={
                        <InputAdornment position="start">
                            <img src={searchicon} alt="search icon" style={{ width: 20, height: 20 }} />
                        </InputAdornment>
                        }
                    />
                    <img src={sortingicon} data-testId="filterIcon"
                        alt="sorting icon" style={{ width: 20, height: 20, marginLeft:"10px" }} />
                    <IconButton>
                        <img src={filtericon} data-testId="MoreVertIcon"
                        alt="filter icon" style={{ width: 20, height: 20 }} />
                    </IconButton>
                    </Box>
                </Box>

                <TableContainer style={{...styles.tableContainer, height:"100%"}}>
                    <Table aria-label="simple table">
                    <TableHead>
                        <TableRow style={styles.TableHead}>
                        <TableCell style={{ border: 'none', width: '0%' }}></TableCell>
                        {this.state.headers.map(({ id, label }) => (
                            <TableCell key={id} align="left" className="TableCellHead" style={{width:"47%"}}>
                            {label}
                            </TableCell>
                        ))}
                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody style={styles.TBody}>
                        <TableRow style={{ height: "40px" }}></TableRow>
                        {tableData.length > 0 ? tableData.map((row:any) => (
                        <React.Fragment>
                            <TableRow
                            data-test-id="ledgerRow"
                            style={{
                                ...styles.TableRow,
                                border: "none",
                                cursor:"pointer"
                            }} onClick={()=>{this.props.history.push('/LedgerDetail')}}
                            >
                            <TableCell style={{ ...styles.tableCell, width: "0%", border: "none" }}></TableCell>
                            <TableCell style={{ ...styles.purchaseId, fontFamily: "Inter, sans-serif", borderLeft: "1px solid #E7E2F0", borderTop: "1px solid #E7E2F0"}}>
                                {row.vendor}
                            </TableCell>
                            <TableCell style={{
                                ...styles.tableCell, borderRight: "1px solid #E7E2F0", fontFamily: "Inter, sans-serif", fontSize:"14px", color:"#323842"
                            }}>
                                {row.closingBalance}
                            </TableCell>
                            <TableCell style={{ width: "0%", border: "none" }}></TableCell>
                            </TableRow>
                        </React.Fragment>
                        )) : <React.Fragment><TableRow><TableCell colSpan={4} rowSpan={20}>
                          <Box className="empty"><img src={listIsEmpty} className="empty__ico" /></Box>
                          </TableCell></TableRow></React.Fragment>}
                      <TableRow style={{ height: "50px" }}></TableRow>

                    </TableBody>
                    </Table>
                </TableContainer>
                </Box>
        </Box >
        </SideBar>
      </LedgerBlock>
      </SettleBlock>
    )
  }
}


// Customizable Area End
