import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start 
  Tab,
  Tabs,
  InputBase,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
  InputAdornment,
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

const dummyTableData = [
  {
      invId: "INV-240218-0010-1",
      invAmount:"541,745",
      penAmount:"100,000",
      dueIn:"3 days"
  },
  {
    invId: "INV-240218-0010-2",
    invAmount:"505,345",
    penAmount:"200,854",
    dueIn:"5 days"
},
{
  invId: "INV-240218-0010-3",
  invAmount:"20,000,745",
  penAmount:"12,000,000",
  dueIn:"6 days"
},
]
const tableData = Array(3).fill(null).reduce(acc => acc.concat(dummyTableData), []);  


import {acknowledgement, filtericon, searchicon, sortingicon } from "./assets";
import PaymentTimelineController, {
  Props,
} from "./PaymentTimelineController";
import Navbar from "../../../components/src/Navbar.web";
import SideBar from "../../../components/src/SideBar.web";
import { styled } from "@material-ui/styles";
import { styles } from "./PaymentSettlement.web";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { SettleBlock } from "./PaymentSettlement.web";


const TimelineBlock = styled("div")(({ theme }) => ({
  "& .totalMount":{
    padding:"12px 16px",
    border:"1px solid transparent",
    borderRadius:"18px",
    marginBottom:"36px",
    width:"244px",
    paddingBottom:"5px"
  },
  "& .totalMount__due":{
    backgroundColor:"#FFE2CE",
    marginRight:"24px"
  },
  "& .totalMount__overdue":{
    backgroundColor:"#F5C4C6"
  },
  "& .totalMount__name":{
    fontFamily: "Inter, sans-serif",
      fontSize: "16px",
      lineHeight:"26px",
      fontWeight: 400,
      color: "#323743",
      paddingBottom:"37px"
  },
  "& .totalMount__number":{
    fontFamily: "Inter, sans-serif",
      fontSize: "26px",
      lineHeight:"48px",
      fontWeight: 400,
      color: "#323743",
  }
}))

export default class PaymentTimeline extends PaymentTimelineController {
  constructor(props: Props) {
    super(props);

  }

  render() {
    const tabLabels = [
        "Pending Payments",
        "Overdue",
        "Payment Completed",
      ];

      const { value } = this.state;
    return(
      <SettleBlock>
        <TimelineBlock>
        <Navbar history={this.props.history} mycart={true}/>
        <Box component="nav" style={{ backgroundColor: "#fff" }}>
            <SideBar navigation={this.props.navigation} mycart={true}>
            <Box className="MainContainer">
                <Box style={styles.header}>
                <Typography variant="h6" component="div" style={styles.heading}>
                    Payment Timeline
                </Typography>
                </Box>
                <Grid container direction="row">
                  <Grid item className="totalMount totalMount__due" data-test-id="total=amount">
                    <Typography className="totalMount__name">Total Due</Typography>
                    <Typography className="totalMount__number">₹2584949.07</Typography>
                  </Grid>
                  <Grid item className="totalMount totalMount__overdue" data-test-id="total=amount">
                    <Typography className="totalMount__name">Total Overdue</Typography>
                    <Typography className="totalMount__number">₹1204949.07</Typography>
                  </Grid>
                </Grid>

                <Box style={{ border: "1px solid #E7E2F0", borderRadius: "6px", marginBottom:"31px"}}>
                <Box style={styles.navTabContainer}>
                    <Box style={{ flexGrow: 1, display: "flex", overflowX: "auto", borderRadius: "10px 0px" }} >
                    <Tabs value={value} onChange={this.handleTabChange} TabIndicatorProps={{
                        style: { background: "#EE6900", height: "4px", borderRadius: "8px" },
                    }}>
                        {tabLabels.map((label, index) => (
                        <Tab key={index} style={{ textTransform: "none", height: "58px", }}
                            label={
                            <Box style={{ display: "flex" }} alignItems="center">
                                <Typography style={ value === index ? { ...styles.activeTab, textAlign: 'center' } : { ...styles.inactiveTab, textAlign: 'center' } } >
                                {label}
                                </Typography>
                            </Box>
                            }
                        />
                        ))}
                    </Tabs>
                    </Box>
                    <Box style={styles.searchContainer2}>
                    <InputBase
                        placeholder="Search"
                        className="searchInput1"
                        inputProps={{
                        style: { fontFamily: 'inter, sans-serif', fontSize: 12, fontWeight: 400, color: "rgba(0, 0, 0, 0.87)", },
                        }}
                        startAdornment={
                        <InputAdornment position="start">
                            <img src={searchicon} alt="search icon" style={{ width: 20, height: 20 }} />
                        </InputAdornment>
                        }
                    />
                    <img src={sortingicon} data-testId="filterIcon"
                        alt="sorting icon" style={{ width: 20, height: 20, marginLeft:"10px" }} />
                    <IconButton>
                        <img src={filtericon} data-testId="MoreVertIcon"
                        alt="filter icon" style={{ width: 20, height: 20 }} />
                    </IconButton>
                    </Box>
                </Box>

                <TableContainer style={styles.tableContainer}>
                    <Table aria-label="simple table">
                    <TableHead>
                        <TableRow style={styles.TableHead}>
                        <TableCell style={{ border: 'none', width: '0%' }}></TableCell>
                        {this.state.headers.map(({ id, label }) => (
                            <TableCell key={id} align="left" className="TableCellHead">
                            {label}
                            </TableCell>
                        ))}
                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody style={styles.TBody}>
                        <TableRow style={{ height: "40px" }}></TableRow>
                        {tableData.map((row:any) => (
                        <React.Fragment>
                            <TableRow
                            style={{
                                ...styles.TableRow,
                                border: "none",
                            }}
                            >
                            <TableCell style={{ ...styles.tableCell, width: "0%", border: "none" }}></TableCell>
                            <TableCell style={{ ...styles.purchaseId, fontFamily: "Inter, sans-serif", borderLeft: "1px solid #E7E2F0", borderTop: "1px solid #E7E2F0"}}>
                                {row.invId}
                            </TableCell>
                            <TableCell style={{ ...styles.amount, fontFamily: "Inter, sans-serif", borderTop: "1px solid #E7E2F0" }}>
                              <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.52034e-05 0.857208C8.52034e-05 0.383065 0.357239 0 0.799311 0H7.19312C7.63519 0 7.99234 0.383065 7.99234 0.857208C7.99234 1.33135 7.63519 1.71442 7.19312 1.71442H5.78948C6.02925 2.10016 6.20657 2.53412 6.30648 3.00023H7.19312C7.63519 3.00023 7.99234 3.38329 7.99234 3.85744C7.99234 4.33158 7.63519 4.71464 7.19312 4.71464H6.30398C5.9743 6.27637 4.75798 7.47914 3.25194 7.68541L6.85844 10.4472C7.21809 10.7231 7.30051 11.2589 7.04326 11.642C6.78601 12.025 6.2865 12.1161 5.92934 11.8402L0.334761 7.55415C0.0525344 7.33984 -0.0673495 6.95142 0.0375489 6.59515C0.142447 6.23887 0.452147 6.00046 0.799311 6.00046H2.79738C3.61658 6.00046 4.3209 5.47274 4.6281 4.71464H0.799311C0.357239 4.71464 8.52034e-05 4.33158 8.52034e-05 3.85744C8.52034e-05 3.38329 0.357239 3.00023 0.799311 3.00023H4.6281C4.3209 2.24214 3.61658 1.71442 2.79738 1.71442H0.799311C0.357239 1.71442 8.52034e-05 1.33135 8.52034e-05 0.857208Z" fill="#323842"/>
                              </svg>&nbsp;
                              {row.invAmount}
                            </TableCell>
                            <TableCell style={styles.tableCell}>
                            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.52034e-05 0.857208C8.52034e-05 0.383065 0.357239 0 0.799311 0H7.19312C7.63519 0 7.99234 0.383065 7.99234 0.857208C7.99234 1.33135 7.63519 1.71442 7.19312 1.71442H5.78948C6.02925 2.10016 6.20657 2.53412 6.30648 3.00023H7.19312C7.63519 3.00023 7.99234 3.38329 7.99234 3.85744C7.99234 4.33158 7.63519 4.71464 7.19312 4.71464H6.30398C5.9743 6.27637 4.75798 7.47914 3.25194 7.68541L6.85844 10.4472C7.21809 10.7231 7.30051 11.2589 7.04326 11.642C6.78601 12.025 6.2865 12.1161 5.92934 11.8402L0.334761 7.55415C0.0525344 7.33984 -0.0673495 6.95142 0.0375489 6.59515C0.142447 6.23887 0.452147 6.00046 0.799311 6.00046H2.79738C3.61658 6.00046 4.3209 5.47274 4.6281 4.71464H0.799311C0.357239 4.71464 8.52034e-05 4.33158 8.52034e-05 3.85744C8.52034e-05 3.38329 0.357239 3.00023 0.799311 3.00023H4.6281C4.3209 2.24214 3.61658 1.71442 2.79738 1.71442H0.799311C0.357239 1.71442 8.52034e-05 1.33135 8.52034e-05 0.857208Z" fill="#323842"/>
                            </svg>&nbsp;
                              {row.penAmount}
                            </TableCell>
                            <TableCell style={{ ...styles.amount, fontFamily: "Inter, sans-serif", borderTop: "1px solid #E7E2F0" }}>{row.dueIn}</TableCell>
                            <TableCell style={{
                                ...styles.tableCell,
                                borderRight: "1px solid #E7E2F0",
                            }}>
                                <IconButton
                                aria-label="more"
                                data-test-id="open-menu-ico"
                                onClick={(event) =>
                                    this.handleMenuClick(event)
                                }
                                data-testId="menuIcon"
                                >
                                <MoreVertIcon />
                                </IconButton>
                                <Menu
                                anchorEl={this.state.anchorEL}
                                keepMounted
                                data-test-id="popup-menu"
                                PaperProps={{style:{boxShadow: 'none', border: "1px solid #E7E2Ef", borderRadius:"8px"}}}
                                open={Boolean(this.state.anchorEL)}
                                onClose={this.handleMenuClose}
                                >
                                <MenuItem data-testId="invoicemenuItem" style={{ ...styles.menuitem, fontFamily: "Inter, sans-serif" }}>
                                    <img src={acknowledgement} style={styles.imagedropdown} />
                                    View Invoice
                                </MenuItem>
                                </Menu>
                            </TableCell>
                            <TableCell style={{ width: "0%", border: "none" }}></TableCell>
                            </TableRow>
                        </React.Fragment>
                        ))}
                      <TableRow style={{ height: "50px" }}></TableRow>

                    </TableBody>
                    </Table>
                </TableContainer>
                </Box>
            </Box>
            </SideBar >
        </Box >
        </TimelineBlock>
      </SettleBlock>
    )
  }
}


// Customizable Area End
