import React, { ChangeEvent } from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { State, City }  from 'country-state-city';

interface FieldRequire {
  companyname: string;
  contact: string;
  email: string;
  phonenumber: string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  isEdit: boolean;
  general: any,
  passState:Function,
  fieldRequiredData: FieldRequire
  // Customizable Area End
}

interface S {
    // Customizable Area Start
    generalData:any;
    showDropDownCom: boolean;
    selectedComOption: string;
    filteredCitieData: any;
            filteredStateData: any;
            showDropDownCity:boolean;
            showDropDownState:boolean;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class BillingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
            // Customizable Area Start
            generalData: { city: "",street:"",contact: "", email: "", zip:"", country: ""},
            showDropDownCom: false,
            selectedComOption: "",
            filteredCitieData: [],
            filteredStateData: [],
            showDropDownCity:false,
            showDropDownState:false,
            
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount(); 
    this.setState({ generalData: this.props.general, selectedComOption: this.props.general.selectedComOption})
    
    const cities = City.getCitiesOfCountry('IN');
    this.setState({ filteredCitieData: cities });
    const states = State.getStatesOfCountry('IN');
    this.setState({ filteredStateData:states})
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined){
    if(prevProps.general.companyname !== this.props.general.companyname){  
    this.setState({ generalData: this.props.general, selectedComOption: this.props.general.selectedComOption})
    }
  }

  // Customizable Area Start

  handleGeneralInfoChange = (event:ChangeEvent<HTMLInputElement>) => {
    this.setState({generalData:{...this.state.generalData, [event.target.name]:event.target.value}});
    this.props.passState({ ...this.state.generalData, [event.target.name]: event.target.value, selectedComOption: this.state.selectedComOption });
  }  
  handleStreetInfoChange = (event:ChangeEvent<HTMLInputElement>) => {
    this.setState({generalData:{...this.state.generalData, street:event.target.value}});
    this.props.passState({ ...this.state.generalData, street: event.target.value, selectedComOption: this.state.selectedComOption });
  }
  handleCityInfoChange = (event:ChangeEvent<HTMLInputElement>) => {
    this.setState({generalData:{...this.state.generalData, city:event.target.value}});
    this.props.passState({ ...this.state.generalData, city: event.target.value, selectedComOption: this.state.selectedComOption });
  }
  handleZipInfoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const filteredValue = inputValue.replace(/[^\d]/g, ''); 
    
    event.target.value = filteredValue;
  
    if (filteredValue.length <= 6) {
      this.setState({ 
        generalData: { ...this.state.generalData, zip: filteredValue }
      });
      this.props.passState({ 
        ...this.state.generalData, 
        zip: filteredValue, 
        selectedComOption: this.state.selectedComOption 
      });
    }
  };
  
  handleStateDrop = () => {
    this.setState((prevState) => ({showDropDownState:!prevState.showDropDownState}))
  }
  handleDropdownCity = () => {
    this.setState((prevState) => ({showDropDownCity:!prevState.showDropDownCity}))
  }
  
  
  // Customizable Area End
}
