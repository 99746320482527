import React from "react";
// Customizable Area Start
import {
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import Navbar from "../../../components/src/Navbar.web";
import SideBar from "../../../components/src/SideBar.web";
import DebitNoteDetailController, {
  Props
} from "./DebitNoteDetailController.web";
import { Logo, RupeeIcon } from "./assets";

// Customizable Area End



export default class DebitNoteDetail extends DebitNoteDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  DebitnoteDetail = () => {

    return (
      <Box style={{ width: "94%", ...style.MainContainer }}>
        <Box style={style.MainHeader}>
          <Typography style={{ fontFamily: "Inter, sans-serif", fontSize: "24px", fontWeight: 700 }}>
          DN-240218-0010-1
          </Typography>
        </Box>
        <Box style={{ borderRadius: "8px", border: "1px solid #E2E7F0" }} >
          <div
            style={{
              padding: "30px",
              display: "flex",
              flexDirection: "column",
              maxWidth: "100%",
            }}
          >
            <div
              style={{
                flexDirection: "column",
                padding: "0",
                display: "flex",
                maxHeight: "calc(100vh - 80px)",
                overflowY: "auto",
              }}
            >

              <div style={{  maxHeight: "100vh",overflow: "hidden" }}> 
                <div style={{ maxHeight: "calc(50vh - 40px)",  overflowY: "auto",padding: "20px", border: "1px solid #E7E2EF" }}> 
                  <div style={{ marginBottom: "20px",justifyContent: "space-between",display: "flex" }}> 

                    <div style={{ display: "flex", margin: "auto 0px" }}> 
                      <div style={{ margin: "auto 0px" }}>  
                        <img style={{ width: "112.5px", height: "58px" }} alt="logo" src={Logo} /> 
                      </div>
                      <div style={{ marginLeft: "20px" }}>
                        <Typography style={{ ...style.ComName, fontFamily: "Inter, sans-serif" }}>
                          KRATEX TRADETECH INDIA PRIVATE LIMITED
                        </Typography>
                        <Typography style={{ ...style.GST, fontFamily: "Inter, sans-serif" }}>
                          GSTIN: <span style={{ ...style.GSTNO, marginLeft: "2px", fontFamily: "Inter, sans-serif" }}>27AAKCK5699N1ZG</span>
                        </Typography>
                        <Typography style={{ ...style.PAN, fontFamily: "Inter, sans-serif" }}>
                          PAN: <span style={{ ...style.panno, marginLeft: "2px", fontFamily: "Inter, sans-serif" }}>AAKCK5699N</span>
                        </Typography>
                      </div>

                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: "50px" }}>
                        <Typography style={{ ...style.AddressOne, fontFamily: "Inter, sans-serif" }}>Address:</Typography>
                        <Typography style={{ ...style.AddressOne1, fontFamily: "Inter, sans-serif" }}>C46, Abhimanshree Society,</Typography>
                        <Typography style={{ ...style.AddressOne1, fontFamily: "Inter, sans-serif" }}>Pune, Maharashtra</Typography>
                        <Typography style={{ ...style.AddressOne1, fontFamily: "Inter, sans-serif" }}>411007</Typography>
                      </div>
                      <div style={{ width: "220px" }}>
                        <Typography style={{ ...style.ConHead, fontFamily: "Inter, sans-serif" }}>
                          Contact Details:
                        </Typography>
                        <Typography style={{ ...style.ConDetHead, fontFamily: "Inter, sans-serif", float: "left" }}>
                          Mobile: <span style={{ ...style.ConDetail, fontFamily: "Inter, sans-serif" }}>+91-9876543210</span>
                        </Typography>
                        <Typography style={{ ...style.ConDetHead, fontFamily: "Inter, sans-serif", float: "left" }}>
                          Email: <span style={{ ...style.ConDetail, fontFamily: "Inter, sans-serif" }}>info@kratex.com</span>
                        </Typography>
                        <Typography style={{ ...style.ConDetHead, fontFamily: "Inter, sans-serif", float: "left" }}>
                          Website: <span style={{ ...style.ConDetail, fontFamily: "Inter, sans-serif" }}>kratex.co</span>
                        </Typography>
                      </div>

                    </div>
                  </div>

                  <div style={{ borderTop: "1px solid #E7E2EF", paddingTop: "20px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <Box style={{ display: "flex" }}>
                        <Typography style={{ ...style.InvoiceKey, fontFamily: "Inter, sans-serif" }}>
                          Sales Return / Credit Note No.:
                          <span style={{ ...style.InvoiceValue, fontFamily: "Inter, sans-serif" }}> CN-240218-0010-2</span>
                        </Typography>
                      </Box>
                      <Box style={{ display: "flex" }}>
                        <Typography style={{ ...style.InvoiceKey, fontFamily: "Inter, sans-serif" }}>
                          Sales Return / Credit Note Date:
                          <span style={{ ...style.InvoiceValue, fontFamily: "Inter, sans-serif" }}> 05 Feb 2024</span>
                        </Typography>
                      </Box>
                      <Box style={{ display: "flex" }}>
                        <Typography style={{ ...style.InvoiceKey, fontFamily: "Inter, sans-serif" }}>
                          Place of Supply:
                          <span style={{ ...style.InvoiceValue, fontFamily: "Inter, sans-serif" }}> 27-Maharashtra</span>
                        </Typography>
                      </Box>
                    </div>


                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex", marginTop: "15px" }}>
                        <Typography style={{ ...style.InvoiceKey, fontFamily: "Inter, sans-serif" }}>
                          Against Invoice No.:
                          <span style={{ ...style.InvoiceValue, fontFamily: "Inter, sans-serif" }}> INV-240218-0010-2</span>
                        </Typography>
                        <div style={{ marginLeft: "263px", width: "310px" }}>
                          <Typography style={{ ...style.InvoiceKey, fontFamily: "Inter, sans-serif" }}>
                            Debit Return Request reason:
                            <span style={{ ...style.InvoiceValue, fontFamily: "Inter, sans-serif" }}> Broken</span>
                          </Typography>
                        </div>
                      </div>
                      <div style={{ display: "flex", marginTop: "15px" }}>
                        <Typography style={{ ...style.InvoiceKey, fontFamily: "Inter, sans-serif" }}>
                          Delivery Challan No.:
                          <span style={{ ...style.InvoiceValue, fontFamily: "Inter, sans-serif" }}> DC-240218-0010-2</span>
                        </Typography>
                        <div style={{ marginLeft: "260px", width: "300px" }}>
                          <Typography style={{ ...style.InvoiceKey, fontFamily: "Inter, sans-serif" }}>
                            Delivery Date:
                            <span style={{ ...style.InvoiceValue, fontFamily: "Inter, sans-serif" }}> 16 Aug 2024</span>
                          </Typography>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", padding: "20px", border: "1px solid #E7E2EF", marginTop: "20px", overflowY: "auto", maxHeight: "calc(50vh - 40px)" }}>
                  <div style={{ margin: "auto 0px" }}>
                    <Typography style={{ ...style.CusDetail, fontFamily: "Inter, sans-serif" }}>Vendor Details: </Typography>
                    <Typography style={{ ...style.CusDetail1, fontFamily: "Inter, sans-serif" }}>Test Vendor</Typography>
                    <Typography style={{ ...style.CusDetail1, fontFamily: "Inter, sans-serif" }}>GSTIN: 27AAKCK5699N1ZG</Typography>
                    <Typography style={{ ...style.CusDetail1, fontFamily: "Inter, sans-serif" }}>PAN: AAKCK5699N</Typography>
                  </div>
                  <div style={{ margin: "auto 0px" }}>
                    <Typography style={{ ...style.AddressOne, fontFamily: "Inter, sans-serif" }}>Billing Address:</Typography>
                    <Typography style={{ ...style.AddressOne1, fontFamily: "Inter,sans-serif" }}>C46, Abhimanshree Society</Typography>
                    <Typography style={{ ...style.AddressOne1, fontFamily: "Inter, sans-serif" }}>Abhimanshree Society</Typography>
                    <Typography style={{ ...style.AddressOne1, fontFamily: "Inter,sans-serif" }}>Pune, Maharashtra, 411007</Typography>
                  </div>
                  <div style={{ display: "flex", margin: "0px 0px auto 0px" }}>
                    <div>
                    </div>
                    <div>
                      <Typography style={{ ...style.Ref, fontFamily: "Inter,sans-serif" }}>Reference:</Typography>
                      <Typography style={{ ...style.poid, fontFamily: "Inter, sans-serif" }}>PO-240218-0010-1</Typography>
                    </div>
                  </div>
                </div>
              </div>


            </div>
            <TableContainer style={{ marginTop: "20px", border: "1px solid #E7E2EF" }}>
              <Table aria-label="simple table" >
                <TableHead style={{ ...style.tableHead, padding: "20px" }}>
                  <TableRow style={{ borderBottom: "none" }}>
                    <TableCell style={{  color: "#565E6C", fontFamily: "Inter, sans-serif",fontSize: "14px", fontWeight: 700 }}>#</TableCell>
                    <TableCell style={{  color: "#565E6C" ,fontFamily: "Inter, sans-serif",fontSize: "14px", fontWeight: 700 }}>Item</TableCell>
                    <TableCell style={{  color: "#565E6C" ,fontFamily: "Inter, sans-serif",fontSize: "14px", fontWeight: 700 }}>HSN/SAC</TableCell>
                    <TableCell style={{  color: "#565E6C" ,fontFamily: "Inter, sans-serif",fontSize: "14px", fontWeight: 700 }}>Rate/Item</TableCell>
                    <TableCell style={{  color: "#565E6C" ,fontFamily: "Inter, sans-serif",fontSize: "14px", fontWeight: 700, width:"100px" }}>Qty</TableCell>
                    <TableCell style={{  color: "#565E6C" ,fontFamily: "Inter, sans-serif",fontSize: "14px", fontWeight: 700}}>Taxable Value</TableCell>
                    <TableCell style={{  color: "#565E6C" ,fontFamily: "Inter, sans-serif",fontSize: "14px", fontWeight: 700}}>Tax Amount</TableCell>
                    <TableCell style={{  color: "#565E6C" ,fontFamily: "Inter, sans-serif",fontSize: "14px", fontWeight: 700}}>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ ...style.TabId, fontFamily: "Inter, sans-serif", width: "0px" }}>1</TableCell>
                    <TableCell style={{ width: "0px" }}>
                      <Typography style={{ ...style.TabSecFirst, fontFamily: "Inter,sans-serif" }}>Product Title</Typography>
                      <Typography style={{ ...style.TabSecData, fontFamily: "Inter,sans-serif", width: "150px" }}>Sample Product Description</Typography>
                    </TableCell>
                    <TableCell style={{ ...style.TabCommon, fontFamily: "Inter,sans-serif" }}>12345678</TableCell>
                    <TableCell>
                      <Typography style={{ ...style.TabCommon, fontFamily: "Inter,sans-serif" }}>90.00</Typography>
                      <Typography style={{ ...style.Percent, fontFamily: "Inter,sans-serif" }}>100.00 (-10.00%)</Typography>
                    </TableCell>
                    <TableCell style={{ ...style.TabCommon, fontFamily: "Inter,sans-serif", width:"100px" }}> 60</TableCell>
                    <TableCell style={{ ...style.TabCommon, fontFamily: "Inter,sans-serif"}}>4,500.00</TableCell>
                    <TableCell>
                      <Typography style={{ ...style.TabCommon, fontFamily: "Inter,sans-serif" }}>0.00 </Typography>
                      <Typography style={{ ...style.TabCommon, fontFamily: "Inter,sans-serif" }}>(0%)</Typography>
                    </TableCell>
                    <TableCell style={{ ...style.TabCommon, fontFamily: "Inter,sans-serif" }}>4,500.00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} style={{ ...style.TabSpanCell, fontFamily: "Inter, sans-serif" }}>Total Items (Qty)</TableCell>
                    <TableCell colSpan={6} style={{ ...style.TabSpanCelldata, fontFamily: "Inter, sans-serif",  textAlign:"right"}}>60</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} style={{ ...style.TabSpanCell, fontFamily: "Inter, sans-serif" }}>Taxable Amount</TableCell>
                    <TableCell colSpan={6} style={{ ...style.TabSpanCelldata, fontFamily: "Inter, sans-serif", textAlign: "right" }}><img src={RupeeIcon} alt="rupeeIcon" />4,500.00</TableCell>
                  </TableRow><TableRow>
                    <TableCell colSpan={2} style={{ ...style.TabSpanCell, fontFamily: "Inter, sans-serif" }}>CGST (6.0%)</TableCell>
                    <TableCell colSpan={6} style={{ ...style.TabSpanCelldata, fontFamily: "Inter, sans-serif", textAlign: "right" }}> <img src={RupeeIcon} alt="rupeeIcon" />250.00</TableCell>
                  </TableRow><TableRow>
                    <TableCell colSpan={2} style={{ ...style.TabSpanCell, fontFamily: "Inter, sans-serif" }}>SGST (6.0%)</TableCell>
                    <TableCell colSpan={6} style={{ ...style.TabSpanCelldata, fontFamily: "Inter, sans-serif", textAlign: "right" }}> <img src={RupeeIcon} alt="rupeeIcon" />250.00</TableCell>
                  </TableRow><TableRow>
                    <TableCell colSpan={2} style={{ ...style.TabSpanCell, fontFamily: "Inter, sans-serif" }}>Total Amount</TableCell>
                    <TableCell colSpan={6} style={{ ...style.TabSpanCelldata, fontFamily: "Inter, sans-serif", textAlign: "right" }}> <img src={RupeeIcon} alt="rupeeIcon" />5,000</TableCell>
                  </TableRow><TableRow>
                    <TableCell colSpan={2} style={{ ...style.TabSpanCell, fontFamily: "Inter, sans-serif" , borderBottom:"none"}}>Total Amount (in words)</TableCell>
                    <TableCell colSpan={6} style={{ ...style.Amount, fontFamily: "Inter, sans-serif", textAlign: "right",borderBottom:"none" }}>INR One Lakh Thirty Five Thousand Only</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer style={{ marginTop: "20px" }}>
              <Table>
                <TableHead style={{ ...style.tableHead, padding: "20px" }}>
                  <TableRow>
                    <TableCell rowSpan={2} style={{ ...style.SecTabCell, fontFamily: "Inter, sans-serif" }}>HSN/SAC</TableCell>
                    <TableCell rowSpan={2} style={{ ...style.SecTabCell, fontFamily: "Inter, sans-serif" }}>Taxable Value</TableCell>
                    <TableCell colSpan={2} style={{ ...style.SecTabCell, textAlign: 'center', fontFamily: "Inter, sans-serif" }}>
                      Central Tax
                    </TableCell>
                    <TableCell colSpan={2} style={{ ...style.SecTabCell, textAlign: 'center', fontFamily: "Inter, sans-serif" }}>
                    State/UT Tax
                    </TableCell>
                    <TableCell rowSpan={2} style={{ ...style.SecTabCell, textAlign: "center", fontFamily: "Inter, sans-serif" }}>Total Tax Amount</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ ...style.SecTabCell, textAlign: "center", fontFamily: "Inter, sans-serif" }}>Rate</TableCell>
                    <TableCell style={{ ...style.SecTabCell, textAlign: "center", fontFamily: "Inter, sans-serif" }}>Amount</TableCell>
                    <TableCell style={{ ...style.SecTabCell, textAlign: "center", fontFamily: "Inter, sans-serif" }}>Rate</TableCell>
                    <TableCell style={{ ...style.SecTabCell, textAlign: "center", fontFamily: "Inter, sans-serif" }}>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ ...style.SecTabFirst, fontFamily: "Inter,sans-serif" }}>12345678</TableCell>
                    <TableCell style={{ ...style.SecTabCommon, fontFamily: "Inter,sans-serif", textAlign:"center" }}>4500.00</TableCell>
                    <TableCell style={{ ...style.SecTabCommon, fontFamily: "Inter,sans-serif", textAlign: "center", }}>6%</TableCell>
                    <TableCell style={{ ...style.SecTabCommon, fontFamily: "Inter,sans-serif", textAlign: "center", }}>250.00</TableCell>
                    <TableCell style={{ ...style.SecTabCommon, fontFamily: "Inter,sans-serif", textAlign: "center", }}>0%</TableCell>
                    <TableCell style={{ ...style.SecTabCommon, fontFamily: "Inter,sans-serif", textAlign: "center", }}>250.00</TableCell>
                    <TableCell style={{ ...style.SecTabCommon, fontFamily: "Inter,sans-serif", textAlign: "right", }}>500.00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ ...style.SecTabKey, fontFamily: "Inter,sans-serif" }}>Total</TableCell>
                    <TableCell style={{ ...style.TabSpanCelldata, fontFamily: "Inter,sans-serif", textAlign:"center" }}>4500.00</TableCell>
                    <TableCell style={{...style.TabSpanCelldata,}}></TableCell>
                    <TableCell style={{ ...style.TabSpanCelldata, textAlign: "center", fontFamily: "Inter,sans-serif" }}>250.00</TableCell>
                    <TableCell style={{...style.TabSpanCelldata,}}></TableCell>
                    <TableCell style={{ ...style.TabSpanCelldata, textAlign: "center", fontFamily: "Inter,sans-serif" }}>250.00</TableCell>
                    <TableCell style={{ ...style.TabSpanCelldata, borderRight: "1px solid #E7E3EF",textAlign: "right", fontFamily: "Inter,sans-serif" }}>500.00</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box style={{ padding: "20px 0px", textAlign:"right", marginRight:"3px" }}>
              <Box style={{textAlign:"right"}}>
                <Typography style={{ ...style.ConHead, fontFamily: "Inter, sans-serif" }}>For KrateX Tradetech India Pvt Ltd</Typography>
                <Typography style={{ ...style.bankdetailvalue, fontFamily: "Inter, sans-serif", marginTop: "90px", textAlign: "right" }}>Authorized Signatory</Typography>
              </Box>
            </Box>
          </div>

        </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box style={{ display: "flex", gap: "15px", flexDirection: "column", }}>
        <Navbar {...this.props} history={this.props.history} />
        <SideBar navigation={this.props.navigation} mycart={true} data-testId="searchText">
          {this.DebitnoteDetail()}
        </SideBar>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const style = {
  tableHead: {
    backgroundColor: "#f3f4f6",
  },
  TableCell: {
    fontWeight: 600,
    color: "#565E6C",
    textAlign: "left",
    lineHeight: "22px",
    fontSize: "14px",
  },

  navTabContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "58px"
  },

  Amount:{
    fontWeight : 400,
    fontSize : "14px",
     color : "#323842",
     backgroundColor: "#F3F4F6"

  },

  icon: {
    margin: "auto 5px",
  },

  activeTab: {
    fontFamily: "Arial, sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "center" as "left" | "right" | "center" | "justify",
    color: "#EE6900",
  },
  inactiveTab: {
    fontFamily: "Arial, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "center" as "left" | "right" | "center" | "justify",
    color: "#000000",
  },
  ComName: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"
  },
  GST: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"
  },
  PAN: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"

  },
  panno: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#323842"
  },
  AddressOne: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#565E6C"
  },
  AddressOne1: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"
  },
  ConHead: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#565E6C"
  },
  ConDetHead: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"
  },
  MainContainer: {
    fontFamily: "Inter, sans-serif",
    marginLeft: "47px",
  },
  ConDetail: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#323842",
  },
  InvoiceKey: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#565E6C"
  },
  GSTNO: {
    color: "#323842",
    fontSize: "14px",
    fontWeight: 500
  },
  InvoiceValue: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"
  },
  CusDetail: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#565E6C"
  },
  CusDetail1: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"
  },
  Ref: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#565E6C"
  },
  poid: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"
  },
  MainHeader: {
    fontWeight: 700,
    margin: "24px 0px",
    fontSize: "24px"
  },
  TabId: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"
  },
  TabSecData: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#323842"
  },

  Percent: {
    fontSize: "13px",
    fontWeight: 300,
    color: "#64748B"
  },
  TabSpanCell: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#383242",
    borderRight: "1px solid #E2E8F0",
    borderTop: "1px solid #E3E8F0",
    backgroundColor: "#F0F0F0",
  },
  TabSpanCelldata: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#383242",
    border: "1px solid #E3E8F0",
    backgroundColor: "#F3F4F6",
  },
  SecTabCell: {
    fontSize: "14px",
    fontWeight: 700,
    border: "1px solid #E2E8F0",
    color: "#565E6C"
  },
  TabSecFirst: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"
  },
  SecTabFirst: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842",
    border: "1px solid #E2E8F0",
  },
  SecTabCommon: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#323842",
    border: "1px solid #E7E2EF"
  },
  SecTabKey: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#383242",
    border: "1px solid #E2E8F0",
    backgroundColor: "#F0F0F0",
  },
  secondtablevalue: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#383242",
    border: "1px solid #E3E8F0",
    backgroundColor: "#F3F4F6",
  },

  bankdetailvalue: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#323842"
  },

  TabCommon: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#323842"
  }
}
// Customizable Area End
