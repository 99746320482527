export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const slider1 = require("../assets/slider-1.jpg");
export const slider2 = require("../assets/slider-2.jpg");
export const slider3 = require("../assets/slider-3.jpg");
export const dummyProduct = require("../assets/dummy-image.jpg");
export const parcel = require("../assets/parcel.png");
export const Done = require("../assets/done.svg");
export const InstantIcon = require("../assets/instantIcon.svg");
export const Downarrow = require("../assets/downarrow.svg");
export const buttonIocn = require("../assets/buttoniocn.svg");
export const lead = require("../assets/lead.svg");
export const Regional = require("../assets/regional.svg");
export const transport = require("../assets/transport.svg");
export const view = require("../assets/view.svg");
export const zoom = require("../assets/zoom.svg");
export const location = require("../assets/location.svg");
export const callIcon = require("../assets/callicon.svg");
export const mainIcon = require("../assets/mail.svg");





