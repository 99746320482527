import React from "react";
import {
    Typography,
    Box,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@material-ui/core";
import Navbar from "../../../components/src/Navbar.web";
import SideBar from "../../../components/src/SideBar.web";
import { Logo, Scanner } from "./assets";

import TaxInvoiceController, {
    Props,
} from "./TaxInvoiceController.web";

export default class TaxInvoice extends TaxInvoiceController {
    constructor(props: Props) {
        // Customizable Area Start
        // Customizable Area End
        super(props);
    }

    // Customizable Area Start
    taxinvoice = () => {
        const tabData = [
            { label: 'Invoice 01', value: 0 },
            { label: 'Invoice 02', value: 1 },
            { label: 'Invoice 03', value: 2 },
            { label: 'Invoice 04', value: 3 },
            { label: 'Invoice 05', value: 4 }
        ];

        return (
            <Box style={{ width: "94%", ...style.mainContainer }}>
                <Box style={style.header}>
                    <Typography style={{ fontFamily: "Inter, sans-serif", fontSize: "24px", fontWeight: 700 }}>
                        INV-240218-0010-1
                    </Typography>
                </Box>
                <Box style={{ borderRadius: "8px", border: "1px solid #E2E7F0" }}>
                    <Box style={{ ...style.navTabContainer }}>
                        <Box style={{
                            overflowX: "auto",
                            display: "flex",
                            flexGrow: 1
                        }}
                        >
                            <Tabs
                                TabIndicatorProps={{
                                    style: { background: "#EE6900", height: "4px", borderRadius: "8px" },
                                }}
                                onChange={(event, newValue) => this.handleTabChange(newValue)}
                                value={this.state.value}
                            >
                                {tabData.map(({ label, value }) => (
                                    <Tab
                                        style={{
                                            fontFamily: "Inter, sans-serif",
                                            textTransform: "none",
                                            height: "58px",
                                            minWidth: "80px",
                                            padding: "6px 40px",
                                        }}
                                        label={
                                            <Typography
                                                style={this.state.value !== value ? style.inactiveTab : style.activeTab}
                                            >
                                                {label}
                                            </Typography>
                                        }
                                        key={value}
                                    />
                                ))}
                            </Tabs>
                        </Box>
                    </Box>

                    <div
                        style={{
                            borderTop: "1px solid #E7E2EF",
                            overflow: "hidden",
                            display: "flex",
                            flexDirection: "column",
                            padding: "30px",
                            maxWidth: "100%",
                        }}
                    >
                        <div
                            style={{
                                padding: "0",
                                display: "flex",
                                flexDirection: "column",
                                overflowY: "auto",
                                maxHeight: "calc(100vh - 80px)",
                            }}
                        >
                            <div style={{ overflow: "hidden", maxHeight: "100vh" }}>
                                <div style={{ border: "1px solid #E7E2EF", padding: "20px", overflowY: "auto", maxHeight: "calc(50vh - 40px)" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                                        <div style={{ display: "flex", margin: "auto 0px" }}>
                                            <div style={{ margin: "auto 0px" }}>
                                                <img src={Logo} alt="logo" style={{ width: "112.5px", height: "58px" }} />
                                            </div>
                                            <div style={{ marginLeft: "28px" }}>
                                                <Typography style={{ ...style.companyname, fontFamily: "Inter, sans-serif" }}>
                                                    KRATEX TRADETECH INDIA PRIVATE LIMITED
                                                </Typography>
                                                <Typography style={{ ...style.GST, fontFamily: "Inter, sans-serif", marginTop:"2px" }}>
                                                    GSTIN:
                                                    <span style={{ ...style.GSTNO, fontFamily: "Inter, sans-serif" }}> 27AAKCK5699N1ZG</span>
                                                </Typography>
                                                <Typography style={{ ...style.PAN, fontFamily:"Inter,sans-serif", marginTop:"2px" }}>
                                                    PAN:
                                                    <span style={{ ...style.panno,fontFamily:"Inter, sans-serif" }}> AAKCK5699N</span>
                                                </Typography>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ marginRight: "70px" }}>
                                                <Typography style={{ ...style.address, fontFamily: "Inter, sans-serif" }}>Address:</Typography>
                                                <Typography style={{ ...style.address1, fontFamily: "Inter, sans-serif" }}>C46, Abhimanshree Society,</Typography>
                                                <Typography style={{ ...style.address1, fontFamily: "Inter, sans-serif" }}>Pune, Maharashtra</Typography>
                                                <Typography style={{ ...style.address1, fontFamily: "Inter, sans-serif" }}>411007</Typography>
                                            </div>
                                            <div style={{ width: "220px" }}>
                                                <Typography style={{ ...style.contacthead, fontFamily: "Inter, sans-serif" }}>
                                                    Contact Details:
                                                </Typography>
                                                <Typography style={{ ...style.contactdetailhead, fontFamily: "Inter, sans-serif", float: "left", marginTop:"2px" }}>
                                                    Mobile:
                                                    <span style={{ ...style.contactdetail, fontFamily: "Inter, sans-serif" }}> +91-9876543210</span>
                                                </Typography>
                                                <Typography style={{ ...style.contactdetailhead, fontFamily: "Inter, sans-serif", float: "left" , marginTop:"2px"}}>
                                                    Email:
                                                    <span style={{ ...style.contactdetail, fontFamily: "Inter, sans-serif" }}> info@kratex.com</span>
                                                </Typography>
                                                <Typography style={{ ...style.contactdetailhead, fontFamily: "Inter, sans-serif", float: "left", marginTop:"2px" }}>
                                                    Website:
                                                    <span style={{ ...style.contactdetail, fontFamily: "Inter, sans-serif" }}> kratex.co</span>
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ borderTop: "1px solid #E7E2EF", paddingTop: "20px" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Box style={{ display: "flex" }}>
                                                <Typography style={{ ...style.invoicekey, fontFamily: "Inter, sans-serif" }}>
                                                    Invoice ID:
                                                    <span style={{ ...style.invoicevalue, fontFamily: "Inter, sans-serif" }}> INV-240219-0010</span>
                                                </Typography>
                                            </Box>
                                            <Box style={{ display: "flex" }}>
                                                <Typography style={{ ...style.invoicekey, fontFamily: "Inter, sans-serif" }}>
                                                    Invoice Date:
                                                    <span style={{ ...style.invoicevalue, fontFamily: "Inter, sans-serif" }}> 05 Feb 2024</span>
                                                </Typography>
                                            </Box>
                                            <Box style={{ display: "flex" }}>
                                                <Typography style={{ ...style.invoicekey, fontFamily: "Inter, sans-serif" }}>
                                                    Invoice Due Date:
                                                    <span style={{ ...style.invoicevalue, fontFamily: "Inter, sans-serif" }}> 05 Feb 2024</span>
                                                </Typography>
                                            </Box>
                                        </div>


                                        <div style={{ display: "flex", marginTop: "12px" }}>
                                            <Typography style={{ ...style.invoicekey, fontFamily: "Inter, sans-serif" }}>
                                                PO Validity:
                                                <span style={{ ...style.invoicevalue, fontFamily: "Inter, sans-serif" }}> 30 Feb 2024</span>
                                            </Typography>
                                            <div style={{ marginLeft: "382px", width: "300px" }}>
                                                <Typography style={{ ...style.invoicekey, fontFamily: "Inter, sans-serif" }}>
                                                    Place of Supply:
                                                    <span style={{ ...style.invoicevalue, fontFamily: "Inter, sans-serif" }}> 27-Maharashtra</span>
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: "flex", justifyContent: "space-between", padding: "20px", border: "1px solid #E7E2EF", marginTop: "20px", overflowY: "auto", maxHeight: "calc(50vh - 40px)" }}>
                                    <div style={{ margin: "auto 0px" }}>
                                        <Typography style={{ ...style.customerdetail, fontFamily: "Inter, sans-serif" }}>Customer Details:</Typography>
                                        <Typography style={{ ...style.customerdetail1, fontFamily: "Inter, sans-serif", marginTop:"2px" }}>Test Customer</Typography>
                                        <Typography style={{ ...style.customerdetail1, fontFamily: "Inter, sans-serif", marginTop:"2px" }}>GSTIN: 27AAKCK5699N1ZG</Typography>
                                        <Typography style={{ ...style.customerdetail1, fontFamily: "Inter, sans-serif" , marginTop:"2px"}}>PAN: AAKCK5699N</Typography>
                                    </div>
                                    <div style={{ margin: "auto 0px" }}>
                                        <Typography style={{ ...style.address, fontFamily: "Inter, sans-serif" }}>Billing Address:</Typography>
                                        <Typography style={{ ...style.address1, fontFamily: "Inter,sans-serif", marginTop:"2px" }}>C46, Abhimanshree Society</Typography>
                                        <Typography style={{ ...style.address1, fontFamily: "Inter, sans-serif" , marginTop:"2px"}}>Abhimanshree Society</Typography>
                                        <Typography style={{ ...style.address1, fontFamily: "Inter,sans-serif" , marginTop:"2px"}}>Pune, Maharashtra, 411007</Typography>
                                    </div>
                                    <div style={{ margin: "auto 0px" }}>
                                        <Typography style={{ ...style.address, fontFamily: "Inter, sans-serif" }}>Shipping Address:</Typography>
                                        <Typography style={{ ...style.address1, fontFamily: "Inter,sans-serif", marginTop:"2px" }}>C46, Abhimanshree Society</Typography>
                                        <Typography style={{ ...style.address1, fontFamily: "Inter, sans-serif", marginTop:"2px" }}>Abhimanshree Society</Typography>
                                        <Typography style={{ ...style.address1, fontFamily: "Inter,sans-serif" , marginTop:"2px"}}>Pune, Maharashtra, 411007</Typography>
                                    </div>
                                    <div style={{ display: "flex", margin: "auto 0px" }}>
                                        <div>
                                            <img src={Scanner} alt="scanner" width="84px" height="86px" />
                                        </div>
                                        <div style={{ margin: "auto 7px" }}>
                                            <Typography style={{ ...style.reference, fontFamily: "Inter,sans-serif" }}>Reference:</Typography>
                                            <Typography style={{ ...style.poid, fontFamily: "Inter, sans-serif" , marginTop:"2px"}}>PO-240218-0010-1</Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <TableContainer style={{ marginTop: "20px", border: "1px solid #E7E2EF" }}>
                            <Table aria-label="simple table" >
                                <TableHead style={{ ...style.tableHead, padding: "20px" }}>
                                    <TableRow style={{ borderBottom: "none" }}>
                                        <TableCell style={{ fontFamily: "Inter, sans-serif", fontSize: "14px", fontWeight: 700, color: "#565E6C" }}>#</TableCell>
                                        <TableCell style={{ fontFamily: "Inter, sans-serif", fontSize: "14px", fontWeight: 700, color: "#565E6C" }}>Item</TableCell>
                                        <TableCell style={{ fontFamily: "Inter, sans-serif", fontSize: "14px", fontWeight: 700, color: "#565E6C" }}>HSN/SAC</TableCell>
                                        <TableCell style={{ fontFamily: "Inter, sans-serif", fontSize: "14px", fontWeight: 700, color: "#565E6C" }}>Rate/Item</TableCell>
                                        <TableCell style={{ fontFamily: "Inter, sans-serif", fontSize: "14px", fontWeight: 700, color: "#565E6C" }}>Qty</TableCell>
                                        <TableCell style={{ fontFamily: "Inter, sans-serif", fontSize: "14px", fontWeight: 700, color: "#565E6C", textAlign: "right" }}>Taxable Value</TableCell>
                                        <TableCell style={{ fontFamily: "Inter, sans-serif", fontSize: "14px", fontWeight: 700, color: "#565E6C", textAlign: "right" }}>Tax Amount</TableCell>
                                        <TableCell style={{ fontFamily: "Inter, sans-serif", fontSize: "14px", fontWeight: 700, color: "#565E6C", textAlign: "right" }}>Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ ...style.tableid, fontFamily: "Inter, sans-serif", width: "0px" }}>1</TableCell>
                                        <TableCell style={{ width: "0px" }}>
                                            <Typography style={{ ...style.tablesecondfirst, fontFamily: "Inter,sans-serif" }}>Product Title</Typography>
                                            <Typography style={{ ...style.tableseconddata, fontFamily: "Inter,sans-serif", width: "210px" }}>Sample Product Description goes here
                                                Lorem Ipsum dolor</Typography>
                                        </TableCell>
                                        <TableCell style={{ ...style.tablecommon, fontFamily: "Inter,sans-serif" }}>12345678</TableCell>
                                        <TableCell>
                                            <Typography style={{ ...style.tablecommon, fontFamily: "Inter,sans-serif" }}>90.00</Typography>
                                            <Typography style={{ ...style.percentage, fontFamily: "Inter,sans-serif" }}>100.00 (-10.00%)</Typography>
                                        </TableCell>
                                        <TableCell style={{ ...style.tablecommon, fontFamily: "Inter,sans-serif" }}> 60</TableCell>
                                        <TableCell style={{ ...style.tablecommon, fontFamily: "Inter,sans-serif", textAlign: "right" }}>4,500.00</TableCell>
                                        <TableCell style={{ textAlign: "right" }}>
                                            <Typography style={{ ...style.tablecommon, fontFamily: "Inter,sans-serif" }}>0.00 </Typography>
                                            <Typography style={{ ...style.tablecommon, fontFamily: "Inter,sans-serif" }}>(0%)</Typography>
                                        </TableCell>
                                        <TableCell style={{ ...style.tablecommon, fontFamily: "Inter,sans-serif", textAlign: "right" }}>4,500.00</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ ...style.tablespancell, fontFamily: "Inter, sans-serif" }}>Total Items (Qty)</TableCell>
                                        <TableCell colSpan={6} style={{ ...style.tablespancelldata, fontFamily: "Inter, sans-serif", textAlign: "right" }}>180</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ ...style.tablespancell, fontFamily: "Inter, sans-serif" }}>Taxable Amount</TableCell>
                                        <TableCell colSpan={6} style={{ ...style.tablespancelldata, fontFamily: "Inter, sans-serif", textAlign: "right" }}>45,000.00</TableCell>
                                    </TableRow><TableRow>
                                        <TableCell colSpan={2} style={{ ...style.tablespancell, fontFamily: "Inter, sans-serif" }}>CGST (6%)</TableCell>
                                        <TableCell colSpan={6} style={{ ...style.tablespancelldata, fontFamily: "Inter, sans-serif", textAlign: "right" }}>3,295.00</TableCell>
                                    </TableRow><TableRow>
                                        <TableCell colSpan={2} style={{ ...style.tablespancell, fontFamily: "Inter, sans-serif" }}>SGST (6%)</TableCell>
                                        <TableCell colSpan={6} style={{ ...style.tablespancelldata, fontFamily: "Inter, sans-serif", textAlign: "right" }}>3,295.00</TableCell>
                                    </TableRow><TableRow>
                                        <TableCell colSpan={2} style={{ ...style.tablespancell, fontFamily: "Inter, sans-serif" }}>Total Amount</TableCell>
                                        <TableCell colSpan={6} style={{ ...style.tablespancelldata, fontFamily: "Inter, sans-serif", textAlign: "right" }}>45,000.00</TableCell>
                                    </TableRow><TableRow>
                                        <TableCell colSpan={2} style={{ ...style.tablespancell, fontFamily: "Inter, sans-serif", borderBottom:"none" }}>Total Amount (in words)</TableCell>
                                        <TableCell colSpan={6} style={{ ...style.Amount, fontFamily: "Inter, sans-serif", textAlign: "right", borderBottom:"none" }}>INR One Lakh Thirty Five Thousand Only</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TableContainer style={{ marginTop: "20px" }}>
                            <Table>
                                <TableHead style={{ ...style.tableHead, padding: "20px" }}>
                                    <TableRow>
                                        <TableCell rowSpan={2} style={{ ...style.secondtablecell, fontFamily: "Inter, sans-serif" }}>HSN/SAC</TableCell>
                                        <TableCell rowSpan={2} style={{ ...style.secondtablecell, fontFamily: "Inter, sans-serif" }}>Taxable Value</TableCell>
                                        <TableCell colSpan={2} style={{ ...style.secondtablecell, textAlign: 'center', fontFamily: "Inter, sans-serif" }}>
                                            Central Tax
                                        </TableCell>
                                        <TableCell colSpan={2} style={{ ...style.secondtablecell, textAlign: 'center', fontFamily: "Inter, sans-serif" }}>
                                            State Tax
                                        </TableCell>
                                        <TableCell rowSpan={2} style={{ ...style.secondtablecell, textAlign: "center", fontFamily: "Inter, sans-serif" }}>Total Tax Amount</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ ...style.secondtablecell, textAlign: "center", fontFamily: "Inter, sans-serif" }}>Rate</TableCell>
                                        <TableCell style={{ ...style.secondtablecell, textAlign: "center", fontFamily: "Inter, sans-serif" }}>Amount</TableCell>
                                        <TableCell style={{ ...style.secondtablecell, textAlign: "center", fontFamily: "Inter, sans-serif" }}>Rate</TableCell>
                                        <TableCell style={{ ...style.secondtablecell, textAlign: "center", fontFamily: "Inter, sans-serif" }}>Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ ...style.secondtablefirst, fontFamily: "Inter,sans-serif" }}>12345678</TableCell>
                                        <TableCell style={{ ...style.secondtablecommon, fontFamily: "Inter,sans-serif" }}>4500.00</TableCell>
                                        <TableCell style={{ ...style.secondtablecommon, fontFamily: "Inter,sans-serif", textAlign: "center", }}>0%</TableCell>
                                        <TableCell style={{ ...style.secondtablecommon, fontFamily: "Inter,sans-serif", textAlign: "center", }}>0.00</TableCell>
                                        <TableCell style={{ ...style.secondtablecommon, fontFamily: "Inter,sans-serif", textAlign: "center", }}>0%</TableCell>
                                        <TableCell style={{ ...style.secondtablecommon, fontFamily: "Inter,sans-serif", textAlign: "center", }}>0.00</TableCell>
                                        <TableCell style={{ ...style.secondtablecommon, fontFamily: "Inter,sans-serif", textAlign: "right", }}>0.00</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ ...style.secondtableKey, fontFamily: "Inter,sans-serif" }}>Total</TableCell>
                                        <TableCell style={{ ...style.secondtablevalue, fontFamily: "Inter,sans-serif" }}>4500.00</TableCell>
                                        <TableCell colSpan={2} style={{ ...style.secondtablevalue, textAlign: "right", fontFamily: "Inter,sans-serif" }}>0.00</TableCell>
                                        <TableCell colSpan={2} style={{ ...style.secondtablevalue, textAlign: "right", fontFamily: "Inter,sans-serif" }}>0.00</TableCell>
                                        <TableCell style={{ ...style.secondtablevalue, textAlign: "right", fontFamily: "Inter,sans-serif" }}>0.00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ borderLeft: "1px solid #E7E3EF", borderRight: "1px solid #E7E2EF" }}>
                                        <TableCell colSpan={3} style={{ ...style.secondtableKey, fontFamily: "Inter,sans-serif" }}>Amount Payable</TableCell>
                                        <TableCell colSpan={4} style={{ ...style.secondtablevalue, fontFamily: "Inter,sans-serif", textAlign: "right" }}>₹45,000.00</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box style={{ padding: "20px 0px", display: "flex", justifyContent: "space-between" }}>
                            <div style={{ justifyContent: "space-between" }}>
                                <Typography style={{ ...style.contacthead, fontFamily: "Inter, sans-serif" }}>Bank Details</Typography>
                                <Box style={{ display: "flex" }}>
                                    <Typography style={{ ...style.bankdetailkey, fontFamily: "Inter, sans-serif" }}>
                                        Bank:
                                        <span style={{ ...style.bankdetailvalue, fontFamily: "Inter, sans-serif", marginLeft: "5px" }}>
                                            Yes Bank
                                        </span>
                                    </Typography>
                                </Box>

                                <Box style={{ display: "flex" }}>
                                    <Typography style={{ ...style.bankdetailkey, fontFamily: "Inter, sans-serif" }}>
                                        Account Number:
                                        <span style={{ ...style.bankdetailvalue, fontFamily: "Inter, sans-serif", marginLeft: "5px" }}>
                                            000863400003864
                                        </span>
                                    </Typography>
                                </Box>

                                <Box style={{ display: "flex" }}>
                                    <Typography style={{ ...style.bankdetailkey, fontFamily: "Inter, sans-serif" }}>
                                        IFSC:
                                        <span style={{ ...style.bankdetailvalue, fontFamily: "Inter, sans-serif", marginLeft: "5px" }}>
                                            YESB0000008
                                        </span>
                                    </Typography>
                                </Box>

                                <Box style={{ display: "flex" }}>
                                    <Typography style={{ ...style.bankdetailkey, fontFamily: "Inter, sans-serif" }}>
                                        Branch:
                                        <span style={{ ...style.bankdetailvalue, fontFamily: "Inter, sans-serif", marginLeft: "5px" }}>
                                            SHIVAJI NAGAR, PUNE
                                        </span>
                                    </Typography>
                                </Box>
                            </div>

                            <Box>
                                <Typography style={{ ...style.contacthead, fontFamily: "Inter, sans-serif" }}>For KrateX Tradetech India Pvt Ltd</Typography>
                                <Typography style={{ ...style.bankdetailvalue, fontFamily: "Inter, sans-serif", marginTop: "80px", textAlign: "right" }}>Authorized Signatory</Typography>
                            </Box>
                        </Box>

                        <Box style={{ padding: "20px 0px", display: "flex", justifyContent: "space-between", borderTop: "1px solid #E7E2EF" }}>
                            <Box style={{ width: "500px" }}>
                                <Typography style={{ ...style.notehead, fontFamily: 'Inter, sans-serif' }}>Notes: </Typography>
                                <Typography style={{ ...style.notevalue, fontFamily: "Inter, sans-serif" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                                    malesuada condimentum odio vitae malesuada. Proin quis ligula
                                    eget lacus luctus pretium. Mauris hendrerit justo vel posuere eleifend.
                                    Nullam mollis odio sem, vel lacinia massa</Typography>
                            </Box>
                            <Box style={{ width: "500px" }}>
                                <Typography style={{ ...style.termhead, fontFamily: "Inter, sans-serif" }}>Terms and Conditions: </Typography>
                                <Typography style={{ ...style.termvalue, fontFamily: "Inter, sans-serif" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                                    malesuada condimentum odio vitae malesuada. Proin quis ligula
                                    eget lacus luctus pretium. Mauris hendrerit justo vel posuere eleifend.
                                    Nullam mollis odio sem, vel lacinia massa </Typography>
                            </Box>
                        </Box>
                    </div>

                </Box>
            </Box>
        )
    }
    // Customizable Area End


    render() {
        // Customizable Area Start
        return (<Box style={{ display: "flex", gap: "15px", flexDirection: "column", }}>
            <Navbar {...this.props} history={this.props.history} />
            <SideBar navigation={this.props.navigation} mycart={true} data-testId="searchText">
                {this.taxinvoice()}
            </SideBar>
        </Box>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const style = {
    mainContainer: {
        fontFamily: "Inter, sans-serif",
        marginLeft: "47px",
    },
    header: {
        margin: "24px 0px",
        fontWeight: 700,
        fontSize: "24px"
    },
    menuHeader: { fontSize: "14px", fontWeight: 500, fontFamily: "inter, sans-serif", color: '#323842', lineHeight: '22px', width: "1085px" },

    heading: {
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "26px",
        textAlign: "left",
    },
    tableHead: {
        backgroundColor: "#f3f4f6",
    },
    TableCell: {
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "22px",
        textAlign: "left",
        color: "#565E6C",
    },
    singleBox: { display: "flex", gap: "15px" },
    toggleBox: { display: "flex", alignItems: "center", justifyContent: "space-between", padding: "17px", cursor: "pointer", backgroundColor: "#f4f4f4", margin: "19px", border: "8px" },
    mainContent: { display: "flex", alignItems: "center", justifyContent: "space-between", padding: "17px", margin: "19px" },

    Tabfont: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "20px",
    },
    Amount: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#323842",
        backgroundColor: "#f3f4f6"
    },
    navTabContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height: "58px"
    },

    icon: {
        margin: "auto 5px",
    },

    activeTab: {
        fontFamily: "Arial, sans-serif",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "center" as "left" | "right" | "center" | "justify",
        color: "#EE6900",
    },
    inactiveTab: {
        fontFamily: "Arial, sans-serif",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: "center" as "left" | "right" | "center" | "justify",
        color: "#000000",
    },
    companyname: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#323842"
    },
    GST: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#323842"
    },
    GSTNO: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#323842"
    },
    PAN: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#323842"

    },
    panno: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#323842"
    },
    address: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#565E6C"
    },
    address1: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#323842"
    },
    contacthead: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#565E6C"
    },
    contactdetailhead: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#323842"
    },
    contactdetail: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#323842",
    },
    invoicekey: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#565E6C"
    },
    invoicevalue: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#323842"
    },
    customerdetail: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#565E6C"
    },
    customerdetail1: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#323842"
    },
    reference: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#565E6C"
    },
    poid: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#323842"
    },
    tableid: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#323842"
    },
    tablesecondfirst: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#323842"
    },
    tableseconddata: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#323842"
    },
    tablecommon: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#323842"
    },
    percentage: {
        fontSize: "13px",
        fontWeight: 300,
        color: "#64748B"
    },
    tablespancell: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#383242",
        borderRight: "1px solid #E2E8F0",
        borderTop: "1px solid #E3E8F0",
        backgroundColor: "#F0F0F0",
    },
    tablespancelldata: {
        fontSize: "16px",
        fontWeight: 700,
        color: "#383242",
        borderTop: "1px solid #E3E8F0",
        backgroundColor: "#F3F4F6",
    },
    secondtablecell: {
        fontSize: "14px",
        fontWeight: 700,
        border: "1px solid #E2E8F0",
        color: "#565E6C"
    },
    secondtablefirst: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#323842",
        border: "1px solid #E2E8F0",
    },
    secondtablecommon: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#323842",
        border: "1px solid #E7E2EF"
    },
    secondtableKey: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#383242",
        border: "1px solid #E2E8F0",
        backgroundColor: "#F0F0F0",
    },
    secondtablevalue: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#383242",
        border: "1px solid #E3E8F0",
        backgroundColor: "#F3F4F6",
    },
    bankdetailkey: {
        fontSize: "14px",
        fontWeight: 500
    },
    bankdetailvalue: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#323842"
    },
    notehead: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#565E6C"
    },
    notevalue: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#323842"
    },
    termhead: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#565E6C"
    },
    termvalue: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#565E6C"
    }


};
// Customizable Area End